import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoSCIService } from 'app/servicios/catalogo-sci.service';

@Component({
  selector: 'app-catalogo-sci',
  templateUrl: './catalogo-sci.component.html',
  styleUrls: ['./catalogo-sci.component.scss']
})
export class CatalogoSciComponent implements OnInit {

  displayedColumns: string[] = ['idCatalogo', 'nombre', 'estado', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: CatalogoSCIService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.catalogoservice.getCatalogosSCI().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

     //AVISO TIPO SNACKBAR ANGULAR MATERIAL
     openSnackBar(message: string) {
      this.snackBar.open(message, "Aceptar", { duration: 2000 });
    }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.array, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.array);
    this.dataSource.sort = this.sort;
    this.array.reverse()
    this.array.forEach(element => {
      element.orden = i;

      if (element.estado == 'ACTIVO') {
        element.estado = 1;
      } else {
        element.estado = 0;
      }

      this.catalogoservice.actualizarCatalogoSCI(element.idCatalogo, element).subscribe(response => {
      });

      if (element.estado == 1) {
        element.estado = 'ACTIVO';
      } else {
        element.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.array.reverse()
    // this.obtenersedes()
  }
}
