import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AcuerdoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getAcuerdos(){
    return this.http.get(`${this.API_URI}/acuerdo`)
  }

  getAcuerdo(id:string){
    return this.http.get(`${this.API_URI}/acuerdo/${id}`);
  }

  actualizarAcuerdo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/acuerdo/${id}`, datos);
  }
  
  crearAcuerdo(datos: any) {
    return this.http.post(`${this.API_URI}/acuerdo`, datos);
  }

  eliminarAcuerdo(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/acuerdo/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/acuerdo/estadoDocumento/${id}`, datos);
  }

}
