import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GruposVulnerablesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getGruposVulnerables(){
    return this.http.get(`${this.API_URI}/grupos-vulnerables`)
  }

  getGrupoVulnerable(id:string){
    return this.http.get(`${this.API_URI}/grupos-vulnerables/${id}`);
  }

  actualizarGrupoVulnerable(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/grupos-vulnerables/${id}`, datos);
  }
  
  crearGrupoVulnerable(datos: any) {
    return this.http.post(`${this.API_URI}/grupos-vulnerables`, datos);
  }

  eliminarGrupoVulnerable(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/grupos-vulnerables/${id}`);
  }

  getDescripcion(id:number){
    return this.http.get(`${this.API_URI}/grupos-vulnerables/descripcion/${id}`);
  }

  actualizarDescripcion(id: number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/grupos-vulnerables/descripcion/${id}`, datos);
  }

}
