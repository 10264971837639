import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { RecomendacionGeneralService } from 'app/servicios/recomendacion-general.service';
import moment = require('moment');

@Component({
  selector: 'app-recomendacion-general',
  templateUrl: './recomendacion-general.component.html',
  styleUrls: ['./recomendacion-general.component.scss']
})
export class RecomendacionGeneralComponent implements OnInit {

  
  displayedColumns: string[] = ['idRecomendacionGeneral', 'numero', 'fecha','asunto','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public recomendaciones_generales: any = [];

  constructor(private serviciorecomendacion: RecomendacionGeneralService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerRecomendacionesGenerales()
  }

  obtenerRecomendacionesGenerales = () => {
    this.serviciorecomendacion.getRecomendacionesGenerales().subscribe(response =>{
      this.recomendaciones_generales = response;
      this.recomendaciones_generales.forEach(recomendacion => {
        recomendacion.fecha = moment(recomendacion.fecha).format('YYYY-MM-DD')
      });
      this.dataSource = new MatTableDataSource(this.recomendaciones_generales)
      this.dataSource.sort = this.sort
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarRecomendacion = (id) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
      this.serviciorecomendacion.eliminarRecomendacionGeneral(id).subscribe(response =>{
        this.obtenerRecomendacionesGenerales()
      })
      }
  
    })
  }


}
