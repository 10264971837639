import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class RecomendacionService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getRecomendaciones(anio:string){
    return this.http.get(`${this.API_URI}/recomendacion/todo/${anio}`)
  }

  getRecomendacion(id:string){
    return this.http.get(`${this.API_URI}/recomendacion/${id}`);
  }

  actualizarRecomendacion(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/recomendacion/${id}`, datos);
  }
  
  crearRecomendacion(datos: any) {
    return this.http.post(`${this.API_URI}/recomendacion`, datos);
  }

  eliminarRecomendacion(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/recomendacion/${id}`);
  }
  
  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/recomendacion/estadoDocumento/${id}`, datos);
  }

}
