import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { FechasService } from 'app/servicios/fechas.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fechas-detalle',
  templateUrl: './fechas-detalle.component.html',
  styleUrls: ['./fechas-detalle.component.scss']
})
export class FechasDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idAnio: string = this.obtenerId().idAnio;

  //VARIABLES
  edit: boolean = false;
  array: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  FechasForm = new FormGroup({
    fecha: new FormControl('', Validators.required),
    orden: new FormControl(0)
    // estado: new FormControl(1)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: FechasService) { }

  ngOnInit(): void {
    if (this.idAnio) {
      this.catalogoservice.getCatalogoFecha(this.idAnio).subscribe(response => {
        this.FechasForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.FechasForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.catalogoservice.getFechas().subscribe(response => {
      if(response){
        this.array = response;
        this.FechasForm.controls['orden'].setValue(this.array.length + 1)
      }
    });

    this.catalogoservice.crearCatalogoFecha(this.FechasForm.value).subscribe(response =>{
      this.FechasForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/catalogo-fecha')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoFecha(this.idAnio, this.FechasForm.value).subscribe(response =>{
      this.FechasForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-fecha')
    });
  }

}
