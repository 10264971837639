import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { BoletinesPrensaService } from 'app/servicios/boletines.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');


@Component({
  selector: 'app-boletines-prensa',
  templateUrl: './boletines-prensa.component.html',
  styleUrls: ['./boletines-prensa.component.scss']
})
export class BoletinesPrensaComponent implements OnInit {

  displayedColumns: string[] = ['orden','numero', 'fecha','titulo','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public boletines: any = [];

  constructor(private serviceboletines: BoletinesPrensaService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerBoletines()
  }

  obtenerBoletines = () =>{
    this.serviceboletines.getBoletinesPrensa().subscribe(response =>{
      this.boletines = response;
      // this.boletines.forEach(boletin => {
      //   boletin.fecha = moment(boletin.fecha).format('YYYY-MM-DD')
      // });
      this.dataSource = new MatTableDataSource(this.boletines)
      this.dataSource.sort = this.sort;

    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarBoletin = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceboletines.eliminarBoletinPrensa(id).subscribe(response =>{
          this.serviceboletines.eliminarDetalleBoletin(id).subscribe(response =>{
            
          })
          this.obtenerBoletines()
        })
      }
  
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.boletines, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.boletines);
    this.dataSource.sort = this.sort;
    this.boletines.reverse()
    this.boletines.forEach(boletin => {
      boletin.orden = i;

      if (boletin.estado == 'ACTIVO') {
        boletin.estado = 1;
      } else {
        boletin.estado = 0;
      }

      this.serviceboletines.actualizarBoletinPrensa(boletin.idBoletin, boletin).subscribe(response => {
      });


      if (boletin.estado == 1) {
        boletin.estado = 'ACTIVO';
      } else {
        boletin.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.boletines.reverse()
    // this.obtenerBoletines()
  }

  
}
