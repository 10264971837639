import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class OrganismosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getOrganismos(){
    return this.http.get(`${this.API_URI}/organismo`)
  }

  getOrganismo(id:string){
    return this.http.get(`${this.API_URI}/organismo/${id}`);
  }

  actualizarOrganismo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/organismo/${id}`, datos);
  }
  
  crearOrganismo(datos: any) {
    return this.http.post(`${this.API_URI}/organismo`, datos);
  }

  eliminarOrganismo(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/organismo/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/organismo/estadoImagen/${id}`, datos);
  }


}
