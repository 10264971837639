export const ArmonizacionTable = {
    idAnio: "selectA",
    // idEncabezadoArmonizacion: "selectE",
    idDetalleArmonizacion: "selectD",
    rutaDocumento:"disable",
    estado:"select",
    isEdit: "isEdit"
}

export const ArmonizacionDetalleTable = {
    nombre:"text",
    estado:"select",
    isEdit: "isEdit"
}