import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoOrganismosService } from 'app/servicios/catalogo-organismos.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { OrganismosService } from 'app/servicios/organismos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-organismos-nal-intl-detalle',
  templateUrl: './organismos-nal-intl-detalle.component.html',
  styleUrls: ['./organismos-nal-intl-detalle.component.scss']
})
export class OrganismosNalIntlDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idOrganismo: string = this.obtenerId().idOrganismo;

  //VARIABLES
  edit: boolean = false;
  categorias: any = [];
  IMAGEN_HOST = environment.API_IMAGEN;
  organismos:any='';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  OrganismoForms = new FormGroup({
    descripcion: new FormControl('', Validators.required),
    url: new FormControl(''),
    idCategoriaOrg: new FormControl(1),
    estado: new FormControl(1),
    rutaImagen: new FormControl('')
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private organismocategoria: CatalogoOrganismosService,
    private organismosservice: OrganismosService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    this.obtenerCategorias()
    if (this.idOrganismo) {
      this.organismosservice.getOrganismo(this.idOrganismo).subscribe(response => {
        this.OrganismoForms.patchValue(response[0])
        this.organismos = response[0].rutaImagen;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.OrganismoForms.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  obtenerCategorias = () => {
    this.organismocategoria.getCatalgoOrganismos().subscribe(response => {
      this.categorias = response;
    })
  }

  guardarDatos = () => {
    let pdf = this.OrganismoForms.get('rutaImagen').value;
    this.OrganismoForms.controls['rutaImagen'].setValue(pdf._fileNames)

    this.organismosservice.crearOrganismo(this.OrganismoForms.value).subscribe(response => {
      if (this.OrganismoForms.get('rutaImagen').value) {
        this.guardarPDF(pdf)
      }this.OrganismoForms.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/organismos-nal-intl')
    });
  }

  actualizarDatos = () => {
    let pdf = this.OrganismoForms.get('rutaImagen').value;
    this.OrganismoForms.controls['rutaImagen'].setValue(pdf._fileNames)

    this.organismosservice.actualizarOrganismo(this.idOrganismo, this.OrganismoForms.value).subscribe(response => {
      if (this.OrganismoForms.get('rutaImagen').value) {
        this.guardarPDF(pdf)
      }
      this.OrganismoForms.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/organismos-nal-intl')
    });
  }

  guardarPDF = (pdf:any) => {
    this.OrganismoForms.controls['rutaImagen'].setValue(pdf._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.OrganismoForms.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });
  }

  eliminarImagen = () =>{

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.organismosservice.actualizarEstadoImagen(this.idOrganismo, this.OrganismoForms.value).subscribe(response=>{
          this.serviciodocumento.eliminarImagen(this.organismos).subscribe(response=>{

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
  
    })
    
  }

}
