import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'app/servicios/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  
  usuario: any = { intentos: 0 };
  messageLogin: string;

  constructor(
    private authService: LoginService,
    private router: Router
  ) { }

  private validarCampos() {
    if (!this.usuario.usuario || this.usuario.usuario.split(' ').join('') == '') {
      return false;
    }
    if (!this.usuario.contrasena || this.usuario.contrasena.split(' ').join('') == '') {
      return false;
    }
    return true;
  }

  tryToLogin() {
    let token;
    this.authService.auth(this.usuario).subscribe(
      (data: any) => {
        let response: any = data;
        //VERIFICAMOS QUE LA CONSULTA NOS RETORNE AL USUARIO PARA CONFIRMAR EL LOGUEO CORRECTO
        if (response.usuario) {
          //ALMACENAMOS EL TOKEN Y EL USUARIO EN EL LOCALSTORAGE
          this.authService.setToken(data.token);
          this.authService.getToken().subscribe(response =>{
            token = response;
          })
          this.authService.guardarUsuario(data).subscribe(response => {
          })
          this.router.navigate(['/dashboard']);
        } else {
          this.messageLogin = 'Usuario/Contraseña inválido';
        }
      },
      (error) => console.log(error)
    );
  }

}
