import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class PublicacionService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getPublicaciones(id: string | number){
    return this.http.get(`${this.API_URI}/publicacion/filtro/${id}`)
  }

  getPublicacion(id:string){
    return this.http.get(`${this.API_URI}/publicacion/${id}`);
  }

  getDocsPublicacion(id:string){
    return this.http.get(`${this.API_URI}/publicacion/estadoDocumento/${id}`);
  }

  crearDocPublicacion(datos: any) {
    return this.http.post(`${this.API_URI}/publicacion/crear/documento`, datos);
  }

  actualizarPublicacion(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/publicacion/${id}`, datos);
  }
  
  crearPublicacion(datos: any) {
    return this.http.post(`${this.API_URI}/publicacion`, datos);
  }

  eliminarPublicacion(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/publicacion/${id}`);
  }

  eliminarDocsPublicacion(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/publicacion/estadoDocumento/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/publicacion/estadoImagen/${id}`, datos);
  }

}
