import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { CuadroConfirmacionComponent } from 'app/componentes/cuadro-confirmacion/cuadro-confirmacion.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { GanadorTable } from 'app/model/concurso';
import { ConcursoService } from 'app/servicios/concursos.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FechasService } from 'app/servicios/fechas.service';
import { CatalogoConcursoService } from 'app/servicios/catalogo-concurso.service';


@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.scss']
})
export class ConcursoComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idConcurso: string = this.obtenerId().idConcurso;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  concurso: any = '';
  ganadores: any = []
  ordenGanadores: any = []
  imagenes: any = [];
  concursante: any = '';
  verImg: boolean = false;
  idConcursante: any = 0;
  ocultarImagenes: boolean = false;
  mostrarModuloGanadores: boolean = false;
  imagenesGanadores: any = [];
  estadoDocumento: any = '';
  anios: any = [];
  seleccionaranio: any = 1;
  concursosDisponibles: any = [];
  conteoGanadores: any = [];

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsGanador: string[] = Object.keys(GanadorTable);
  dataSchemaGanador = GanadorTable;
  dataSourceGanador = [...this.ganadores];
  @ViewChild('tableG', { static: true }) tableG: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ConcursoForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    descripcion: new FormControl(''),
    estado: new FormControl(1),
    rutaImagen: new FormControl(''),
    orden: new FormControl(0),
    mostrarNinos: new FormControl(0)
  });

  GanadoresForm = new FormGroup({
    nombreGanador: new FormControl(null, Validators.required),
    premio: new FormControl(null, Validators.required),
    obra: new FormControl(''),
    tipo: new FormControl('0'),
    rutaDocumento: new FormControl(''),
    idAnio: new FormControl(1),
    idConcurso: new FormControl(0),
    orden: new FormControl(0)
  });

  ImagenesForm = new FormGroup({
    rutaImagen: new FormControl(null),
    idGanador: new FormControl(null),
    titulo: new FormControl('')
  })

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioconcurso: ConcursoService,
    private serviciodocumento: DocumentService,
    private serviciofechas: CatalogoConcursoService
  ) { }

  ngOnInit(): void {
    this.obtenerFechas()
    if (this.idConcurso) {
      this.mostrarModuloGanadores = true;
      this.servicioconcurso.getconcurso(this.idConcurso).subscribe(response => {
        this.ConcursoForm.patchValue(response[0])
        this.concurso = response[0].rutaImagen;
        this.obtenerGanador(this.idConcurso)
        // this.obtenerImagenes(this.idConcurso)

      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ConcursoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }


  elegirGanador = (id) => {
    if (id) {
      let idImagen;
      this.servicioconcurso.getGanadorImagenes(id).subscribe(response => {
        this.concursante = response[0].nombreGanador;
        this.idConcursante = response[0].idGanador;
        idImagen = response[0].idImagenGanador;
        if (idImagen) {
          this.ocultarImagenes = true;
        }
        this.verImg = true;
        this.imagenes = response;
      });
    } else {
      this.verImg = true;
    }

  }

  // obtenerGanador = () =>{
  //   this.servicioconcurso.getGanadorImagenes(id).subscribe(response=>{

  //   })
  // }

  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idConcurso) {
      this.edit = false;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  //FUNCIONES DE BOTONES PRINCIPALES DE GUARDADO Y ACTUALIZAR
  // guardarDatos = () => {
  //   let result: any = {};
  //   let id;
  //   let imagen = this.ConcursoForm.get('rutaImagen').value;
  //   if (this.ConcursoForm.get('rutaImagen').value) {
  //     this.ConcursoForm.controls['rutaImagen'].setValue(imagen._fileNames)
  //   }

  //   this.servicioconcurso.crearconcurso(this.ConcursoForm.value).subscribe(response => {
  //     result = response;
  //     id = result.reps[0].id;
  //     this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
  //     if (this.ConcursoForm.get('rutaImagen').value) {
  //       this.guardarImagen(imagen)
  //       this.guardarImagenBD(id)
  //     }

  //     if (this.ganadores.length > 0) {
  //       this.ganadores.forEach(ganador => {
  //         ganador.idConcurso = id;
  //         if (ganador.rutaDocumento == null) {
  //           ganador.rutaDocumento = '';
  //         }
  //         this.servicioconcurso.crearGanadorConcurso(ganador).subscribe(response => {

  //         });
  //       });
  //     }
  //     if (this.ganadores.length > 0) {
  //       this.dialog.open(CuadroConfirmacionComponent).afterClosed().subscribe(confirm => {
  //         if (confirm) {
  //           this.ConcursoForm.markAsPristine()
  //           this.ConcursoForm.markAllAsTouched()
  //           this.router.navigateByUrl(`/concurso-detalle/${id}`)
  //         }
  //       })
  //     } else {
  //       this.ConcursoForm.markAsPristine()
  //       this.ConcursoForm.markAllAsTouched()
  //       this.router.navigateByUrl('/concursos')
  //     }

  //   });
  // }

  actualizarDatos = () => {
    let imagen = this.ConcursoForm.get('rutaImagen').value;
    if (this.ConcursoForm.get('rutaImagen').value) {
      this.ConcursoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioconcurso.actualizarconcurso(this.idConcurso, this.ConcursoForm.value).subscribe(response => {
      if (this.ConcursoForm.get('rutaImagen').value) {
        this.guardarImagen(imagen)
      }

      this.ConcursoForm.markAsPristine()
      this.ConcursoForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/concursos')
    })


    //   this.servicioconcurso.actualizarconcurso(this.idConcurso, this.ConcursoForm.value).subscribe(response => {
    //     if (this.ConcursoForm.get('rutaImagen').value) {
    //       this.guardarImagen(imagen)
    //     }

    //     if (this.ganadores.length > 0) {
    //       this.ganadores.forEach(ganador => {
    //         ganador.idConcurso = this.idConcurso;
    //         if (ganador.rutaDocumento == null) {
    //           ganador.rutaDocumento = '';
    //         }
    //         this.servicioconcurso.crearGanadorConcurso(ganador).subscribe(response => {

    //         });
    //       });
    //     }
    //     // this.ConcursoForm.reset()
    //     this.ConcursoForm.markAsPristine()
    //     this.ConcursoForm.markAllAsTouched()
    //     this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
    //     this.router.navigateByUrl('/concursos')
    //   });
  }



  guardarImagenGanadores = (imagen: any) => {
    this.ImagenesForm.controls['rutaImagen'].setValue(imagen);
    const imgData = new FormData();
    imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });
    this.elegirGanador(this.idConcursante)
  }



  //GUARDAR LAS IMAGENES DE LOS GANADORES
  guardarImgGanador = () => {
    let imagen = this.ImagenesForm.get('rutaImagen').value;
    if (this.idConcurso) {
      imagen._files.forEach(element => {
        this.ImagenesForm.controls['rutaImagen'].setValue(element.name)
        this.ImagenesForm.controls['idGanador'].setValue(this.idConcursante)
        this.servicioconcurso.crearGanadorConcursoImagenes(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenGanadores(element)
        })
      });
    }
  }

  guardarImagenBD = (id) => {
    this.ImagenesForm.controls['idConcurso'].setValue(id)
    let imagen = this.ImagenesForm.get('rutaImagen').value;
    imagen._files.forEach(element => {
      this.ImagenesForm.get('rutaImagen').setValue(element.name)
      this.ImagenesForm.get('idConcurso').setValue(id)
      this.servicioconcurso.crearGanadorConcursoImagenes(this.ImagenesForm.value).subscribe(response => {
        this.guardarImagenPHP(imagen);
      })
    });
  }

  // obtenerImagenes = (id) => {
  //   this.servicioconcurso.getGanadoresConcursoImagenes(id).subscribe(response => {
  //     this.imagenes = response;
  //   })
  // }

  //GUARDAR EL GANADOR
  guardarGanador = () => {
    let id;
    let result: any = {};
    let pdf = this.GanadoresForm.get('rutaDocumento').value;
    this.GanadoresForm.controls['idAnio'].setValue(this.seleccionaranio);
    this.GanadoresForm.controls['idConcurso'].setValue(this.idConcurso);

    if (this.GanadoresForm.get('nombreGanador').value != null) {
      if (this.GanadoresForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
        this.GanadoresForm.controls['rutaDocumento'].setValue(pdf._fileNames)
      }

      this.servicioconcurso.getGanadoresConcurso(this.idConcurso, this.seleccionaranio).subscribe(response => {

        if (response) {

          this.conteoGanadores = response;
          this.GanadoresForm.controls['orden'].setValue(this.conteoGanadores.length + 1);
          
          this.servicioconcurso.crearGanadorConcurso(this.GanadoresForm.value).subscribe(response => {
            if (response) {
              result = response;
              id = result.reps[0].id;
              if (this.ImagenesForm.get('rutaImagen').value != null) {

                let imagen = this.ImagenesForm.get('rutaImagen').value;

                imagen._files.forEach(element => {
                  this.ImagenesForm.get('rutaImagen').setValue(element.name)
                  this.ImagenesForm.get('idGanador').setValue(id)
                  this.servicioconcurso.crearGanadorConcursoImagenes(this.ImagenesForm.value).subscribe(response => {
                    this.guardarImagenPHP(imagen);
                    this.GanadoresForm.reset()
                  })
                });
                this.obtenerGanador(this.idConcurso)
              }
              if (this.GanadoresForm.get('rutaDocumento').value) {
                this.obtenerGanador(this.idConcurso)
                this.GanadoresForm.reset()

              }
            }
          });

        }

      })
      this.dataSourceGanador.push(this.GanadoresForm.value)
      this.ganadores.push(this.GanadoresForm.value)
      this.tableG.renderRows()
    }
  }

  obtenerGanador = (id) => {
    let array: any = []
    this.dataSourceGanador.length = 0;
    array.length = 0;
    this.servicioconcurso.getGanadoresConcurso(id, this.seleccionaranio).subscribe(response => {
      if (response) {
        array = response;
        this.ganadores = response;
        this.ordenGanadores = response;
        array.forEach(ganador => {
          this.dataSourceGanador.push(ganador);
          this.tableG.renderRows();
        });
      }

    });
  }

  cambios = (event) => {
    this.obtenerGanador(this.idConcurso)
  }

  //GUARDAR ARCHIVOS

  guardarImagenPHP = (imagen) => {
    imagen._files.forEach(element => {
      this.ImagenesForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });
  }

  guardarImagenGanador = (id) => {
    let imagen = this.ImagenesForm.get('rutaImagen').value;
    imagen._files.forEach(element => {
      this.ImagenesForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });
  }

  guardarPDF = (pdf: any) => {
    this.GanadoresForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.GanadoresForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  guardarImagen = (imagen: any) => {
    this.ConcursoForm.controls['rutaImagen'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.ConcursoForm.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });
  }

  //EDITAR DATOS

  editarGanador(row) {
    if (row.id === 0) {
    } else {
      if (row.idGanador) {
        delete row.isEdit
        this.servicioconcurso.actualizarGanadorConcurso(row.idGanador, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
        });
      } else {
        row.isEdit = false
      }
    }
  }


  //SECCIÓN DE ELIMINACIÓN DE DATOS Y ARCHIVOS

  eliminarImagenGanador = (id, name) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioconcurso.eliminarImagenGanador(id).subscribe(() => {
          this.serviciodocumento.eliminarImagen(name).subscribe(response => {

          });
          this.elegirGanador(this.idConcursante)
          this.openSnackBar("Se ha eliminado correctamente")
        })
      }
    })
  }

  //ELIMNAR IMAGEN Y DOCUMENTO
  eliminarImagen = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioconcurso.actualizarEstadoImagen(this.idConcurso, this.ConcursoForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.concurso).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }

    })
  }

  eliminarGanador(id, name) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioconcurso.eliminarGanadorConcurso(id).subscribe(() => {
          this.serviciodocumento.eliminarPDF(name).subscribe(response => {

          });
          this.obtenerGanador(this.idConcurso)
        })
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.ordenGanadores, event.previousIndex, event.currentIndex);

    this.dataSourceGanador.length = 0;
    this.ordenGanadores.forEach(ganador => {
      this.dataSourceGanador.push(ganador)
    });
    this.tableG.renderRows()

    this.ordenGanadores.reverse()
    this.ordenGanadores.forEach(ganador => {
      ganador.orden = i;

      this.servicioconcurso.actualizarGanadorConcurso(ganador.idGanador, ganador).subscribe(response => {
      });

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.ordenGanadores.reverse()
  }

  obtenerFechas = () => {
    this.serviciofechas.getCatalogosConcurso().subscribe(response => {
      this.anios = response;
    })
  }

  mostrarGanadores = () => {
    let result: any = {};
    let id;

    let imagen = this.ConcursoForm.get('rutaImagen').value;
    if (this.ConcursoForm.get('rutaImagen').value) {
      this.guardarImagen(imagen)
      this.ConcursoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioconcurso.getconcursos().subscribe(response => {
      this.concursosDisponibles = response;
      if (response) {
        this.ConcursoForm.controls['orden'].setValue(this.concursosDisponibles.length + 1)
        this.servicioconcurso.crearconcurso(this.ConcursoForm.value).subscribe(response => {
          if (response) {
            result = response;
            id = result.reps[0].id;
            this.mostrarModuloGanadores = true;
            this.ConcursoForm.markAsPristine()
            this.ConcursoForm.markAllAsTouched()
            this.router.navigateByUrl(`/concurso-detalle/${id}`)
          }

        })
      }
    });



  }

}
