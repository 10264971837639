import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoSubModuloGruposService } from 'app/servicios/catalogo-submodulo-grupos.service';
import { SubModulosGruposService } from 'app/servicios/submodulos-grupos-v.service';

@Component({
  selector: 'app-submodulos-grupos',
  templateUrl: './submodulos-grupos.component.html',
  styleUrls: ['./submodulos-grupos.component.scss']
})
export class SubmodulosGruposComponent implements OnInit {

  displayedColumns: string[] = ['idCatalogo', 'nombre','ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: CatalogoSubModuloGruposService) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.catalogoservice.getCatalogosGrupos().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
