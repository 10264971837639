import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ConoceDerechosPDFService } from 'app/servicios/conoce-derechos-pdf.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-conoce-derechos-pdf-detalle',
  templateUrl: './conoce-derechos-pdf-detalle.component.html',
  styleUrls: ['./conoce-derechos-pdf-detalle.component.scss']
})
export class ConoceDerechosPdfDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idConoce: string = this.obtenerId().idConoce;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  documento: any = '';
  imagenes: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  DerechosPDF = new FormGroup({
    titulo: new FormControl('', Validators.required),
    estado: new FormControl(1),
    rutaDocumento: new FormControl('')
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioDatos: ConoceDerechosPDFService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {

    if (this.idConoce) {
      this.servicioDatos.getDerecho(this.idConoce).subscribe(response => {
        this.DerechosPDF.patchValue(response[0])
        this.documento = response[0].rutaDocumento;
      })
    }

  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.DerechosPDF.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  guardarDatos = () => {

    let result: any = {};

    let pdf = this.DerechosPDF.get('rutaDocumento').value;
    if (this.DerechosPDF.get('rutaDocumento').value) {
      this.DerechosPDF.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.servicioDatos.crearDerecho(this.DerechosPDF.value).subscribe(response => {
      result = response;
      if (this.DerechosPDF.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.DerechosPDF.markAsPristine()
      this.DerechosPDF.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/conoce-derechos')
    });
  }

  actualizarDatos = () => {

    let pdf = this.DerechosPDF.get('rutaDocumento').value;
    if (this.DerechosPDF.get('rutaDocumento').value) {
      this.DerechosPDF.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.servicioDatos.actualizarDerecho(this.idConoce, this.DerechosPDF.value).subscribe(response => {
      if (this.DerechosPDF.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.DerechosPDF.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/conoce-derechos')
    });
  }


  guardarPDF = (pdf: any) => {
    this.DerechosPDF.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.DerechosPDF.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.actualizarEstadoDocumento(this.idConoce, this.DerechosPDF.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }
}
