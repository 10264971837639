import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { RecomendacionService } from 'app/servicios/recomendacion.service';
import moment = require('moment');
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FechasService } from 'app/servicios/fechas.service';

@Component({
  selector: 'app-recomendacion',
  templateUrl: './recomendacion.component.html',
  styleUrls: ['./recomendacion.component.scss']
})
export class RecomendacionComponent implements OnInit {


  displayedColumns: string[] = ['orden', 'numero', 'fecha', 'asunto', 'estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public recomendaciones: any = [];
  anio: any = moment().format("YYYY"); ;
  anios: any = [];

  constructor(private serviciorecomendacion: RecomendacionService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciofechas: FechasService) { }

  ngOnInit(): void {
    this.obtenerFechas()
    this.obtenerRecomendaciones()
  }

  obtenerRecomendaciones = () => {
    this.serviciorecomendacion.getRecomendaciones(this.anio).subscribe(response => {
      this.recomendaciones = response;
      // this.recomendaciones.forEach(recomendacion => {
      //   recomendacion.fecha = moment(recomendacion.fecha).format('YYYY-MM-DD')
      // });
      this.dataSource = new MatTableDataSource(this.recomendaciones)
      this.dataSource.sort = this.sort
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarRecomendacion = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciorecomendacion.eliminarRecomendacion(id).subscribe(cresponse => {
          this.obtenerRecomendaciones()
        });
      }

    })
  }

  obtenerFechas = () => {
    this.serviciofechas.getFechas().subscribe(response => {
      this.anios = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  cambios = (event) => {
    this.obtenerRecomendaciones()
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.recomendaciones, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.recomendaciones);
    this.dataSource.sort = this.sort;
    this.recomendaciones.reverse()

    this.recomendaciones.forEach(recomendacion => {
      recomendacion.orden = i;

      if (recomendacion.estado == 'ACTIVO') {
        recomendacion.estado = 1;
      } else {
        recomendacion.estado = 0;
      }


      this.serviciorecomendacion.actualizarRecomendacion(recomendacion.idRecomendacion, recomendacion).subscribe(response => {
      });


      if (recomendacion.estado == 1) {
        recomendacion.estado = 'ACTIVO';
      } else {
        recomendacion.estado = 'INACTIVO';
      }
      i++;
    });
    this.recomendaciones.reverse()

    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
