import { Routes } from '@angular/router';

import { HomeComponent } from '../../home/home.component';
import { SedesComponent } from '../../sedes/sedes.component';
import { SedeComponent } from '../../sedes/sede/sede.component';
import { CanDeactivateGuard } from 'app/servicios/can-deactive-guard.service';
import { MensajePresidenteComponent } from 'app/codhey/mensaje-presidente/mensaje-presidente.component';
import { ConsejoConsultivoComponent } from 'app/codhey/consejo-consultivo/consejo-consultivo.component';
import { ConsejoConsultivoVerComponent } from 'app/codhey/consejo-consultivo/consejo-consultivo-ver/consejo-consultivo-ver/consejo-consultivo-ver.component';
import { NormatividadComponent } from 'app/codhey/normatividad/normatividad.component';
import { NormatividadVerComponent } from 'app/codhey/normatividad/normatividad-ver/normatividad-ver.component';
import { NormatividadCatalogoComponent } from 'app/catalogos/normatividad-catalogo/normatividad-catalogo.component';
import { CatalogoDetalleNormatividadComponent } from 'app/catalogos/normatividad-catalogo/catalogo-detalle-normatividad/catalogo-detalle-normatividad.component';
import { OrganismosNacionalesInternacionalesComponent } from 'app/derechos-humanos/organismos-nacionales-internacionales/organismos-nacionales-internacionales.component';
import { OrganismosNalIntlDetalleComponent } from 'app/derechos-humanos/organismos-nacionales-internacionales/organismos-nal-intl-detalle/organismos-nal-intl-detalle.component';
import { CentroInvestigacionComponent } from 'app/derechos-humanos/centro-investigacion/centro-investigacion.component';
import { CentroInvestigacionDetalleComponent } from 'app/derechos-humanos/centro-investigacion/centro-investigacion-detalle/centro-investigacion-detalle.component';
import { SupervisionPermanenteComponent } from 'app/derechos-humanos/supervision-permanente/supervision-permanente.component';
import { SupervisionPermanenteDetalleComponent } from 'app/derechos-humanos/supervision-permanente/supervision-permanente-detalle/supervision-permanente-detalle.component';
import { RecomendacionComponent } from 'app/recomendaciones/recomendacion/recomendacion.component';
import { RecomendacionDetalleComponent } from 'app/recomendaciones/recomendacion/recomendacion-detalle/recomendacion-detalle.component';
import { RecomendacionGeneralComponent } from 'app/recomendaciones/recomendacion-general/recomendacion-general.component';
import { RecomendacionGeneralDetalleComponent } from 'app/recomendaciones/recomendacion-general/recomendacion-general-detalle/recomendacion-general-detalle.component';
import { AcuerdoNoResponsComponent } from 'app/recomendaciones/acuerdo-no-respons/acuerdo-no-respons.component';
import { AcuerdoNoResponsDetalleComponent } from 'app/recomendaciones/acuerdo-no-respons/acuerdo-no-respons-detalle/acuerdo-no-respons-detalle.component';
import { InconstitucionalidadComponent } from 'app/recomendaciones/inconstitucionalidad/inconstitucionalidad.component';
import { InconstitucionalidadDetalleComponent } from 'app/recomendaciones/inconstitucionalidad/inconstitucionalidad-detalle/inconstitucionalidad-detalle.component';
import { ConcursosComponent } from 'app/difusion/concursos/concursos.component';
import { AntecendentesComponent } from 'app/codhey/antecendentes/antecendentes.component';
import { ConcursoComponent } from 'app/difusion/concursos/concurso/concurso.component';
import { BoletinesPrensaComponent } from 'app/difusion/boletines-prensa/boletines-prensa.component';
import { BoletinPrensaComponent } from 'app/difusion/boletines-prensa/boletin-prensa/boletin-prensa.component';
import { ProgramasRadioComponent } from 'app/difusion/programas-radio/programas-radio.component';
import { ProgramaRadioDetalleComponent } from 'app/difusion/programas-radio/programa-radio-detalle/programa-radio-detalle.component';
import { GaleriaFotograficaComponent } from 'app/difusion/galeria-fotografica/galeria-fotografica.component';
import { GaleriaDetalleComponent } from 'app/difusion/galeria-fotografica/galeria-detalle/galeria-detalle.component';
import { PublicacionesComponent } from 'app/difusion/publicaciones/publicaciones.component';
import { PublicacionesDetalleComponent } from 'app/difusion/publicaciones/publicaciones-detalle/publicaciones-detalle.component';
import { AtribucionesComponent } from 'app/codhey/atribuciones/atribuciones.component';
import { AvisoPrivacidadComponent } from 'app/aviso-privacidad/aviso-privacidad.component';
import { AvisoPrivacidadDetalleComponent } from 'app/aviso-privacidad/aviso-privacidad-detalle/aviso-privacidad-detalle.component';
import { ConvocatoriasComponent } from 'app/destacados/convocatorias/convocatorias.component';
import { ConvocatoriaDetalleComponent } from 'app/destacados/convocatorias/convocatoria-detalle/convocatoria-detalle.component';
import { CursosComponent } from 'app/destacados/cursos/cursos.component';
import { CursoDetalleComponent } from 'app/destacados/cursos/curso-detalle/curso-detalle.component';
import { CatalogoPublicacionesComponent } from 'app/catalogos/catalogo-publicaciones/catalogo-publicaciones.component';
import { CatalogoDetallePublicacionesComponent } from 'app/catalogos/catalogo-publicaciones/catalogo-detalle-publicaciones/catalogo-detalle-publicaciones.component';
import { CatalogoOrganismosComponent } from 'app/catalogos/catalogo-organismos/catalogo-organismos.component';
import { CatalogoOrganismoDetalleComponent } from 'app/catalogos/catalogo-organismos/catalogo-organismo-detalle/catalogo-organismo-detalle.component';
import { DescripcionCursosComponent } from 'app/destacados/descripcion-cursos/descripcion-cursos.component';
import { BibliotecaComponent } from 'app/destacados/biblioteca/biblioteca.component';
import { BibliotecaDetalleComponent } from 'app/destacados/biblioteca/biblioteca-detalle/biblioteca-detalle.component';
import { CatalogoAutoresComponent } from 'app/catalogos/catalogo-autores/catalogo-autores.component';
import { CatalogoAutoresDetalleComponent } from 'app/catalogos/catalogo-autores/catalogo-autores-detalle/catalogo-autores-detalle.component';
import { CatalogoArmonizacionEncabezadoComponent } from 'app/catalogos/catalogo-armonizacion-encabezado/catalogo-armonizacion-encabezado.component';
import { ArmonizacionEncabezadoEditarComponent } from 'app/catalogos/catalogo-armonizacion-encabezado/armonizacion-encabezado-editar/armonizacion-encabezado-editar.component';
import { CatalogoArmonizacionDetalleComponent } from 'app/catalogos/catalogo-armonizacion-detalle/catalogo-armonizacion-detalle.component';
import { ArmonizacionDetalleEditarComponent } from 'app/catalogos/catalogo-armonizacion-detalle/armonizacion-detalle-editar/armonizacion-detalle-editar.component';
import { ArmonizacionComponent } from 'app/destacados/sistema-control-interno/armonizacion/armonizacion.component';
import { ArmonizacionDetalleComponent } from 'app/destacados/sistema-control-interno/armonizacion/armonizacion-detalle/armonizacion-detalle.component';
import { QuienesSomosComponent } from 'app/destacados/organo-control-interno/quienes-somos/quienes-somos.component';
import { DeclaracionesPatrimonialesComponent } from 'app/destacados/organo-control-interno/declaraciones-patrimoniales/declaraciones-patrimoniales.component';
import { EntregaRecepcionComponent } from 'app/destacados/organo-control-interno/entrega-recepcion/entrega-recepcion.component';
import { NormatividadOrganoComponent } from 'app/destacados/organo-control-interno/normatividad-organo/normatividad-organo.component';
import { PresentaDenunciaComponent } from 'app/destacados/organo-control-interno/presenta-denuncia/presenta-denuncia.component';
import { SupervisionPermanenteDHComponent } from 'app/derechos-humanos/defensa-proteccion/supervision-permanente-dh/supervision-permanente-dh.component';
import { ConciliacionesComponent } from 'app/derechos-humanos/defensa-proteccion/conciliaciones/conciliaciones.component';
import { MedidasCautelaresComponent } from 'app/derechos-humanos/defensa-proteccion/medidas-cautelares/medidas-cautelares.component';
import { SeguimientosComponent } from 'app/recomendaciones/seguimientos/seguimientos.component';
import { ControlInternoComponent } from 'app/destacados/sistema-control-interno/control-interno/control-interno.component';
import { TransparenciaComponent } from 'app/destacados/transparencia/transparencia.component';
import { ConvenioColaboracionComponent } from 'app/derechos-humanos/divulgacion-promocion/convenio-colaboracion/convenio-colaboracion.component';
import { CapacitacionComponent } from 'app/derechos-humanos/divulgacion-promocion/capacitacion/capacitacion.component';
import { CatalogoGrupoVulnerableComponent } from 'app/catalogos/catalogo-grupo-vulnerable/catalogo-grupo-vulnerable.component';
import { CatalogoGrupoVulnerableDetalleComponent } from 'app/catalogos/catalogo-grupo-vulnerable/catalogo-grupo-vulnerable-detalle/catalogo-grupo-vulnerable-detalle.component';
import { GruposVulnerablesComponent } from 'app/destacados/grupos-vulnerables/grupos-vulnerables.component';
import { GruposVulnerablesDetalleComponent } from 'app/destacados/grupos-vulnerables/grupos-vulnerables-detalle/grupos-vulnerables-detalle.component';
import { EscribenosComponent } from 'app/seccion-ninios/escribenos/escribenos.component';
import { CatalogoJuegosComponent } from 'app/catalogos/catalogo-juegos/catalogo-juegos.component';
import { CatalogoJuegosDetalleComponent } from 'app/catalogos/catalogo-juegos/catalogo-juegos-detalle/catalogo-juegos-detalle.component';
import { JuegosComponent } from 'app/seccion-ninios/juegos/juegos.component';
import { JuegosDetalleComponent } from 'app/seccion-ninios/juegos/juegos-detalle/juegos-detalle.component';
import { AudioDescripcionComponent } from 'app/seccion-ninios/audios/audio-descripcion/audio-descripcion.component';
import { AudiosNiniosComponent } from 'app/seccion-ninios/audios/audios-ninios/audios-ninios.component';
import { DescripcionCuentosComponent } from 'app/seccion-ninios/cuentos/descripcion-cuentos/descripcion-cuentos.component';
import { CatalogoNinosCatComponent } from 'app/catalogos/catalogo-ninos-cat/catalogo-ninos-cat.component';
import { CatalogoNinosCatDetalleComponent } from 'app/catalogos/catalogo-ninos-cat/catalogo-ninos-cat-detalle/catalogo-ninos-cat-detalle.component';
import { ConcursosNinosComponent } from 'app/seccion-ninios/cuentos/concursos-ninos/concursos-ninos.component';
import { ConcursosNinosDetalleComponent } from 'app/seccion-ninios/cuentos/concursos-ninos/concursos-ninos-detalle/concursos-ninos-detalle.component';
import { CatalogoPuestoNinosComponent } from 'app/catalogos/catalogo-puesto-ninos/catalogo-puesto-ninos.component';
import { CatalogoPuestoNinosDetalleComponent } from 'app/catalogos/catalogo-puesto-ninos/catalogo-puesto-ninos-detalle/catalogo-puesto-ninos-detalle.component';
import { CatalogoMicrositiosComponent } from 'app/catalogos/catalogo-micrositios/catalogo-micrositios.component';
import { CatalogoMicrositiosDetalleComponent } from 'app/catalogos/catalogo-micrositios/catalogo-micrositios-detalle/catalogo-micrositios-detalle.component';
import { MicrositiosComponent } from 'app/micrositios/micrositios/micrositios.component';
import { MicrositiosDetalleComponent } from 'app/micrositios/micrositios/micrositios-detalle/micrositios-detalle.component';
import { OrganigramaComponent } from 'app/codhey/organigrama/organigrama.component';
import { OrganigramaDetalleComponent } from 'app/codhey/organigrama/organigrama-detalle/organigrama-detalle.component';
import { ConoceDerechosPdfDetalleComponent } from 'app/seccion-ninios/conoce-derechos/conoce-derechos-pdf/conoce-derechos-pdf-detalle/conoce-derechos-pdf-detalle.component';
import { ConoceDerechosPdfComponent } from 'app/seccion-ninios/conoce-derechos/conoce-derechos-pdf/conoce-derechos-pdf.component';
import { DescripcionDerechosComponent } from 'app/seccion-ninios/conoce-derechos/descripcion-derechos/descripcion-derechos.component';
import { DescripcionDerechosDetalleComponent } from 'app/seccion-ninios/conoce-derechos/descripcion-derechos/descripcion-derechos-detalle/descripcion-derechos-detalle.component';
import { UnidadDocumentacionComponent } from 'app/derechos-humanos/centro-investigacion/unidad-documentacion/unidad-documentacion.component';
import { UnidadInvestigacionComponent } from 'app/derechos-humanos/centro-investigacion/unidad-investigacion/unidad-investigacion.component';
import { MecanismoComponent } from 'app/derechos-humanos/centro-investigacion/mecanismo/mecanismo.component';
import { UnidadProfesionalismoComponent } from 'app/derechos-humanos/centro-investigacion/unidad-profesionalismo/unidad-profesionalismo.component';
import { ImagenesDestacadasComponent } from 'app/destacados/imagenes-destacadas/imagenes-destacadas.component';
import { ImagenesComponent } from 'app/recomendaciones/imagenes/imagenes.component';
import { FechasDetalleComponent } from 'app/catalogos/fechas/fechas-detalle/fechas-detalle.component';
import { FechasComponent } from 'app/catalogos/fechas/fechas.component';
import { DescripcionArmonizacionComponent } from 'app/destacados/descripcion-armonizacion/descripcion-armonizacion.component';
import { DescripcionSciComponent } from 'app/destacados/sistema-control-interno/descripcion-sci/descripcion-sci.component';
import { OrganigramaDescripcionComponent } from 'app/codhey/organigrama/organigrama-descripcion/organigrama-descripcion.component';
import { DescripcionDerechosHComponent } from 'app/derechos-humanos/descripcion-derechos-h/descripcion-derechos-h.component';
import { ContenidoGruposComponent } from 'app/destacados/grupos-vulnerables/contenido-grupos/contenido-grupos.component';
import { CatalogoUnidadInvComponent } from 'app/catalogos/catalogo-unidad-inv/catalogo-unidad-inv.component';
import { CatalogoUnidadDetalleComponent } from 'app/catalogos/catalogo-unidad-inv/catalogo-unidad-detalle/catalogo-unidad-detalle.component';
import { SubmodulosDetalleComponent } from 'app/derechos-humanos/centro-investigacion/submodulos/submodulos-detalle/submodulos-detalle.component';
import { SubmodulosComponent } from 'app/derechos-humanos/centro-investigacion/submodulos/submodulos.component';
import { CatalogoSciDetalleComponent } from 'app/catalogos/catalogo-sci/catalogo-sci-detalle/catalogo-sci-detalle.component';
import { CatalogoSciComponent } from 'app/catalogos/catalogo-sci/catalogo-sci.component';
import { SubmodulosSciComponent } from 'app/destacados/sistema-control-interno/submodulos-sci/submodulos-sci.component';
import { SubmodulosSciDetalleComponent } from 'app/destacados/sistema-control-interno/submodulos-sci/submodulos-sci-detalle/submodulos-sci-detalle.component';
import { CatalogoConcursosComponent } from 'app/catalogos/catalogo-concursos/catalogo-concursos.component';
import { CatalogoConcursosDetalleComponent } from 'app/catalogos/catalogo-concursos/catalogo-concursos-detalle/catalogo-concursos-detalle.component';
import { GruposPermisosComponent } from 'app/configuracion/grupos-permisos/grupos-permisos.component';
import { GruposPermisosDetalleComponent } from 'app/configuracion/grupos-permisos/grupos-permisos-detalle/grupos-permisos-detalle.component';
import { UsuariosDetalleComponent } from 'app/configuracion/usuarios/usuarios-detalle/usuarios-detalle.component';
import { UsuariosComponent } from 'app/configuracion/usuarios/usuarios.component';
import { CatalogoSubmodulosGruposComponent } from 'app/catalogos/catalogo-submodulos-grupos/catalogo-submodulos-grupos.component';
import { CatalogoSubmodulosGruposDetalleComponent } from 'app/catalogos/catalogo-submodulos-grupos/catalogo-submodulos-grupos-detalle/catalogo-submodulos-grupos-detalle.component';
import { SubmodulosGruposComponent } from 'app/destacados/grupos-vulnerables/submodulos-grupos/submodulos-grupos.component';
import { SubmodulosGruposDetalleComponent } from 'app/destacados/grupos-vulnerables/submodulos-grupos/submodulos-grupos-detalle/submodulos-grupos-detalle.component';
import { QuejasComponent } from 'app/quejas/quejas.component';
import { QuejasDetalleComponent } from 'app/quejas/quejas-detalle/quejas-detalle.component';
import { VideosComponent } from 'app/difusion/videos/videos.component';
import { VideoDetalleComponent } from 'app/difusion/videos/video-detalle/video-detalle.component';
import { CatalogoVideosComponent } from 'app/catalogos/catalogo-videos/catalogo-videos.component';
import { CatalogoVideoDetalleComponent } from 'app/catalogos/catalogo-videos/catalogo-video-detalle/catalogo-video-detalle.component';
import { InformesEspecialesComponent } from 'app/recomendaciones/informes-especiales/informes-especiales.component';
import { VideosNiniosComponent } from 'app/seccion-ninios/audios/videos-ninios/videos-ninios.component';
import { VideoNinioDetalleComponent } from 'app/seccion-ninios/audios/videos-ninios/video-ninio-detalle/video-ninio-detalle.component';


// import { UpgradeComponent } from '../../upgrade/upgrade.component';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard', component: HomeComponent },
    //CODHEY
    { path: 'sedes', component: SedesComponent },
    { path: 'sede', component: SedeComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'sede/:idSede', component: SedeComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'mensaje-presidente', component: MensajePresidenteComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'consejo-consultivo', component: ConsejoConsultivoComponent },
    { path: 'consejo-consultivo-personal', component: ConsejoConsultivoVerComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'consejo-consultivo-personal/:idConsejo', component: ConsejoConsultivoVerComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'antecedentes', component: AntecendentesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'atribuciones', component: AtribucionesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'organigrama', component: OrganigramaComponent },
    { path: 'organigrama-detalle', component: OrganigramaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'organigrama-detalle/:idOrganigrama', component: OrganigramaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-organigrama', component: OrganigramaDescripcionComponent, canDeactivate: [CanDeactivateGuard] },


    //DERECHOS HUMANOS
    { path: 'organismos-nal-intl', component: OrganismosNacionalesInternacionalesComponent },
    { path: 'organismo-nal-intl-detalle', component: OrganismosNalIntlDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'organismo-nal-intl-detalle/:idOrganismo', component: OrganismosNalIntlDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'centro-investigacion', component: CentroInvestigacionComponent },
    { path: 'centro-investigacion-detalle', component: CentroInvestigacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'centro-investigacion-detalle/:idCentroInv', component: CentroInvestigacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'supervision-permanente', component: SupervisionPermanenteComponent },
    { path: 'supervision-permanente-detalle', component: SupervisionPermanenteDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'supervision-permanente-detalle/:idSupervision', component: SupervisionPermanenteDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'supervision-dh', component: SupervisionPermanenteDHComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'conciliaciones', component: ConciliacionesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'medidas-cautelares', component: MedidasCautelaresComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'convenio-colaboracion', component: ConvenioColaboracionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'capacitacion', component: CapacitacionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'unidad-documentacion', component: UnidadDocumentacionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'unidad-investigacion', component: UnidadInvestigacionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'mecanismo', component: MecanismoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'unidad-profesionalismo', component: UnidadProfesionalismoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'contenido-derechos-humanos', component: DescripcionDerechosHComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'submodulos', component: SubmodulosComponent },
    { path: 'submodulos-detalle', component: SubmodulosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'submodulos-detalle/:idContenido', component: SubmodulosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    
    //RECOMENDACIONES
    { path: 'recomendacion', component: RecomendacionComponent },
    { path: 'recomendacion-detalle', component: RecomendacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'recomendacion-detalle/:idRecomendacion', component: RecomendacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'recomendacion-general', component: RecomendacionGeneralComponent },
    { path: 'recomendacion-general-detalle', component: RecomendacionGeneralDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'recomendacion-general-detalle/:idRecomendacionGeneral', component: RecomendacionGeneralDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'acuerdos', component: AcuerdoNoResponsComponent },
    { path: 'acuerdo-detalle', component: AcuerdoNoResponsDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'acuerdo-detalle/:idAcuerdo', component: AcuerdoNoResponsDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'inconstitucionalidad', component: InconstitucionalidadComponent },
    { path: 'inconstitucionalidad-detalle', component: InconstitucionalidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'inconstitucionalidad-detalle/:idInconstitucionalidad', component: InconstitucionalidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'seguimiento', component: SeguimientosComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'imagenes-recomendaciones', component: ImagenesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'informes-especiales', component: InformesEspecialesComponent, canDeactivate: [CanDeactivateGuard] },

    //DIFUSIÓN
    { path: 'concursos', component: ConcursosComponent },
    { path: 'concurso-detalle', component: ConcursoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'concurso-detalle/:idConcurso', component: ConcursoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'boletines-prensa', component: BoletinesPrensaComponent },
    { path: 'boletines-prensa-detalle', component: BoletinPrensaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'boletines-prensa-detalle/:idBoletin', component: BoletinPrensaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'programas-radio', component: ProgramasRadioComponent },
    { path: 'programa-radio-detalle', component: ProgramaRadioDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'programa-radio-detalle/:idProgramaRadio', component: ProgramaRadioDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'galeria-fotografica', component: GaleriaFotograficaComponent },
    { path: 'galeria-detalle', component: GaleriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'galeria-detalle/:idGaleria', component: GaleriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'publicaciones', component: PublicacionesComponent },
    { path: 'publicacion-detalle', component: PublicacionesDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'publicacion-detalle/:idPublicacion', component: PublicacionesDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'videos', component: VideosComponent },
    { path: 'video-detalle', component: VideoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'video-detalle/:idVideo', component: VideoDetalleComponent, canDeactivate: [CanDeactivateGuard] },

    //MICROSITIOS
    { path: 'micrositios', component: MicrositiosComponent },
    { path: 'micrositios-detalle', component: MicrositiosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'micrositios-detalle/:idContenido', component: MicrositiosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    

    //TRANSPARENCIA
    { path: 'normatividad', component: NormatividadComponent },
    { path: 'normatividad-detalle', component: NormatividadVerComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'normatividad-detalle/:idNormatividad', component: NormatividadVerComponent, canDeactivate: [CanDeactivateGuard] },
    //AVISO DE PRIVACIDAD
    { path: 'avisos-privacidad', component: AvisoPrivacidadComponent },
    { path: 'aviso-privacidad-detalle', component: AvisoPrivacidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'aviso-privacidad-detalle/:idAviso', component: AvisoPrivacidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    //CATÁLOGOS
    { path: 'catalogo-normatividad', component: NormatividadCatalogoComponent },
    { path: 'catalogo-normatividad-detalle', component: CatalogoDetalleNormatividadComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-normatividad-detalle/:idCategoria', component: CatalogoDetalleNormatividadComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-publicacion', component: CatalogoPublicacionesComponent },
    { path: 'catalogo-publicacion-detalle', component: CatalogoDetallePublicacionesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-publicacion-detalle/:idCategoriaPublicacion', component: CatalogoDetallePublicacionesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-organismos', component: CatalogoOrganismosComponent },
    { path: 'catalogo-organismo-detalle', component: CatalogoOrganismoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-organismo-detalle/:idCategoriaOrg', component: CatalogoOrganismoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-autores', component: CatalogoAutoresComponent },
    { path: 'catalogo-autor-detalle', component: CatalogoAutoresDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-autor-detalle/:idAutor', component: CatalogoAutoresDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-encabezado', component: CatalogoArmonizacionEncabezadoComponent },
    { path: 'catalogo-encabezado-detalle', component: ArmonizacionEncabezadoEditarComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-encabezado-detalle/:idEncabezadoArmonizacion', component: ArmonizacionEncabezadoEditarComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-detalle', component: CatalogoArmonizacionDetalleComponent },
    { path: 'catalogo-detalle-armonizacion', component: ArmonizacionDetalleEditarComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-detalle-armonizacion/:idEncabezadoArmonizacion', component: ArmonizacionDetalleEditarComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-grupos-vulnerables', component: CatalogoGrupoVulnerableComponent },
    { path: 'catalogo-grupos-vulnerables-detalle', component: CatalogoGrupoVulnerableDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-grupos-vulnerables-detalle/:idGrupo', component: CatalogoGrupoVulnerableDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-juegos', component: CatalogoJuegosComponent },
    { path: 'catalogo-juegos-detalle', component: CatalogoJuegosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-juegos-detalle/:idJuego', component: CatalogoJuegosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-ninos', component: CatalogoNinosCatComponent },
    { path: 'catalogo-ninos-detalle', component: CatalogoNinosCatDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-ninos-detalle/:idCategoria', component: CatalogoNinosCatDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-puesto', component: CatalogoPuestoNinosComponent },
    { path: 'catalogo-puesto-detalle', component: CatalogoPuestoNinosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-puesto-detalle/:idPuesto', component: CatalogoPuestoNinosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-micrositios', component: CatalogoMicrositiosComponent },
    { path: 'catalogo-micrositios-detalle', component: CatalogoMicrositiosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-micrositios-detalle/:idSitio', component: CatalogoMicrositiosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-fecha', component: FechasComponent },
    { path: 'catalogo-fecha-detalle', component: FechasDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-fecha-detalle/:idAnio', component: FechasDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-unidad-inv', component: CatalogoUnidadInvComponent },
    { path: 'catalogo-unidad-inv-detalle', component: CatalogoUnidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-unidad-inv-detalle/:idCatalogo', component: CatalogoUnidadDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-sci', component: CatalogoSciComponent },
    { path: 'catalogo-sci-detalle', component: CatalogoSciDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-sci-detalle/:idCatalogo', component: CatalogoSciDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-concurso', component: CatalogoConcursosComponent },
    { path: 'catalogo-concurso-detalle', component: CatalogoConcursosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-concurso-detalle/:idCatalogo', component: CatalogoConcursosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-submodulo-grupo', component: CatalogoSubmodulosGruposComponent },
    { path: 'catalogo-submodulo-grupo-detalle', component: CatalogoSubmodulosGruposDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-submodulo-grupo-detalle/:idCatalogo', component: CatalogoSubmodulosGruposDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-videos', component: CatalogoVideosComponent },
    { path: 'catalogo-video-detalle', component: CatalogoVideoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'catalogo-video-detalle/:idCatalogoVideo', component: CatalogoVideoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    

    //DESTACADOS
    { path: 'convocatorias', component: ConvocatoriasComponent },
    { path: 'convocatoria-detalle', component: ConvocatoriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'convocatoria-detalle/:idConvocatoria', component: ConvocatoriaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'cursos', component: CursosComponent },
    { path: 'curso-detalle', component: CursoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'curso-detalle/:idCurso', component: CursoDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-cursos', component: DescripcionCursosComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'biblioteca', component: BibliotecaComponent },
    { path: 'biblioteca-detalle', component: BibliotecaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'biblioteca-detalle/:idBiblioteca', component: BibliotecaDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'armonizacion', component: ArmonizacionComponent },
    { path: 'armonizacion-detalle', component: ArmonizacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'armonizacion-detalle/:idArmonizacion', component: ArmonizacionDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'quienes-somos', component: QuienesSomosComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'declaracion-patrimonial', component: DeclaracionesPatrimonialesComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'entrega-recepcion', component: EntregaRecepcionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'normatividad-org', component: NormatividadOrganoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'presenta-tu-denuncia', component: PresentaDenunciaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'comite-control', component: ControlInternoComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'transparencia', component: TransparenciaComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'grupos-vulnerables', component: GruposVulnerablesComponent },
    { path: 'grupos-vulnerables-detalle', component: GruposVulnerablesDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'grupos-vulnerables-detalle/:idGrupoVulnerable', component: GruposVulnerablesDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'imagenes', component: ImagenesDestacadasComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-armonizacion', component: DescripcionArmonizacionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-sci', component: DescripcionSciComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-grupos-vulnerables', component: ContenidoGruposComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'submodulos-sci', component: SubmodulosSciComponent },
    { path: 'submodulos-sci-detalle', component: SubmodulosSciDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'submodulos-sci-detalle/:idContenido', component: SubmodulosSciDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'submodulos-grupos', component: SubmodulosGruposComponent },
    { path: 'submodulos-grupos-detalle', component: SubmodulosGruposDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'submodulos-grupos-detalle/:idCatalogo', component: SubmodulosGruposDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    
    
    //SECCIÓN NIÑOS
    { path: 'escribenos', component: EscribenosComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'juegos', component: JuegosComponent },
    { path: 'juegos-detalle', component: JuegosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'juegos-detalle/:idJuego', component: JuegosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-audios', component: AudioDescripcionComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'audios-ninios', component: AudiosNiniosComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-cuentos', component: DescripcionCuentosComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'concursos-ninios', component: ConcursosNinosComponent },
    { path: 'concursos-ninios-detalle', component: ConcursosNinosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'concursos-ninios-detalle/:idConcurso', component: ConcursosNinosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'conoce-derechos', component: ConoceDerechosPdfComponent },
    { path: 'conoce-derechos-detalle', component: ConoceDerechosPdfDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'conoce-derechos-detalle/:idConoce', component: ConoceDerechosPdfDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-derechos', component: DescripcionDerechosComponent },
    { path: 'descripcion-derechos-detalle', component: DescripcionDerechosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'descripcion-derechos-detalle/:idDescripcion', component: DescripcionDerechosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'videos-ninios', component: VideosNiniosComponent },
    { path: 'video-ninio-detalle', component: VideoNinioDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'video-ninio-detalle/:idVideo', component: VideoNinioDetalleComponent, canDeactivate: [CanDeactivateGuard] },


    //CONFIGURACION
    { path: 'grupos-permisos', component: GruposPermisosComponent },
    { path: 'grupos-permisos-detalle', component: GruposPermisosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'grupos-permisos-detalle/:idGrupo', component: GruposPermisosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'usuarios', component: UsuariosComponent },
    { path: 'usuarios-detalle', component: UsuariosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    { path: 'usuarios-detalle/:idUsuario', component: UsuariosDetalleComponent, canDeactivate: [CanDeactivateGuard] },
    
    //QUEJAS
    { path: 'quejas', component: QuejasComponent },
    { path: 'quejas-detalle', component: QuejasDetalleComponent},
    { path: 'quejas-detalle/:idQueja', component: QuejasDetalleComponent },
    
];
