export const environment = {
  // production: true

  //LOCALHOST
  //RUTA DEL SERVIDOR NODE JS
  // API_URL: 'http://localhost:4000',

  //RUTA DEL SERVIDOR PHP
  // API_SERVER_PHP: 'http://localhost/archivos-codhey',

  //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
  // API_PDF: `http://localhost/archivos-codhey/documentos/`,
  // API_AUDIO: `http://localhost/archivos-codhey/audios/`,
  // API_IMAGEN: `http://localhost/archivos-codhey/imagenes/`,

  

  //GODADDY
  //RUTA DEL SERVIDOR NODE JS
  // API_URL: 'http://134.122.23.70:4000',
  API_URL: 'https://admin.codhey.org.mx',

  

  //RUTA DEL SERVIDOR PHP
  API_SERVER_PHP: '/archivos-codhey',
  //RUTA DE CONSULTA DE ARCHIVOS E IMAGENES
  API_PDF: `/archivos-codhey/documentos/`,
  API_AUDIO: `/archivos-codhey/audios/`,
  API_IMAGEN: `/archivos-codhey/imagenes/`


};
