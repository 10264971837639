import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GruposVulnerablesService } from 'app/servicios/grupos-vulnerables.service';

@Component({
  selector: 'app-grupos-vulnerables',
  templateUrl: './grupos-vulnerables.component.html',
  styleUrls: ['./grupos-vulnerables.component.scss']
})
export class GruposVulnerablesComponent implements OnInit {

  displayedColumns: string[] = ['idGrupoVulnerable', 'nombre','ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: GruposVulnerablesService) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.catalogoservice.getGruposVulnerables().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
