import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BoletinesPrensaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getBoletinesPrensa(){
    return this.http.get(`${this.API_URI}/boletines-prensa`)
  }

  getBoletinPrensa(id:string){
    return this.http.get(`${this.API_URI}/boletines-prensa/${id}`);
  }

  actualizarBoletinPrensa(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/boletines-prensa/${id}`, datos);
  }
  
  crearBoletinPrensa(datos: any) {
    return this.http.post(`${this.API_URI}/boletines-prensa`, datos);
  }

  eliminarBoletinPrensa(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/boletines-prensa/${id}`);
  }

  crearImagenes(datos: any) {
    return this.http.post(`${this.API_URI}/boletines-prensa/boletin/imagen`, datos);
  }

  getImagenes(id:string){
    return this.http.get(`${this.API_URI}/boletines-prensa/imagen/${id}`);
  }

  eliminarDetalleBoletin(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/boletines-prensa/eliminarI/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/boletines-prensa/estadoDocumento/${id}`, datos);
  }

  eliminarImagenBoletin(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/boletines-prensa/eliminarImagen/${id}`);
  }

  actualizarBoletinPrensaImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/boletines-prensa/imagenUpdate/${id}`, datos);
  }
  

}
