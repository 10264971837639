import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ConoceDerechosPDFService } from 'app/servicios/conoce-derechos-pdf.service';

@Component({
  selector: 'app-conoce-derechos-pdf',
  templateUrl: './conoce-derechos-pdf.component.html',
  styleUrls: ['./conoce-derechos-pdf.component.scss']
})
export class ConoceDerechosPdfComponent implements OnInit {

  displayedColumns: string[] = ['idConoce', 'titulo','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private servicioDatos: ConoceDerechosPDFService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.servicioDatos.getDerechos().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarFila = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.eliminarDerecho(id).subscribe(response =>{
          this.obtenerDatos()
        })
      }
  
    })
  }
}
