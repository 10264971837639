import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoEncabezadoService } from 'app/servicios/catalogo-encabezado-armonizacion.service';

@Component({
  selector: 'app-catalogo-armonizacion-encabezado',
  templateUrl: './catalogo-armonizacion-encabezado.component.html',
  styleUrls: ['./catalogo-armonizacion-encabezado.component.scss']
})
export class CatalogoArmonizacionEncabezadoComponent implements OnInit {

  displayedColumns: string[] = ['idEncabezadoArmonizacion', 'nombre','estado' , 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: CatalogoEncabezadoService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerEncabezadosArmonizaciones()
  }

  obtenerEncabezadosArmonizaciones = () =>{
    this.catalogoservice.getCatalogoEncabezados().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

    //AVISO TIPO SNACKBAR ANGULAR MATERIAL
    openSnackBar(message: string) {
      this.snackBar.open(message, "Aceptar", { duration: 2000 });
    }
  

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.array, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.array);
    this.dataSource.sort = this.sort;
    this.array.reverse()
    this.array.forEach(encabezado => {
      encabezado.orden = i;

      if (encabezado.estado == 'ACTIVO') {
        encabezado.estado = 1;
      } else {
        encabezado.estado = 0;
      }

      this.catalogoservice.actualizarCatalogoEncabezado(encabezado.idEncabezadoArmonizacion, encabezado).subscribe(response => {
      });


      if (encabezado.estado == 1) {
        encabezado.estado = 'ACTIVO';
      } else {
        encabezado.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.array.reverse()
    // this.obtenerarray()
  }

}
