import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class cursoservice {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCursos(){
    return this.http.get(`${this.API_URI}/cursos`)
  }

  getCurso(id:string){
    return this.http.get(`${this.API_URI}/cursos/${id}`);
  }

  actualizarCursos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/cursos/${id}`, datos);
  }
  
  crearCursos(datos: any) {
    return this.http.post(`${this.API_URI}/cursos`, datos);
  }

  eliminarCursos(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/cursos/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/cursos/estadoImagen/${id}`, datos);
  }

}
