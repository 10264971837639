import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ArmonizacionService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getArmonizaciones(){
    return this.http.get(`${this.API_URI}/armonizacion`)
  }

  getArmonizacion(id:string, anio: string | number){
    return this.http.get(`${this.API_URI}/armonizacion/${id}/${anio}`);
  }

  getArmonizacionDetalle(id:string){
    return this.http.get(`${this.API_URI}/armonizacion/${id}`);
  }

  actualizarArmonizacion(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/armonizacion/${id}`, datos);
  }
  
  crearArmonizacion(datos: any) {
    return this.http.post(`${this.API_URI}/armonizacion`, datos);
  }

  eliminarArmonizacion(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/armonizacion/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/armonizacion/estadoDocumento/${id}`, datos);
  }

}
