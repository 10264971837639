import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NormatividadOrgService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getNormatividadOrg(id:number){
    return this.http.get(`${this.API_URI}/normatividad-organo/${id}`);
  }

  actualizarNormatividadOrg(id: number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/normatividad-organo/${id}`, datos);
  }
  

}
