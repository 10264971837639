import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoSubModuloGruposService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalogosGrupos(){
    return this.http.get(`${this.API_URI}/catalogo-submodulo-grupos`)
  }

  getCatalogoGrupos(id:string){
    return this.http.get(`${this.API_URI}/catalogo-submodulo-grupos/${id}`);
  }

  actualizarCatalogoGrupos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-submodulo-grupos/${id}`, datos);
  }
  
  crearCatalogoGrupos(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-submodulo-grupos`, datos);
  }

  eliminarCatalogoGrupos(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-submodulo-grupos/${id}`);
  }

}
