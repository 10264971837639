import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoCategoriasService } from 'app/servicios/catalogo-publicacion.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { PublicacionService } from 'app/servicios/publicaciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-publicaciones-detalle',
  templateUrl: './publicaciones-detalle.component.html',
  styleUrls: ['./publicaciones-detalle.component.scss']
})
export class PublicacionesDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idPublicacion: string = this.obtenerId().idPublicacion;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  imagen: any = '';
  categorias: any = [];
  documentos: any = [];
  publicaciones: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  PublicacionForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    idCategoriaPublicacion: new FormControl(0),
    rutaImagen: new FormControl(''),
    estado: new FormControl(1),
    descripcion: new FormControl(''),
    mostrarNinios: new FormControl(0)
  });

  DocumentoForm = new FormGroup({
    rutaDocumento: new FormControl(''),
    titulo: new FormControl(''),
    idPublicacion: new FormControl('')
  });


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciopublicacion: PublicacionService,
    private serviciocatalogo: CatalogoCategoriasService,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {
    this.obtenerCatalogo()
    if (this.idPublicacion) {
      this.serviciopublicacion.getPublicacion(this.idPublicacion).subscribe(response => {
        this.PublicacionForm.patchValue(response[0])
        this.imagen = response[0].rutaImagen;
        this.obtenerDocumentos()
      })
    }
  }

  obtenerCatalogo = () => {
    this.serviciocatalogo.getCatalogoCategorias().subscribe(response => {
      this.categorias = response;
    });
  }

  obtenerDocumentos = () => {
    this.serviciopublicacion.getDocsPublicacion(this.idPublicacion).subscribe(response => {
      this.documentos = response;
    });
  }


  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.PublicacionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idPublicacion) {
      this.edit = false;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let id;
    let result: any = {};
    let imagen = this.PublicacionForm.get('rutaImagen').value;

    if (this.PublicacionForm.get('rutaImagen').value) {
      this.PublicacionForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.PublicacionForm.addControl('orden', new FormControl(0));

    this.serviciopublicacion.getPublicaciones(this.PublicacionForm.get('idCategoriaPublicacion').value).subscribe(response => {
      if (response) {
        this.publicaciones = response;
        this.PublicacionForm.controls['orden'].setValue(this.publicaciones.length + 1)

        this.serviciopublicacion.crearPublicacion(this.PublicacionForm.value).subscribe(response => {
          result = response;
          id = result.reps[0].id;

          if (this.PublicacionForm.get('rutaImagen').value) {
            this.guardarImagenPHP(imagen)
          }

          if (this.DocumentoForm.get('rutaDocumento').value) {
            this.guardarImagenBd(id)
          }
          this.PublicacionForm.reset()
          this.PublicacionForm.markAsPristine()
          this.PublicacionForm.markAsUntouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/publicaciones')
        });
      }
    })

  }

  //GUARDAR IMAGEN EN BD
  guardarImagenBd = (id) => {
    if (this.idPublicacion) {
      this.DocumentoForm.controls['idPublicacion'].setValue(this.idPublicacion)
    } else {
      this.DocumentoForm.controls['idPublicacion'].setValue(id)
    }
    let pdf = this.DocumentoForm.get('rutaDocumento').value;
    pdf._files.forEach(element => {
      this.DocumentoForm.get('rutaDocumento').setValue(element.name)
      this.serviciopublicacion.crearDocPublicacion(this.DocumentoForm.value).subscribe(response => {
        this.guardarPdfPHP(element);
      })
    });
    if (this.idPublicacion) {
      this.obtenerDocumentos()
    }
  }

  actualizarDatos = () => {

    let imagen = this.PublicacionForm.get('rutaImagen').value;
    this.PublicacionForm.controls['rutaImagen'].setValue(imagen._fileNames);

    this.serviciopublicacion.actualizarPublicacion(this.idPublicacion, this.PublicacionForm.value).subscribe(response => {
      if (this.PublicacionForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }

      if (this.DocumentoForm.get('rutaDocumento').value) {
        this.guardarImagenBd(this.idPublicacion)
      }

      this.PublicacionForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/publicaciones')
    });
  }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.PublicacionForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.PublicacionForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  guardarPdfPHP = (pdf: any) => {
    this.DocumentoForm.controls['rutaDocumento'].setValue(pdf);
    const pdfData = new FormData();
    pdfData.append('pdf', this.DocumentoForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  //ELIMNAR IMAGEN Y DOCUMENTO

  eliminarImagen = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciopublicacion.actualizarEstadoImagen(this.idPublicacion, this.PublicacionForm.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen).subscribe(response => {

          })
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }

    })
  }

  eliminarDocumento = (id, name) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciopublicacion.eliminarDocsPublicacion(id).subscribe(response => {
          this.serviciodocumento.eliminarPDF(name).subscribe(response => {

          })
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }

}
