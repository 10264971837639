export const GanadorTable = {
    idAnio: "selectA",
    nombreGanador:"text",
    premio:"text",
    obra:"text",
    isEdit: "isEdit"
}

export const GanadorNinoTable = {
    idPuesto: "selectP",
    idCategoria: "selectC",
    cuento:"text",
    rutaDocumento:"disable",
    estado:"select",
    isEdit: "isEdit"
}