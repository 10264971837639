import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class OrganigramaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getOrganigramas(){
    return this.http.get(`${this.API_URI}/organigrama`)
  }

  getOrganigrama(id:string){
    return this.http.get(`${this.API_URI}/organigrama/${id}`);
  }

  actualizarOrganigrama(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/organigrama/${id}`, datos);
  }
  
  crearOrganigrama(datos: any) {
    return this.http.post(`${this.API_URI}/organigrama`, datos);
  }

  eliminarOrganigrama(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/organigrama/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/organigrama/estadoDocumento/${id}`, datos);
  }

  getDescripcion(id:number){
    return this.http.get(`${this.API_URI}/organigrama/descripcion/${id}`);
  }

  actualizarDescripcion(id: number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/organigrama/descripcion/${id}`, datos);
  }
  

}
