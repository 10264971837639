import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class SedesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getSedes(){
    return this.http.get(`${this.API_URI}/sedes`)
  }

  getSede(id:string){
    return this.http.get(`${this.API_URI}/sedes/${id}`);
  }

  actualizarSede(id: string | number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sedes/${id}`, sede);
  }
  
  crearSede(sede: any) {
    return this.http.post(`${this.API_URI}/sedes`, sede);
  }

  eliminarSede(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/${id}`);
  }

  getServicio(id:string){
    return this.http.get(`${this.API_URI}/sedes/servicios/${id}`);
  }

  actualizarServicio(id: string | number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sedes/servicios/${id}`, sede);
  }
  
  crearServicio(sede: any) {
    return this.http.post(`${this.API_URI}/sedes/servicios/sede`, sede);
  }

  eliminarServicio(idServicio: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/servicios/${idServicio}`);
  }

  getMunicipio(id:string){
    return this.http.get(`${this.API_URI}/sedes/municipios/${id}`);
  }

  actualizarMunicipio(id: string | number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sedes/municipios/${id}`, sede);
  }
  
  crearMunicipio(sede: any) {
    return this.http.post(`${this.API_URI}/sedes/municipios/sede`, sede);
  }

  eliminarMunicipio(idMunicipio: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/municipios/${idMunicipio}`);
  }

  getPersonal(id:string){
    return this.http.get(`${this.API_URI}/sedes/personal/${id}`);
  }

  actualizarPersonal(id: string | number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sedes/personal/${id}`, sede);
  }
  
  crearPersonal(sede: any) {
    return this.http.post(`${this.API_URI}/sedes/personal/sede`, sede);
  }

  eliminarPersonal(idPersonal: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/personal/${idPersonal}`);
  }

  getTelefonos(id:string){
    return this.http.get(`${this.API_URI}/sedes/telefono/${id}`);
  }

  actualizarTelefono(id: string | number, sede: any): Observable<any> {
    return this.http.put(`${this.API_URI}/sedes/telefono/${id}`, sede);
  }
  
  crearTelefono(sede: any) {
    return this.http.post(`${this.API_URI}/sedes/telefono/sede`, sede);
  }

  eliminarTelefono(idTelefono: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/telefono/${idTelefono}`);
  }

  //ELIMINAR TODOS LOS DATOS DE UNA SEDE

  eliminarTodoTelefono(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/eliminarTelefono/${id}`);
  }

  eliminarTodoServicio(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/eliminarServicio/${id}`);
  }

  eliminarTodoPersonal(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/eliminarPersonal/${id}`);
  }

  eliminarTodoMunicipio(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/eliminarMunicipio/${id}`);
  }

  //ELIMINAR IMAGEN

  eliminarImagen(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/sedes/eliminarImagen/${id}`);
  }


}
