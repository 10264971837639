import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { MensajePresidenteService } from 'app/servicios/mensaje-presidente.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mensaje-presidente',
  templateUrl: './mensaje-presidente.component.html',
  styleUrls: ['./mensaje-presidente.component.scss']
})
export class MensajePresidenteComponent implements OnInit {

  MensajePresidente = new FormGroup({
    mensajePresidente: new FormControl('', [Validators.required])
  });

  constructor(private mensajeservices: MensajePresidenteService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.obtenerMensajePresidente()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.MensajePresidente.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerMensajePresidente = () => {
    this.mensajeservices.getMensajePresidente(1).subscribe(response => {
      // this.ckeditorContent = response[0].mensajePresidente;
      this.MensajePresidente.patchValue(response[0]);
    })
  }


  guardarDatos = () => {
    this.mensajeservices.actualizarMensajePresidente(1, this.MensajePresidente.value).subscribe(response => {
      this.openSnackBar('REGISTRO ACTUALIZADO!');
    })

  }

}
