import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { DataService } from 'app/servicios/data.service';
import { ConsejoConsultivoService } from 'app/servicios/consejo-consultivo.service';
import { Observable } from 'rxjs';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment.prod';
import { DocumentService } from 'app/servicios/documentos.service';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';


@Component({
  selector: 'app-consejo-consultivo-ver',
  templateUrl: './consejo-consultivo-ver.component.html',
  styleUrls: ['./consejo-consultivo-ver.component.scss']
})
export class ConsejoConsultivoVerComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idPersonalConsultivo: string = this.obtenerId().idConsejo;

  //VARIABLES
  edit: boolean = false;
  curriculum: any = '';
  imagen: any = '';
  PDF_HOST = environment.API_PDF;
  IMAGEN_HOST = environment.API_IMAGEN;
  personal: any = []


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  PersonalConsejo = new FormGroup({
    nombre: new FormControl('', [Validators.required]),
    puesto: new FormControl('', [Validators.required]),
    curriculum: new FormControl(''),
    facebook: new FormControl(''),
    instagram: new FormControl(''),
    twitter: new FormControl(''),
    rutaImagen: new FormControl('')
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private consejoservices: ConsejoConsultivoService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idPersonalConsultivo) {
      this.consejoservices.getPersonalConsejo(this.idPersonalConsultivo).subscribe(response => {
        this.PersonalConsejo.patchValue(response[0]);
        this.curriculum = response[0].curriculum;
        this.imagen = response[0].rutaImagen;

      });
    }
  }


  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.PersonalConsejo.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idPersonalConsultivo) {
      this.edit = false;
    }
  }

  guardarDatos = () => {
    let pdf = this.PersonalConsejo.get('curriculum').value;
    this.PersonalConsejo.controls['curriculum'].setValue(pdf._fileNames)

    let imagen = this.PersonalConsejo.get('rutaImagen').value;
    this.PersonalConsejo.controls['rutaImagen'].setValue(imagen._fileNames)

    this.PersonalConsejo.addControl('orden', new FormControl(0));
    this.consejoservices.getConsejoConsultivo().subscribe(response => {
      if (response) {
        this.personal = response;
        this.PersonalConsejo.controls['orden'].setValue(this.personal.length + 1)
      }
    });

    this.consejoservices.crearPersonalConsejo(this.PersonalConsejo.value).subscribe(response => {
      if (this.PersonalConsejo.get('curriculum').value) {
        this.guardarPDF(pdf)
      }
      if (this.PersonalConsejo.get('rutaImagen').value) {
        this.guardarImagen(imagen)
      }
      this.PersonalConsejo.reset();
      this.router.navigateByUrl('/consejo-consultivo')
    })
  }

  actualizarDatos = () => {
    let pdf = this.PersonalConsejo.get('curriculum').value;
    this.PersonalConsejo.controls['curriculum'].setValue(pdf._fileNames)

    let imagen = this.PersonalConsejo.get('rutaImagen').value;
    this.PersonalConsejo.controls['rutaImagen'].setValue(imagen._fileNames)

    this.consejoservices.actualizarPersonalConsejo(this.idPersonalConsultivo, this.PersonalConsejo.value).subscribe(response => {
      if (this.PersonalConsejo.get('curriculum').value) {
        this.guardarPDF(pdf)
      }
      if (this.PersonalConsejo.get('rutaImagen').value) {
        this.guardarImagen(imagen)
      }
      this.PersonalConsejo.reset();
      this.router.navigateByUrl('/consejo-consultivo')
    })
  }

  guardarImagen = (imagen: any) => {
    this.PersonalConsejo.controls['rutaImagen'].setValue(imagen._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.PersonalConsejo.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });
  }

  guardarPDF = (pdf: any) => {
    this.PersonalConsejo.controls['curriculum'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.PersonalConsejo.get('curriculum').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  //ELIMNAR IMAGEN Y DOCUMENTO

  eliminarImagen = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.consejoservices.actualizarEstadoImagen(this.idPersonalConsultivo, this.PersonalConsejo.value).subscribe(response => {
          this.serviciodocumento.eliminarImagen(this.imagen).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }

    })
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.consejoservices.actualizarEstadoDocumento(this.idPersonalConsultivo, this.PersonalConsejo.value).subscribe(response=>{
      this.serviciodocumento.eliminarPDF(this.curriculum).subscribe(response=>{

      });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }

    })
  }

}
