import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class SupervisionPermanenteService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getSupervisionAll(){
    return this.http.get(`${this.API_URI}/supervision-permanente`)
  }

  getSupervision(id:string){
    return this.http.get(`${this.API_URI}/supervision-permanente/${id}`);
  }

  actualizarSupervision(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/supervision-permanente/${id}`, datos);
  }
  
  crearSupervision(datos: any) {
    return this.http.post(`${this.API_URI}/supervision-permanente`, datos);
  }

  eliminarSupervision(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/supervision-permanente/${id}`);
  }

}
