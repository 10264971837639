import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UnidadProfesionalismoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getUnidadProfesionalismo(id:number){
    return this.http.get(`${this.API_URI}/unidad-profesionalismo/${id}`);
  }

  actualizarUnidadProfesionalismo(id: number, data: any): Observable<any> {
    return this.http.put(`${this.API_URI}/unidad-profesionalismo/${id}`, data);
  }
  

}
