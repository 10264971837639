import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class NormatividadService {

  API_URI = environment.API_URL;
  API_SERVER_PHP = environment.API_SERVER_PHP;

  constructor(private http: HttpClient) { }

  getNormatividadAll(id: string | number) {
    return this.http.get(`${this.API_URI}/normatividad/filtro/${id}`)
  }

  getNormatividad(id: string) {
    return this.http.get(`${this.API_URI}/normatividad/${id}`);
  }

  actualizarNormatividad(id: string | number, normatividad: any): Observable<any> {
    return this.http.put(`${this.API_URI}/normatividad/${id}`, normatividad);
  }

  crearNormatividad(normatividad: any) {
    return this.http.post(`${this.API_URI}/normatividad`, normatividad);
  }

  eliminarNormatividad(idNormatividad: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/normatividad/${idNormatividad}`);
  }

  guardarPdf(pdf: FormData) {
    return this.http.post(`${this.API_SERVER_PHP}/insertarPdf.php`, pdf);
  }
}
