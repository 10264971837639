import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ArmonizacionDetalleTable } from 'app/model/armonizacion';
import { CatalogoDetalleService } from 'app/servicios/catalogo-detalle-armonizacion.service';
import { CatalogoEncabezadoService } from 'app/servicios/catalogo-encabezado-armonizacion.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-armonizacion-detalle-editar',
  templateUrl: './armonizacion-detalle-editar.component.html',
  styleUrls: ['./armonizacion-detalle-editar.component.scss']
})
export class ArmonizacionDetalleEditarComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idEncabezadoArmonizacion: string = this.obtenerId().idEncabezadoArmonizacion;

  //VARIABLES
  edit: boolean = false;
  array: any = [];
  tituloArmonizacion: any = '';
  detalle: any = [];
  conteoArray: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  DetalleForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    idEncabezadoArmonizacion: new FormControl(0, Validators.required),
    estado: new FormControl(1),
    orden: new FormControl(0)
  });

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsArmonizacion: string[] = Object.keys(ArmonizacionDetalleTable);
  dataSchemaArmonizacion = ArmonizacionDetalleTable;
  dataSourceArmonizacion = [...this.detalle];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoDetalleService,
    private catalogoserviceencabezado: CatalogoEncabezadoService
  ) { }

  ngOnInit(): void {
    if (this.idEncabezadoArmonizacion) {
      this.obtenerEncabezados(this.idEncabezadoArmonizacion)
      this.obtenerDetalle(this.idEncabezadoArmonizacion)
      // this.catalogoservice.getCatalogoDetalle(this.idEncabezadoArmonizacion).subscribe(response => {
      //   this.DetalleForm.patchValue(response[0])
      // });
    }
  }

  obtenerEncabezados = (id) => {
    this.catalogoserviceencabezado.getCatalogoEncabezado(id).subscribe(response => {
      this.tituloArmonizacion = response[0].nombre;
    });
  }

  obtenerDetalle = (id) => {

    this.dataSourceArmonizacion.length = 0;
    this.catalogoservice.getCatalogoDetallesArmonizacion(id).subscribe(response => {
      this.array = response;
      this.conteoArray = this.array.length;
      this.array.forEach(detalle => {
        this.dataSourceArmonizacion.push(detalle)
        this.tableA.renderRows()
      });
    });

  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.DetalleForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  guardarDetalleArmonizacion = () => {

    this.DetalleForm.controls['idEncabezadoArmonizacion'].setValue(this.idEncabezadoArmonizacion)
    this.DetalleForm.controls['orden'].setValue(this.conteoArray + 1)
    this.dataSourceArmonizacion.push(this.DetalleForm.value)
    this.detalle.push(this.DetalleForm.value)
    this.tableA.renderRows()
    this.DetalleForm.reset()
  }


  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    // this.catalogoservice.crearCatalogoDetalle(this.DetalleForm.value).subscribe(response => {
    //   this.DetalleForm.reset()
    //   this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
    //   this.router.navigateByUrl('/catalogo-detalle')
    // });

  }

  actualizarDatos = () => {


    if (this.detalle.length > 0) {
      this.detalle.forEach(detalle => {
        delete detalle.isEdit;
        this.catalogoservice.crearCatalogoDetalle(detalle).subscribe(response => {
        });
      });
    }

      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-detalle')
  }

  editarArmonizacion(row) {
    if (row.id === 0) {
    } else {
      if (row.idDetalleArmonizacion) {
        delete row.isEdit
        this.catalogoservice.actualizarCatalogoDetalle(row.idDetalleArmonizacion, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
        });
      } else {
        row.isEdit = false
      }
    }
  }


  eliminarArmonizacion(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.catalogoservice.eliminarCatalogoDetalle(id).subscribe(() => {
          this.obtenerDetalle(this.idEncabezadoArmonizacion)
        })
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.array, event.previousIndex, event.currentIndex);

    this.dataSourceArmonizacion.length = 0;
    this.array.forEach(detalle => {
      this.dataSourceArmonizacion.push(detalle)
    });
    this.tableA.renderRows()

    this.array.reverse()
    this.array.forEach(detalle => {
      detalle.orden = i;

      this.catalogoservice.actualizarCatalogoDetalle(detalle.idDetalleArmonizacion, detalle).subscribe(response => {
      });

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.array.reverse()
  }

}
