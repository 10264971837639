import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { GaleriaService } from 'app/servicios/galeria.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';
import * as moment from 'moment';


@Component({
  selector: 'app-galeria-detalle',
  templateUrl: './galeria-detalle.component.html',
  styleUrls: ['./galeria-detalle.component.scss']
})
export class GaleriaDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idGaleria: string = this.obtenerId().idGaleria;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagenes: any = [];
  labelPosition:number = 1


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  GaleriaForm = new FormGroup({
    fecha: new FormControl(new Date, Validators.required),
    lugar: new FormControl('', Validators.required),
    descripcion: new FormControl(''),
    estado: new FormControl(1),
  });

  ImagenesForm = new FormGroup({
    rutaImagen: new FormControl(null),
    idGaleria: new FormControl(null),
    principal: new FormControl(0)
  })

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciogaleria: GaleriaService,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {
    if (this.idGaleria) {
      this.serviciogaleria.getGaleria(this.idGaleria).subscribe(response => {
        const fechanew = response[0].fecha.split('T')
        response[0].fecha = fechanew[0];
        this.GaleriaForm.patchValue(response[0])

        let fechaDate = new Date(this.GaleriaForm.get('fecha').value + 'T00:00');

        this.GaleriaForm.controls['fecha'].setValue(fechaDate)
        this.obtenerImagenes(this.idGaleria)
      })
    }
  }

  obtenerImagenes = (id) => {
    this.serviciogaleria.getImagenes(id).subscribe(response => {
      this.imagenes = response;
    });
  }



  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.GaleriaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }


  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idGaleria) {
      this.edit = false;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.GaleriaForm.controls['fecha'].setValue(moment(this.GaleriaForm.get('fecha').value).format('YYYY-MM-DD'));   
    let result: any = {};
    let id;
    this.serviciogaleria.crearGaleria(this.GaleriaForm.value).subscribe(response => {
      result = response;
      if (this.ImagenesForm.get('rutaImagen').value) {
        id = result.reps[0].id;
        this.guardarImagenBd(id)
      }
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      // this.GaleriaForm.reset();
      this.GaleriaForm.markAsPristine()
      this.GaleriaForm.markAllAsTouched()
      this.router.navigateByUrl('/galeria-fotografica')
    });
  }

  actualizarDatos = () => {
    this.GaleriaForm.controls['fecha'].setValue(moment(this.GaleriaForm.get('fecha').value).format('YYYY-MM-DD'));   
    this.serviciogaleria.actualizarGaleria(this.idGaleria, this.GaleriaForm.value).subscribe(response => {
      if (this.ImagenesForm.get('rutaImagen').value) {
        this.guardarImagenBd(0)
      }
      this.GaleriaForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/galeria-fotografica')
    });
  }

    //GUARDAR IMAGEN EN PROYECTO
    guardarImagenPHP = (imagen: any) => {
      imagen._files.forEach(element => {
        this.ImagenesForm.controls['rutaImagen'].setValue(element);
        const imgData = new FormData();
        imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
        this.serviciodocumento.guardarImg(imgData).subscribe(response => {
        });
      });
  
    }
  
    //GUARDAR IMAGEN EN BD
    guardarImagenBd = (id) => {
      if(this.idGaleria){
        this.ImagenesForm.controls['idGaleria'].setValue(this.idGaleria)
      }else{
        this.ImagenesForm.controls['idGaleria'].setValue(id)
      }
      let imagen = this.ImagenesForm.get('rutaImagen').value;
      imagen._files.forEach(element => {
        this.ImagenesForm.get('rutaImagen').setValue(element.name)
        this.serviciogaleria.crearImagen(this.ImagenesForm.value).subscribe(response => {
          this.guardarImagenPHP(imagen);
        })
      });
    }

    eliminarImagen = (id, name) =>{
      this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
        if (confirm) {
       this.serviciogaleria.eliminarImagen(id).subscribe(response=>{
         this.serviciodocumento.eliminarImagen(name).subscribe(response =>{

         });
        this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
        this.ngOnInit()
       })
        }
    
      })
    }

    radioSelected = (datos) =>{

      this.imagenes.forEach(element => {
        if(element.principal == 1){
          element.principal = 0;
          this.serviciogaleria.actualizarImagen(element.idImagenGaleria, element).subscribe(response =>{
          });
        }
      });
      datos.principal = 1;
      this.serviciogaleria.actualizarImagen(datos.idImagenGaleria, datos).subscribe(response =>{
        this.openSnackBar('El estado se ha actualizado!!')
        this.obtenerImagenes(this.idGaleria)
        this.labelPosition = 1
      });
    }

}
