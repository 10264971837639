import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { GruposPErmisosService } from 'app/servicios/configuracion/grupos-permisos.service';
import { UsuariosService } from 'app/servicios/configuracion/usuarios.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-usuarios-detalle',
  templateUrl: './usuarios-detalle.component.html',
  styleUrls: ['./usuarios-detalle.component.scss']
})
export class UsuariosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idUsuario: string = this.obtenerId().idUsuario;

  //VARIABLES
  edit: boolean = false;
  array: any = [];
  grupos: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  UsuarioForm = new FormGroup({
    idGrupo: new FormControl(1),
    usuario: new FormControl('', Validators.required),
    contrasena: new FormControl('', Validators.required),
    estado: new FormControl(1)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciosUsuarios: UsuariosService,
    private servicioGrupos: GruposPErmisosService) { }

  ngOnInit(): void {
    this.obtenerGrupos()
    if (this.idUsuario) {
      this.serviciosUsuarios.getUsuario(this.idUsuario).subscribe(response => {
        this.UsuarioForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.UsuarioForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  obtenerGrupos = () =>{
    this.servicioGrupos.getGruposPermisos().subscribe(response =>{
      this.grupos = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
        this.serviciosUsuarios.crearUsuario(this.UsuarioForm.value).subscribe(response => {
          if (response) {
          }
          this.UsuarioForm.reset()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/usuarios')
        });

  }

  actualizarDatos = () => {
    this.serviciosUsuarios.actualizarUsuario(this.idUsuario, this.UsuarioForm.value).subscribe(response => {
      this.UsuarioForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/usuarios')
    });
  }

}
