import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SupervisionPermanenteService } from 'app/servicios/supervision-permanente.service';

@Component({
  selector: 'app-supervision-permanente',
  templateUrl: './supervision-permanente.component.html',
  styleUrls: ['./supervision-permanente.component.scss']
})
export class SupervisionPermanenteComponent implements OnInit {

  displayedColumns: string[] = ['idSupervision', 'descripcion', 'estado', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public supervision: any = [];


  constructor(private servicesupervision: SupervisionPermanenteService) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () => {
    this.servicesupervision.getSupervisionAll().subscribe(response =>{
      this.supervision = response;
      this.dataSource = new MatTableDataSource(this.supervision)
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
