import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoVideosService } from 'app/servicios/catalogos/catalogo-videos.service';
import { DataService } from 'app/servicios/data.service';
import { VideosService } from 'app/servicios/videos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-video-detalle',
  templateUrl: './video-detalle.component.html',
  styleUrls: ['./video-detalle.component.scss']
})
export class VideoDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idVideo: string = this.obtenerId().idVideo;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  AvisoPDF: any = '';
  imagenes: any = [];
  videos: any = [];
  categorias: any = []


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  VideoForm = new FormGroup({
    url: new FormControl('', Validators.required),
    idCatalogoVideo: new FormControl(1, Validators.required),
    estado: new FormControl(1),
    orden: new FormControl(0)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciovideo: VideosService,
    private serviciocatalogo: CatalogoVideosService
  ) { }

  ngOnInit(): void {

    this.obtenerCategorias()
    if (this.idVideo) {
      this.serviciovideo.getVideo(this.idVideo).subscribe(response => {
        this.VideoForm.patchValue(response[0])
        this.AvisoPDF = response[0].rutaDocumento;
      })
    }

  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.VideoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  obtenerCategorias = () => {
    this.serviciocatalogo.getCatalgoVideos().subscribe(response => {
      this.categorias = response
    })
  }


  guardarDatos = () => {

    this.serviciovideo.getVideos(this.VideoForm.get('idCatalogoVideo').value).subscribe(response =>{
      this.videos = response;
      this.VideoForm.controls['orden'].setValue(this.videos.length + 1)
      this.serviciovideo.crearVideo(this.VideoForm.value).subscribe(response => {
        this.VideoForm.markAsPristine()
        this.VideoForm.markAllAsTouched()
        this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
        this.router.navigateByUrl('/videos')
      });
    });

  }

  actualizarDatos = () => {

    this.serviciovideo.actualizarVideo(this.idVideo, this.VideoForm.value).subscribe(response => {

      this.VideoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/videos')
    });
  }

}
