import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DescripcionAudiosService } from 'app/servicios/audio-descripcion.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-audio-descripcion',
  templateUrl: './audio-descripcion.component.html',
  styleUrls: ['./audio-descripcion.component.scss']
})
export class AudioDescripcionComponent implements OnInit {

  //VARIABLES
  imagen: any = '';
  IMAGEN_HOST = environment.API_IMAGEN;


  DescripcionForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required]),
    rutaImagen: new FormControl('')
  });

  constructor(private serviciosDatos: DescripcionAudiosService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private serviciodocumento: DocumentService
              ) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.DescripcionForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerDatos = () => {
    this.serviciosDatos.getDescripcionAudios(1).subscribe(response => {
      // this.ckeditorContent = response[0].DescripcionForm;
      this.DescripcionForm.patchValue(response[0]);
      this.imagen = response[0].rutaImagen;
    })
  }


  guardarDatos = () => {
    let imagen = this.DescripcionForm.get('rutaImagen').value;
    if (this.DescripcionForm.get('rutaImagen').value) {
      this.DescripcionForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.serviciosDatos.actualizarDescripcionAudios(1, this.DescripcionForm.value).subscribe(response => {

      if (this.DescripcionForm.get('rutaImagen').value) {
        this.guardarImagen(imagen)
      }

      this.DescripcionForm.markAsPristine()
      this.DescripcionForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');
      this.obtenerDatos()
    })

  }

  guardarImagen = (pdf:any) => {
    this.DescripcionForm.controls['rutaImagen'].setValue(pdf._files[0]);
    const imgData = new FormData();
    imgData.append('imagen', this.DescripcionForm.get('rutaImagen').value);
    this.serviciodocumento.guardarImg(imgData).subscribe(response => {
    });
  }

  eliminarImagen = () =>{

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciosDatos.actualizarEstadoImagen(1, this.DescripcionForm.value).subscribe(response=>{
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.obtenerDatos()
        })
      }
  
    })
    
  }

}
