import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { InconstitucionalidadService } from 'app/servicios/inconstitucionalidad.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inconstitucionalidad-detalle',
  templateUrl: './inconstitucionalidad-detalle.component.html',
  styleUrls: ['./inconstitucionalidad-detalle.component.scss']
})
export class InconstitucionalidadDetalleComponent implements OnInit {

  // obtenerId = () => this.activatedRoute.snapshot.params;
  // //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  // idInconstitucionalidad: string = this.obtenerId().idInconstitucionalidad;

  // //VARIABLES
  // edit: boolean = false;
  // categorias: any = [];
  // inconstitucionalidad: any = '';
  // PDF_HOST = environment.API_PDF;


  // //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  // idInconstitucionalidadForm = new FormGroup({
  //   fecha: new FormControl(new Date, Validators.required),
  //   asunto: new FormControl('', Validators.required),
  //   rutaDocumento: new FormControl(''),
  //   estado: new FormControl(1)
  // });

  constructor(
    // private router: Router,
    // private activatedRoute: ActivatedRoute,
    // public navigation: DataService,
    // private dialog: MatDialog,
    // private snackBar: MatSnackBar,
    // private servicioinconstitucionalidad: InconstitucionalidadService,
    // private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    // if(this.idInconstitucionalidad){
    //   this.servicioinconstitucionalidad.getinconstitucionalidad(this.idInconstitucionalidad).subscribe(response =>{
    //     this.idInconstitucionalidadForm.patchValue(response[0])
    //     this.inconstitucionalidad = response[0].rutaDocumento;
    //   })
    // }
    
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  // canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
  //   if (this.idInconstitucionalidadForm.dirty) {
  //     const dialogRef = this.dialog.open(MatDialogComponent, {
  //       data: {
  //         message: ' ¿ Desea salir sin guardar los cambios ? ',
  //         buttonText: {
  //           ok: 'Aceptar',
  //           cancel: 'Cancelar'
  //         }
  //       }
  //     });
  //     return dialogRef.afterClosed();
  //   } else {
  //     return true;
  //   }

  // }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  // openSnackBar(message: string) {
  //   this.snackBar.open(message, "Aceptar", { duration: 2000 });
  // }

  // guardarDatos = () => {
  //   let pdf = this.idInconstitucionalidadForm.get('rutaDocumento').value;
  //   this.idInconstitucionalidadForm.controls['rutaDocumento'].setValue(pdf._fileNames)
  //   this.idInconstitucionalidadForm.controls['fecha'].setValue(moment(this.idInconstitucionalidadForm.get('fecha').value).format('YYYY-MM-DD'));   
  //   this.servicioinconstitucionalidad.crearinconstitucionalidad(this.idInconstitucionalidadForm.value).subscribe(response => {
  //     if (this.idInconstitucionalidadForm.get('rutaDocumento').value) {
  //       this.guardarPDF(pdf)
  //     }
  //     this.idInconstitucionalidadForm.reset()
  //     this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
  //     this.router.navigateByUrl('/inconstitucionalidad')
  //   });
  // }

  // actualizarDatos = () => {
  //   let pdf = this.idInconstitucionalidadForm.get('rutaDocumento').value;
  //   this.idInconstitucionalidadForm.controls['rutaDocumento'].setValue(pdf._fileNames)

  //   this.idInconstitucionalidadForm.controls['fecha'].setValue(moment(this.idInconstitucionalidadForm.get('fecha').value).format('YYYY-MM-DD'));   
  //   this.servicioinconstitucionalidad.actualizarinconstitucionalidad(this.idInconstitucionalidad, this.idInconstitucionalidadForm.value).subscribe(response => {
  //     if (this.idInconstitucionalidadForm.get('rutaDocumento').value) {
  //       this.guardarPDF(pdf)
  //     }
  //     this.idInconstitucionalidadForm.reset()
  //     this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
  //     this.router.navigateByUrl('/inconstitucionalidad')
  //   });
  // }

  // guardarPDF = (pdf:any) =>{
  //   this.idInconstitucionalidadForm.controls['rutaDocumento'].setValue(pdf._files[0]);
  //   const pdfData = new FormData();
  //   pdfData.append('pdf', this.idInconstitucionalidadForm.get('rutaDocumento').value);
  //   this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
  //   });
  // }
}
