import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoSubModuloGruposService } from 'app/servicios/catalogo-submodulo-grupos.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-catalogo-submodulos-grupos-detalle',
  templateUrl: './catalogo-submodulos-grupos-detalle.component.html',
  styleUrls: ['./catalogo-submodulos-grupos-detalle.component.scss']
})
export class CatalogoSubmodulosGruposDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idCatalogo: string = this.obtenerId().idCatalogo;

  //VARIABLES
  edit: boolean = false;
  datosmodulos: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  SubmoduloForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    estado: new FormControl(1),
    orden: new FormControl(0)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoSubModuloGruposService) { }

  ngOnInit(): void {
    if (this.idCatalogo) {
      this.catalogoservice.getCatalogoGrupos(this.idCatalogo).subscribe(response => {
        this.SubmoduloForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SubmoduloForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    this.catalogoservice.getCatalogosGrupos().subscribe(response => {
      if (response) {
        this.datosmodulos = response;
        this.SubmoduloForm.controls['orden'].setValue(this.datosmodulos.length + 1)
      }
    });

    this.catalogoservice.crearCatalogoGrupos(this.SubmoduloForm.value).subscribe(response => {
      this.SubmoduloForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/catalogo-submodulo-grupo')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoGrupos(this.idCatalogo, this.SubmoduloForm.value).subscribe(response => {
      this.SubmoduloForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-submodulo-grupo')
    });
  }
}
