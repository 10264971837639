import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class BibliotecaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getBibliotecas(){
    return this.http.get(`${this.API_URI}/biblioteca`)
  }

  getBiblioteca(id:string){
    return this.http.get(`${this.API_URI}/biblioteca/${id}`);
  }

  actualizarBiblioteca(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/biblioteca/${id}`, datos);
  }
  
  crearBiblioteca(datos: any) {
    return this.http.post(`${this.API_URI}/biblioteca`, datos);
  }

  eliminarBiblioteca(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/biblioteca/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/biblioteca/estadoDocumento/${id}`, datos);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/biblioteca/estadoImagen/${id}`, datos);
  }
  

}
