import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { GanadorNinoTable } from 'app/model/concurso';
import { CatalogoCategoriasNinosService } from 'app/servicios/catalogo-categoria-ninos.controller';
import { CatalogoPuestosService } from 'app/servicios/catalogo-puesto-ninos.service';
import { ConcursoNinosDetalleService } from 'app/servicios/concurso-detalle-ninos.service';
import { ConcursoNinosService } from 'app/servicios/concursos-ninos.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-concursos-ninos-detalle',
  templateUrl: './concursos-ninos-detalle.component.html',
  styleUrls: ['./concursos-ninos-detalle.component.scss']
})
export class ConcursosNinosDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idConcurso: string = this.obtenerId().idConcurso;

  //VARIABLES
  edit: boolean = false;
  puestos: any = [];
  categorias: any = [];
  ganadores: any = [];
  PDF_HOST = environment.API_PDF;



  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsGanador: string[] = Object.keys(GanadorNinoTable);
  dataSchemaGanador = GanadorNinoTable;
  dataSourceGanador = [...this.ganadores];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ConcursoForm = new FormGroup({
    nombreConcurso: new FormControl('', Validators.required)
  });

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  GanadoresForm = new FormGroup({
    idPuesto: new FormControl(0, Validators.required),
    idCategoria: new FormControl(0, Validators.required),
    cuento: new FormControl('', Validators.required),
    rutaDocumento: new FormControl('', Validators.required),
    estado: new FormControl(1)
  });


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: ConcursoNinosService,
    private serviciopuestos: CatalogoPuestosService,
    private serviciocategorias: CatalogoCategoriasNinosService,
    private servicioDetalleConcursos: ConcursoNinosDetalleService,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {

    if (this.idConcurso) {
      this.catalogoservice.getConcurso(this.idConcurso).subscribe(response => {
        this.ConcursoForm.patchValue(response[0])
      });
      this.obtenerGanadores()

    }
    this.obtenerCategorias()
    this.obtenerPuestos()
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ConcursoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  editarGanador(row) {
    if (row.id === 0) {
    } else {
      if (row.idGanador) {
        delete row.isEdit
        this.servicioDetalleConcursos.actualizarConcursoDetalle(row.idGanador, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerGanadores()
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarGanador(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDetalleConcursos.eliminarConcursoDetalle(id).subscribe(() => {
          this.obtenerGanadores()
        })
      }
    })
  }

  obtenerGanadores = () => {
    let array: any = []
    this.dataSourceGanador.length = 0;
    this.servicioDetalleConcursos.getConcursoDetalle(this.idConcurso).subscribe(response => {
      array = response;
      array.forEach(ganador => {
        this.dataSourceGanador.push(ganador)
        this.tableA.renderRows()
      });
    });

  }


  obtenerPuestos = () => {
    this.serviciopuestos.getCatalogoPuestos().subscribe(response => {
      this.puestos = response;
    })
  }

  obtenerCategorias = () => {
    this.serviciocategorias.getCatalogoCategoriasNinos().subscribe(response => {
      this.categorias = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatosGanadores = () => {

    let result: any = {};

    let pdf = this.GanadoresForm.get('rutaDocumento').value;
    if (this.GanadoresForm.get('rutaDocumento').value) {
      this.GanadoresForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    if(this.idConcurso){
      this.GanadoresForm.addControl('idConcurso', new FormControl(this.idConcurso));
      this.servicioDetalleConcursos.crearConcursoDetalle(this.GanadoresForm.value).subscribe(response => {
        result = response;
        if (this.GanadoresForm.get('rutaDocumento').value) {
          this.guardarPDF(pdf)
        }
        this.GanadoresForm.reset()
        this.obtenerGanadores()
        this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      });
    }else{
      this.dataSourceGanador.push(this.GanadoresForm.value)
      this.ganadores.push(this.GanadoresForm.value)
      if(this.GanadoresForm.get('rutaDocumento').value){
        this.guardarPDF(pdf)
      }
      this.tableA.renderRows()
      this.GanadoresForm.reset()
    }
   

  }

  guardarPDF = (pdf: any) => {
    this.GanadoresForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.GanadoresForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }


  guardarDatos = () => {
    let result: any = {};
    let id;
    this.catalogoservice.crearConcurso(this.ConcursoForm.value).subscribe(response => {
      result = response;
      id = result.reps[0].id;
      if(this.ganadores.length >= 1){
        this.ganadores.forEach(ganador => {
          ganador.idConcurso = id;
          this.servicioDetalleConcursos.crearConcursoDetalle(ganador).subscribe(response => {

          });
        });
      }

      this.ConcursoForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/concursos-ninios')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarConcurso(this.idConcurso, this.ConcursoForm.value).subscribe(response => {
      this.ConcursoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/concursos-ninios')
    });
  }
}
