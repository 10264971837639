export const SedeTable = {
    telefono: "text",
    isEdit: "isEdit"
}

export const SedeServicioTable = {
    nombreServicio:"text",
    isEdit: "isEdit"
}

export const SedePersonalTable = {
    nombrePersonal:"text",
    puesto:"text",
    isEdit: "isEdit"
}

export const SedeMunicipioTable = {
    nombre:"text",
    isEdit: "isEdit"
}
