import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { CatalogoJuegosService } from 'app/servicios/catalogo-juegos.service';
import { JuegoService } from 'app/servicios/juegos.service';

@Component({
  selector: 'app-juegos',
  templateUrl: './juegos.component.html',
  styleUrls: ['./juegos.component.scss']
})
export class JuegosComponent implements OnInit {

  displayedColumns: string[] = ['idJuego', 'nombre', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public centro_investigacion: any = [];

  constructor(private servicioDatosJuegos: JuegoService,
              private servicioDatos: CatalogoJuegosService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.servicioDatos.getCatalogoJuegos().subscribe(response =>{
      this.centro_investigacion = response;
      this.dataSource = new MatTableDataSource(this.centro_investigacion);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarRegistro = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        // this.servicioDatos.eliminarJuego(id).subscribe(response =>{
        //   this.obtenerDatos()
        // })
      }
  
    })
  }

}
