import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ConvenioColaboracionService } from 'app/servicios/convenio-colaboracion.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-convenio-colaboracion',
  templateUrl: './convenio-colaboracion.component.html',
  styleUrls: ['./convenio-colaboracion.component.scss']
})
export class ConvenioColaboracionComponent implements OnInit {


  ConvenioColaboracionForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required])
  });

  constructor(private servicioDatos: ConvenioColaboracionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.ConvenioColaboracionForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerDatos = () => {
    this.servicioDatos.getConvenioColaboracion(1).subscribe(response => {
      // this.ckeditorContent = response[0].ConvenioColaboracionForm;
      this.ConvenioColaboracionForm.patchValue(response[0]);
    })
  }


  guardarDatos = () => {
    this.servicioDatos.actualizarConvenioColaboracion(1, this.ConvenioColaboracionForm.value).subscribe(response => {
      this.ConvenioColaboracionForm.markAsPristine()
      this.ConvenioColaboracionForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');
    })

  }

}
