import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuadro-dialogo',
  templateUrl: './cuadro-dialogo.component.html',
  styleUrls: ['./cuadro-dialogo.component.scss']
})
export class CuadroDialogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
