import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProgramaRadioService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getProgramasRadio(){
    return this.http.get(`${this.API_URI}/programa-radio`)
  }

  getProgramaRadio(id:string){
    return this.http.get(`${this.API_URI}/programa-radio/${id}`);
  }

  actualizarProgramaRadio(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/programa-radio/${id}`, datos);
  }
  
  crearProgramaRadio(datos: any) {
    return this.http.post(`${this.API_URI}/programa-radio`, datos);
  }

  eliminarProgramaRadio(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/programa-radio/${id}`);
  }

  getAudios(id:string){
    return this.http.get(`${this.API_URI}/programa-radio/audio/${id}`);
  }

  actualizarAudio(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/programa-radio/audio/${id}`, datos);
  }
  
  crearAudio(datos: any) {
    return this.http.post(`${this.API_URI}/programa-radio/audio/new`, datos);
  }

  eliminarAudio(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/programa-radio/audio/${id}`);
  }

  eliminarDetallePrograma(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/programa-radio/eliminarD/${id}`);
  }
}
