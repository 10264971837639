import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoDetalleService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalogoDetalles(){
    return this.http.get(`${this.API_URI}/catalogo-armonizacion-d`)
  }

  getCatalogoDetalle(id:string){
    return this.http.get(`${this.API_URI}/catalogo-armonizacion-d/${id}`);
  }

  getCatalogoDetallesArmonizacion(id:string){
    return this.http.get(`${this.API_URI}/catalogo-armonizacion-d/detalle/${id}`);
  }
  actualizarCatalogoDetalle(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-armonizacion-d/${id}`, datos);
  }
  
  crearCatalogoDetalle(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-armonizacion-d`, datos);
  }

  eliminarCatalogoDetalle(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-armonizacion-d/${id}`);
  }

}
