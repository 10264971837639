import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ArmonizacionService } from 'app/servicios/armonizacion.service';

@Component({
  selector: 'app-armonizacion',
  templateUrl: './armonizacion.component.html',
  styleUrls: ['./armonizacion.component.scss']
})
export class ArmonizacionComponent implements OnInit {

  // displayedColumns: string[] = ['idArmonizacion', 'fecha','nombre','estado', 'ver', 'delete'];
  displayedColumns: string[] = ['idEncabezadoArmonizacion', 'nombre', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public armonizacion: any = [];

  constructor(private servicioarmonizacion: ArmonizacionService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.servicioarmonizacion.getArmonizaciones().subscribe(response =>{
      this.armonizacion = response;
      this.dataSource = new MatTableDataSource(this.armonizacion);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarCurso = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioarmonizacion.eliminarArmonizacion(id).subscribe(response =>{
          this.obtenerDatos()
        })
      }
  
    })
  }
}
