import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoDetalleService } from 'app/servicios/catalogo-detalle-armonizacion.service';
import { CatalogoEncabezadoService } from 'app/servicios/catalogo-encabezado-armonizacion.service';

@Component({
  selector: 'app-catalogo-armonizacion-detalle',
  templateUrl: './catalogo-armonizacion-detalle.component.html',
  styleUrls: ['./catalogo-armonizacion-detalle.component.scss']
})
export class CatalogoArmonizacionDetalleComponent implements OnInit {

  displayedColumns: string[] = ['idEncabezadoArmonizacion', 'nombre', 'estado', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: CatalogoEncabezadoService) { }

  ngOnInit(): void {
    this.obtenerEncabezadosArmonizaciones()
  }

  obtenerEncabezadosArmonizaciones = () =>{
    this.catalogoservice.getCatalogoEncabezados().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
