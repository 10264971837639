import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoCategoriasNinosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalogoCategoriasNinos(){
    return this.http.get(`${this.API_URI}/catalogo-ninos`)
  }

  getCatalogoCategoriaNino(id:string){
    return this.http.get(`${this.API_URI}/catalogo-ninos/${id}`);
  }

  actualizarCatalogoCategoriaNino(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-ninos/${id}`, datos);
  }
  
  crearCatalogoCategoriaNino(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-ninos`, datos);
  }

  eliminarCatalogoCategoriaNino(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-ninos/${id}`);
  }

}
