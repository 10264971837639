import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { AudioTable } from 'app/model/radio';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { ProgramaRadioService } from 'app/servicios/programa-radio.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');
import { Observable } from 'rxjs';

@Component({
  selector: 'app-programa-radio-detalle',
  templateUrl: './programa-radio-detalle.component.html',
  styleUrls: ['./programa-radio-detalle.component.scss']
})
export class ProgramaRadioDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idProgramaRadio: string = this.obtenerId().idProgramaRadio;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  AUDIO_HOST = environment.API_AUDIO;
  audios: any = [];

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsAudio: string[] = Object.keys(AudioTable);
  dataSchemaAudio = AudioTable;
  dataSourceAudio = [...this.audios];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ProgramaForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    descripcion: new FormControl(''),
    estado: new FormControl(1)
  });

  AudiosForm = new FormGroup({
    fecha: new FormControl(new Date, Validators.required),
    titulo: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    rutaAudio: new FormControl('', Validators.required),
    idProgramaRadio: new FormControl(''),
    estado: new FormControl(1)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioprograma: ProgramaRadioService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idProgramaRadio) {
      this.servicioprograma.getProgramaRadio(this.idProgramaRadio).subscribe(response => {
        this.ProgramaForm.patchValue(response[0])
        this.obtenerAudios(this.idProgramaRadio)
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ProgramaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idProgramaRadio) {
      this.edit = false;
    }
  }

  guardarDatos = () => {

    let result: any = {};
    let id;

    this.servicioprograma.crearProgramaRadio(this.ProgramaForm.value).subscribe(response => {
      result = response;
      id = result.reps[0].id;

      if(this.audios.length > 0){
        this.audios.forEach(audio => {
          delete audio.isEdit;
          audio.idProgramaRadio = id;
          this.servicioprograma.crearAudio(audio).subscribe(response => {
          });
        });
      }

      // this.ProgramaForm.reset()
      this.ProgramaForm.markAsPristine()
      this.ProgramaForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/programas-radio')
    });
  }

  actualizarDatos = () => {

    this.servicioprograma.actualizarProgramaRadio(this.idProgramaRadio, this.ProgramaForm.value).subscribe(response => {

      if(this.audios.length > 0){
        this.audios.forEach(audio => {
          delete audio.isEdit;
          audio.idProgramaRadio = this.idProgramaRadio;
          this.servicioprograma.crearAudio(audio).subscribe(response => {
          });
        });
      }

      this.ProgramaForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/programas-radio')
    });
  }

  guardarAudios = (mp3: any) => {
    this.AudiosForm.controls['rutaAudio'].setValue(mp3._files[0]);
    const audioData = new FormData();
    audioData.append('mp3', this.AudiosForm.get('rutaAudio').value);
    this.serviciodocumento.guardarAudio(audioData).subscribe(response => {
    });
  }


  //AUDIOS
  guardarAudio = () => {
    let audio = this.AudiosForm.get('rutaAudio').value;
    if (this.AudiosForm.get('titulo').value != null) {
      if(this.AudiosForm.get('rutaAudio').value){
        this.guardarAudios(audio)
      }

      this.AudiosForm.controls['fecha'].setValue(moment(this.AudiosForm.get('fecha').value).format('YYYY-MM-DD'));   
      this.AudiosForm.controls['rutaAudio'].setValue(audio._fileNames)
      console.log(this.AudiosForm.value)
      this.dataSourceAudio.push(this.AudiosForm.value)
      this.audios.push(this.AudiosForm.value)
      this.tableA.renderRows()
      this.AudiosForm.reset()
    }
  }

  obtenerAudios = (id) => {
    let array: any = []
    this.dataSourceAudio.length = 0;
    this.servicioprograma.getAudios(id).subscribe(response => {
      array = response;
      array.forEach(audio => {
        audio.fecha = moment(audio.fecha).format('YYYY-MM-DD')
        this.dataSourceAudio.push(audio)
        this.tableA.renderRows()
      });
    });
  }

  editarAudio(row) {
    if (row.id === 0) {
    } else {
      if (row.idDetalleRadio) {
        delete row.isEdit
        this.servicioprograma.actualizarAudio(row.idDetalleRadio, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerAudios(this.idProgramaRadio)
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarAudio(id, name) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioprograma.eliminarAudio(id).subscribe(() => {
          this.serviciodocumento.eliminarAudio(name).subscribe(response =>{

          })
          this.obtenerAudios(this.idProgramaRadio)
        })
      }
    })
  }


}
