import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FechasService } from 'app/servicios/fechas.service';

@Component({
  selector: 'app-fechas',
  templateUrl: './fechas.component.html',
  styleUrls: ['./fechas.component.scss']
})
export class FechasComponent implements OnInit {

  displayedColumns: string[] = ['idAnio', 'fecha', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: FechasService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.catalogoservice.getFechas().subscribe(response =>{
      this.array = response;
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

    //AVISO TIPO SNACKBAR ANGULAR MATERIAL
    openSnackBar(message: string) {
      this.snackBar.open(message, "Aceptar", { duration: 2000 });
    }
  

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.array, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.array);
    this.dataSource.sort = this.sort;
    this.array.reverse()
    this.array.forEach(fecha => {
      fecha.orden = i;

      this.catalogoservice.actualizarCatalogoFecha(fecha.idAnio, fecha).subscribe(response => {
      });

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.array.reverse()
    // this.obtenerBoletines()
  }


}
