import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class DescripcionDerechosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getDescripcionDerechos(){
    return this.http.get(`${this.API_URI}/descripcion-derecho`)
  }

  getDescripcionDerecho(id:string){
    return this.http.get(`${this.API_URI}/descripcion-derecho/${id}`);
  }

  actualizarDescripcionDerecho(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/descripcion-derecho/${id}`, datos);
  }
  
  crearDescripcionDerecho(datos: any) {
    return this.http.post(`${this.API_URI}/descripcion-derecho`, datos);
  }

  eliminarDescripcionDerecho(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/descripcion-derecho/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/descripcion-derecho/estadoDocumento/${id}`, datos);
  }

  //DOCUMENTOS

  crearDocumentos(datos: any) {
    return this.http.post(`${this.API_URI}/descripcion-derecho/conoceninos/imagen`, datos);
  }

  getDocumentos(id:string){
    return this.http.get(`${this.API_URI}/descripcion-derecho/imagen/${id}`);
  }

  eliminarDocumento(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/descripcion-derecho/eliminarI/${id}`);
  }



}
