import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { InconstitucionalidadService } from 'app/servicios/inconstitucionalidad.service';
import moment = require('moment');
import { Observable } from 'rxjs';

@Component({
  selector: 'app-inconstitucionalidad',
  templateUrl: './inconstitucionalidad.component.html',
  styleUrls: ['./inconstitucionalidad.component.scss']
})
export class InconstitucionalidadComponent implements OnInit {

  
  DescripcionForm = new FormGroup({
    contenido: new FormControl('', [Validators.required])
  });

  constructor(private serviciodescripcion: InconstitucionalidadService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerAntecendentes()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.DescripcionForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerAntecendentes = () => {
    this.serviciodescripcion.getDescripcion(1).subscribe(response => {
      this.DescripcionForm.patchValue(response[0]);
    })
  }


  guardarDatos = () => {
    this.serviciodescripcion.actualizarDescripcion(1, this.DescripcionForm.value).subscribe(response => {
      this.DescripcionForm.markAsPristine()
      this.DescripcionForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');

    })

  }

  // displayedColumns: string[] = ['idInconstitucionalidad', 'fecha','asunto','estado', 'ver'];
  // dataSource: MatTableDataSource<any>;
  // @ViewChild(MatSort) sort: MatSort;

  // public array: any = [];

  // constructor(private servicioinconstitucionalidad: InconstitucionalidadService) { }

  // ngOnInit(): void {
  //   this.obtenerRecomendaciones()
  // }

  // obtenerRecomendaciones = () => {
  //   this.servicioinconstitucionalidad.getinconstitucionalidades().subscribe(response =>{
  //     this.array = response;
  //     this.array.forEach(inconstitucionalidad => {
  //       inconstitucionalidad.fecha = moment(inconstitucionalidad.fecha).format('YYYY-MM-DD')
  //     });
  //     this.dataSource = new MatTableDataSource(this.array)
  //     this.dataSource.sort = this.sort
  //   })
  // }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

}
