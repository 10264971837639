import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DescripcionAudiosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getDescripcionAudios(id:number){
    return this.http.get(`${this.API_URI}/descrpcion-audios/${id}`);
  }

  actualizarDescripcionAudios(id: number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/descrpcion-audios/${id}`, datos);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/descrpcion-audios/estadoImagen/${id}`, datos);
  }

  

}
