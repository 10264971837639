import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoConcursoService {
  
  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalogosConcurso(){
    return this.http.get(`${this.API_URI}/catalogo-concurso`)
  }

  getCatalogoConcurso(id:string){
    return this.http.get(`${this.API_URI}/catalogo-concurso/${id}`);
  }

  actualizarCatalogoConcurso(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-concurso/${id}`, datos);
  }
  
  crearCatalogoConcurso(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-concurso`, datos);
  }

  eliminarCatalogoConcurso(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-concurso/${id}`);
  }

}
