import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { ImagenService } from 'app/servicios/imagenes.service';
import { SedesService } from 'app/servicios/sedes.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';
import { SedeTable, SedeServicioTable, SedePersonalTable, SedeMunicipioTable } from '../../model/sede'


@Component({
  selector: 'app-sede',
  templateUrl: './sede.component.html',
  styleUrls: ['./sede.component.scss']
})
export class SedeComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idSede: string = this.obtenerId().idSede;

  //DECLARACIÓN DE ARREGLOS
  municipios: any = [];
  municipiosTemp: any = [];
  personal: any = [];
  servicios: any = [];
  telefonos: any = [];

  //VARIABLES
  edit: boolean = false;
  editandoMunicipio: boolean = false;
  imagenes: any = [];
  IMAGEN_HOST = environment.API_IMAGEN;


  //DECLARAMOS TABLAS DE AANGULAR MATERIAL
  displayedColumns: string[] = Object.keys(SedeTable);
  dataSchema = SedeTable;
  dataSource = [...this.telefonos];
  @ViewChild('table', { static: true }) table: MatTable<any>;

  displayedColumnsServicios: string[] = Object.keys(SedeServicioTable);
  dataSchemaServicios = SedeServicioTable;
  dataSourceServicios = [...this.servicios];
  @ViewChild('tableS', { static: true }) tableS: MatTable<any>;

  displayedColumnsPersonal: string[] = Object.keys(SedePersonalTable);
  dataSchemaPersonal = SedePersonalTable;
  dataSourcePersonal = [...this.personal];
  @ViewChild('tableP', { static: true }) tableP: MatTable<any>;


  //DECLARACIÓN DE FORMULARIOS REACTIVOS DE LAS SEDES
  SedeForm = new FormGroup({
    nombreSede: new FormControl('', [Validators.required]),
    descripcionSede: new FormControl('', [Validators.required]),
    direccionSede: new FormControl('', [Validators.required]),
    correoSede: new FormControl('', [Validators.required]),
    ubicacion: new FormControl('')
  });

  ImagenForm = new FormGroup({
    rutaDocumento: new FormControl(''),
    idSede: new FormControl(0)
  })

  TelefonoForm = new FormGroup({
    telefono: new FormControl(null, [Validators.required])
  });

  JurisdiccionFrom = new FormGroup({
    nombre: new FormControl(null, [Validators.required])
  });

  ServiciosForm = new FormGroup({
    nombreServicio: new FormControl(null, [Validators.required])
  });

  PersonalForm = new FormGroup({
    nombrePersonal: new FormControl(null, [Validators.required]),
    puesto: new FormControl(null, [Validators.required])
  });

  constructor(private sedesservicio: SedesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciodocumento: DocumentService,
    private servicioimagen: ImagenService,
  ) { }

  ngOnInit(): void {
    //VERIFICAMOS SI EXISTE LA SEDE PARA TRAER LA INFORMACIÓN DE LA BD
    if (this.idSede) {
      this.sedesservicio.getSede(this.idSede).subscribe(response => {
        this.SedeForm.patchValue(response[0]);
        this.obtenerImagenes(this.idSede)
        this.obtenerMunicipios(this.idSede)
        this.obtenerPersonal(this.idSede)
        this.obtenerServicios(this.idSede)
        this.obtenerTelefonos(this.idSede)
        this.edit = true;
      });
    }
  }

  //IMAGENES SEDES
  obtenerImagenes = (id) => {
    this.servicioimagen.getImagenesSede(id).subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SedeForm.dirty || this.TelefonoForm.dirty || this.ServiciosForm.dirty || this.PersonalForm.dirty || this.JurisdiccionFrom.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  //VERIFICAMOS LOS CAMBIOS EN LOS INPUTS DEL FORMULARIO
  onChangeEvent(event: any) {
    if (this.idSede) {
      this.edit = false;
    }
  }

  onChangeEventMunicipio(event: any) {
    if (this.idSede) {
      this.editandoMunicipio = true;
      this.edit = false;
    }
  }

  //GUARDAMOS LA INFORMACIÓN DE LA SEDE NUEVA}

  guardarDatos = () => {
    let result: any = {};
    let id;
    this.sedesservicio.crearSede(this.SedeForm.value).subscribe(response => {
      result = response;
      id = result.reps[0].id;

      if (this.ImagenForm.get('rutaDocumento').value) {
        this.guardarImagenBd(id)
      }

      if (this.telefonos.length > 0) {
        this.telefonos.forEach(cel => {
          cel.idSede = id;
          this.sedesservicio.crearTelefono(cel).subscribe(response => {
          });
        });
      }
      if (this.servicios.length > 0) {
        this.servicios.forEach(servicio => {
          servicio.idSede = id;
          this.sedesservicio.crearServicio(servicio).subscribe(response => {

          });
        });
      }
      if (this.personal.length > 0) {
        this.personal.forEach(personal => {
          personal.idSede = id
          this.sedesservicio.crearPersonal(personal).subscribe(response => {

          });
        });
      }
      if (this.municipiosTemp.length > 0) {
        this.municipiosTemp.forEach(municipio => {
          municipio.idSede = id
          this.sedesservicio.crearMunicipio(municipio).subscribe(response => {
          });
        });
      }

      this.SedeForm.reset();
      this.ImagenForm.reset();
      this.JurisdiccionFrom.reset();
      this.PersonalForm.reset();
      this.TelefonoForm.reset();
      this.ServiciosForm.reset();
      this.router.navigateByUrl('/sedes')
    });
  }

  //ACTUALIZAMOS LA INFORMACIÓN DE LA SEDE
  actualizarDatos = () => {
    this.sedesservicio.actualizarSede(this.idSede, this.SedeForm.value).subscribe(response => {
      if (this.ImagenForm.get('rutaDocumento').value) {
        this.guardarImagenBd(0)
      }
      this.SedeForm.reset();

      if (this.telefonos.length > 0) {
        this.telefonos.forEach(cel => {
          delete cel.isEdit;
          cel.idSede = this.idSede;
          this.sedesservicio.crearTelefono(cel).subscribe(response => {
          });
        });
      }

      if (this.servicios.length > 0) {
        this.servicios.forEach(servicio => {
          servicio.idSede = this.idSede;
          this.sedesservicio.crearServicio(servicio).subscribe(response => {

          });
        });
      }

      if (this.personal.length > 0) {
        this.personal.forEach(personal => {
          personal.idSede = this.idSede
          this.sedesservicio.crearPersonal(personal).subscribe(response => {

          });
        });
      }

      if (this.municipiosTemp.length > 0) {
        this.municipiosTemp.forEach(municipio => {
          municipio.idSede = this.idSede
          this.sedesservicio.crearMunicipio(municipio).subscribe(response => {

          });
        });
      } else if (this.editandoMunicipio) {
        this.municipios.forEach(element => {
          this.sedesservicio.actualizarMunicipio(element.idMunicipioSede, element).subscribe(response => {

          })
        });
        this.editandoMunicipio = false;
      }
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/sedes')
    });
  }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.ImagenForm.controls['rutaDocumento'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenForm.get('rutaDocumento').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  //GUARDAR IMAGEN EN BD
  guardarImagenBd = (id) => {
    if(this.idSede){
      this.ImagenForm.controls['idSede'].setValue(this.idSede)
    }else{
      this.ImagenForm.controls['idSede'].setValue(id)
    }
    let imagen = this.ImagenForm.get('rutaDocumento').value;
    imagen._files.forEach(element => {
      this.ImagenForm.get('rutaDocumento').setValue(element.name)
      this.servicioimagen.crearImagenSede(this.ImagenForm.value).subscribe(response => {
        this.guardarImagenPHP(imagen);
      })
    });
  }

  //JURISDICCION
  guardarMunicipio = () => {
    if (this.JurisdiccionFrom.get('nombre').value != null) {
      this.municipios.push(this.JurisdiccionFrom.value);
      this.municipiosTemp.push(this.JurisdiccionFrom.value)
      this.JurisdiccionFrom.reset()
    }
  }

  obtenerMunicipios = (id: string) => {
    this.municipios.length = 0;
    this.sedesservicio.getMunicipio(id).subscribe(response => {
      this.municipios = response;
    });
  }

  eliminarMunicipio = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.sedesservicio.eliminarMunicipio(id).subscribe(() => {
          this.obtenerMunicipios(this.idSede)
        })
      }

    })
  }

  //SERVICIOS
  guardarServicio = () => {
    if (this.ServiciosForm.get('nombreServicio').value != null) {
      this.dataSourceServicios.push(this.ServiciosForm.value)
      this.servicios.push(this.ServiciosForm.value)
      this.tableS.renderRows()
      this.ServiciosForm.reset()
    }
  }

  obtenerServicios = (id) => {
    let array: any = []
    this.sedesservicio.getServicio(id).subscribe(response => {
      this.dataSourceServicios = [];
      array = response;
      array.forEach(servicio => {
        this.dataSourceServicios.push(servicio)
        this.tableS.renderRows()
      });
    });
  }

  editarServicio(row) {
    if (row.id === 0) {
    } else {
      if (row.idServicioSede) {
        delete row.isEdit
        this.sedesservicio.actualizarServicio(row.idServicioSede, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarServicio(id) {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {

      if (confirm) {
        this.sedesservicio.eliminarServicio(id).subscribe(() => {
          this.obtenerServicios(this.idSede)
        })
      }

    })



  }

  //PERSONAL
  guardarPersonal = () => {
    if (this.PersonalForm.get('nombrePersonal').value != null) {
      this.dataSourcePersonal.push(this.PersonalForm.value)
      this.personal.push(this.PersonalForm.value)
      this.tableP.renderRows()
      this.PersonalForm.reset()
    }
  }

  obtenerPersonal = (id) => {
    let array: any = []
    this.sedesservicio.getPersonal(id).subscribe(response => {
      array = response;
      array.forEach(personal => {
        this.dataSourcePersonal.push(personal)
        this.tableP.renderRows()
      });
    });
  }

  editarPersonal(row) {
    if (row.id === 0) {
    } else {
      if (row.idPersonal) {
        delete row.isEdit
        this.sedesservicio.actualizarPersonal(row.idPersonal, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarPersonal(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.sedesservicio.eliminarPersonal(id).subscribe(() => {
          this.obtenerServicios(this.idSede)
        })
      }
    })
  }

  //TELEFONOS
  guardarTelefono = () => {
    if (this.TelefonoForm.get('telefono').value != null) {
      this.dataSource.push(this.TelefonoForm.value)
      this.telefonos.push(this.TelefonoForm.value)
      this.table.renderRows()
      this.TelefonoForm.reset()
    }
  }

  obtenerTelefonos = (id) => {
    let array: any = []
    this.sedesservicio.getTelefonos(id).subscribe(response => {
      this.dataSource = [];
      array = response;
      array.forEach(cel => {
        this.dataSource.push(cel)
        this.table.renderRows()
      });
    });
  }

  editarTelefono(row) {
    if (row.id === 0) {
    } else {
      if (row.idTelefonoSede) {
        delete row.isEdit
        this.sedesservicio.actualizarTelefono(row.idTelefonoSede, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarTelefono(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.sedesservicio.eliminarTelefono(id).subscribe(() => {
          this.obtenerTelefonos(this.idSede)
        })
      }
    })

  }

  eliminarImagen = (id, name) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioimagen.eliminarImagenSede(id).subscribe(response =>{
          this.serviciodocumento.eliminarImagen(name).subscribe(response=>{

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }
  
    })
  }



}
