import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'app/servicios/data.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { NormatividadService } from 'app/servicios/normatividad.service';
import { Observable } from 'rxjs';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoNormatividadService } from 'app/servicios/catalogo-normatividad.service';
import { environment } from 'environments/environment.prod'

@Component({
  selector: 'app-normatividad-ver',
  templateUrl: './normatividad-ver.component.html',
  styleUrls: ['./normatividad-ver.component.scss']
})
export class NormatividadVerComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idNormatividad: string = this.obtenerId().idNormatividad;

  //VARIABLES
  edit: boolean = false;
  categorias: any = []
  PDF_HOST = environment.API_PDF;
  normatividad: any = '';
  disableds: boolean = true;
  normatividades: any = []

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  NormatividadForm = new FormGroup({
    descripcion: new FormControl('', Validators.required),
    tipo: new FormControl(0),
    url: new FormControl(''),
    idCategoria: new FormControl(0),
    estado: new FormControl(1),
    pdf: new FormControl('')
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private normatividadservices: NormatividadService,
    private catalogoservice: CatalogoNormatividadService,
  ) { }

  ngOnInit(): void {
    this.obtenerCategorias()
    if (this.idNormatividad) {
      this.normatividadservices.getNormatividad(this.idNormatividad).subscribe(response => {
        this.NormatividadForm.patchValue(response[0])
        this.normatividad = response[0].pdf;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.NormatividadForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  obtenerCategorias = () => {
    this.catalogoservice.getNormatividadAll().subscribe(response => {
      this.categorias = response
    })
  }

  guardarDatos = () => {
    let pdf = this.NormatividadForm.get('pdf').value;

    if (this.NormatividadForm.get('pdf').value) {
      this.NormatividadForm.controls['pdf'].setValue(pdf._fileNames)
    }

    this.NormatividadForm.addControl('orden', new FormControl(0));

    this.normatividadservices.getNormatividadAll(this.NormatividadForm.get('idCategoria').value).subscribe(response => {

      if (response) {
        this.normatividades = response;
        this.NormatividadForm.controls['orden'].setValue(this.normatividades.length + 1)

        this.normatividadservices.crearNormatividad(this.NormatividadForm.value).subscribe(response => {
          if (this.NormatividadForm.get('pdf').value) {
            this.NormatividadForm.controls['pdf'].setValue(pdf._files[0]);
            const pdfData = new FormData();
            pdfData.append('pdf', this.NormatividadForm.get('pdf').value);
            this.normatividadservices.guardarPdf(pdfData).subscribe(response => {
            });
          }
          this.NormatividadForm.reset()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/normatividad')
        });

      }

    })


  }

  actualizarDatos = () => {
    let pdf = this.NormatividadForm.get('pdf').value;
    this.NormatividadForm.controls['pdf'].setValue(pdf._fileNames)
    this.normatividadservices.actualizarNormatividad(this.idNormatividad, this.NormatividadForm.value).subscribe(response => {
      if (this.NormatividadForm.get('pdf').value) {
        this.NormatividadForm.controls['pdf'].setValue(pdf._files[0]);
        const pdfData = new FormData();
        pdfData.append('pdf', this.NormatividadForm.get('pdf').value);
        this.normatividadservices.guardarPdf(pdfData).subscribe(response => {
        });
      }
      this.NormatividadForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/normatividad')
    });

  }

}
