import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { BoletinesPrensaService } from 'app/servicios/boletines.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');
import { Observable } from 'rxjs';


@Component({
  selector: 'app-boletin-prensa',
  templateUrl: './boletin-prensa.component.html',
  styleUrls: ['./boletin-prensa.component.scss']
})
export class BoletinPrensaComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idBoletin: string = this.obtenerId().idBoletin;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  boletinPDF: any = '';
  imagenes: any = [];
  labelPosition:number = 1
  boletines: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  BoletinForm = new FormGroup({
    numero: new FormControl('', Validators.required),
    fecha: new FormControl(moment([]).add(1, 'days').format('YYYY-MM-DD'), Validators.required),
    // fecha: new FormControl(new Date, Validators.required),
    titulo: new FormControl('', Validators.required),
    descripcion: new FormControl(''),
    estado: new FormControl(1),
    rutaDocumento: new FormControl(''),
    slider: new FormControl(0),
    orden: new FormControl(0)
  });

  ImagenesForm = new FormGroup({
    rutaImagen: new FormControl(null),
    idBoletin: new FormControl(null),
    principal: new FormControl(0)
  })

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioboletin: BoletinesPrensaService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {

    if (this.idBoletin) {
      this.servicioboletin.getBoletinPrensa(this.idBoletin).subscribe(response => {
        const fechanew = response[0].fecha.split('T')
        response[0].fecha = fechanew[0];
        this.BoletinForm.patchValue(response[0])

        let fechaDate = new Date(this.BoletinForm.get('fecha').value + 'T00:00');

        this.BoletinForm.controls['fecha'].setValue(fechaDate)

        this.obtenerImagenes(this.idBoletin)
        this.boletinPDF = response[0].rutaDocumento;
      })
    }

  }

  //IMAGENES
  obtenerImagenes = (id) => {
    this.imagenes.length = 0;
    this.servicioboletin.getImagenes(id).subscribe(response => {
      this.imagenes = response;
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.BoletinForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  radioSelected = (datos) =>{

    this.imagenes.forEach(element => {
      if(element.principal == 1){
        element.principal = 0;
        this.servicioboletin.actualizarBoletinPrensaImagen(element.idImagenBoletin, element).subscribe(response =>{
        });
      }
    });
    datos.principal = 1;
    this.servicioboletin.actualizarBoletinPrensaImagen(datos.idImagenBoletin, datos).subscribe(response =>{
      this.openSnackBar('El estado se ha actualizado!!')
      this.obtenerImagenes(this.idBoletin)
      this.labelPosition = 1
    });
  }


  guardarDatos = () => {

    let result: any = {};
    let id;

    let pdf = this.BoletinForm.get('rutaDocumento').value;
    if (this.BoletinForm.get('rutaDocumento').value) {
      this.BoletinForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.BoletinForm.controls['fecha'].setValue(moment(this.BoletinForm.get('fecha').value).format('YYYY-MM-DD'));
    this.servicioboletin.getBoletinesPrensa().subscribe(response => {
      if(response){

        this.boletines = response;
        this.BoletinForm.controls['orden'].setValue(this.boletines.length + 1)
        this.servicioboletin.crearBoletinPrensa(this.BoletinForm.value).subscribe(response => {
          result = response;
          if (this.BoletinForm.get('rutaDocumento').value) {
            this.guardarPDF(pdf)
          }
          if (this.ImagenesForm.get('rutaImagen').value) {
            id = result.reps[0].id;
            this.guardarImagenBd(id)
          }
          // this.BoletinForm.reset()
          this.BoletinForm.markAsPristine()
          this.BoletinForm.markAllAsTouched()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/boletines-prensa')
        });

      }
    });

  }

  actualizarDatos = () => {


    let pdf = this.BoletinForm.get('rutaDocumento').value;
    if (this.BoletinForm.get('rutaDocumento').value) {
      this.BoletinForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.BoletinForm.controls['fecha'].setValue(moment(this.BoletinForm.get('fecha').value).format('YYYY-MM-DD'));
    this.servicioboletin.actualizarBoletinPrensa(this.idBoletin, this.BoletinForm.value).subscribe(response => {
      if (this.BoletinForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      // this.BoletinForm.reset()
      this.BoletinForm.markAsPristine()
      this.BoletinForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/boletines-prensa')
    });
  }


  guardarPDF = (pdf: any) => {
    this.BoletinForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.BoletinForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  //GUARDAR IMAGEN EN BD
  guardarImagenBd = (id) => {
    if (this.idBoletin) {
      this.ImagenesForm.controls['idBoletin'].setValue(this.idBoletin)
    } else {
      this.ImagenesForm.controls['idBoletin'].setValue(id)
    }
    let imagen = this.ImagenesForm.get('rutaImagen').value;
    imagen._files.forEach(element => {
      this.ImagenesForm.get('rutaImagen').setValue(element.name)
      this.servicioboletin.crearImagenes(this.ImagenesForm.value).subscribe(response => {
        this.guardarImagenPHP(imagen);
      })
    });
    if (this.idBoletin) {
      this.obtenerImagenes(this.idBoletin)
    }
  }

  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.ImagenesForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });
  }

  eliminarDocumento = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.servicioboletin.actualizarEstadoDocumento(this.idBoletin, this.BoletinForm.value).subscribe(response=>{
      this.serviciodocumento.eliminarPDF(this.boletinPDF).subscribe(response=>{

      });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
  
    })
  }

  eliminarImagen = (id, name) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.servicioboletin.eliminarImagenBoletin(id).subscribe(response=>{
      this.serviciodocumento.eliminarImagen(name).subscribe(response=>{

      });

      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.obtenerImagenes(this.idBoletin)
     })
      }
  
    })
  }

}
