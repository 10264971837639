import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoCategoriasNinosService } from 'app/servicios/catalogo-categoria-ninos.controller';

@Component({
  selector: 'app-catalogo-ninos-cat',
  templateUrl: './catalogo-ninos-cat.component.html',
  styleUrls: ['./catalogo-ninos-cat.component.scss']
})
export class CatalogoNinosCatComponent implements OnInit {

  displayedColumns: string[] = ['idCategoria', 'nombre', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public categorias: any = [];

  constructor(private catalogoservice: CatalogoCategoriasNinosService) { }

  ngOnInit(): void {
    this.obtenerCategorias()
  }

  obtenerCategorias = () =>{
    this.catalogoservice.getCatalogoCategoriasNinos().subscribe(response =>{
      this.categorias = response;
      this.dataSource = new MatTableDataSource(this.categorias);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
