import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoPuestosService } from 'app/servicios/catalogo-puesto-ninos.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-catalogo-puesto-ninos-detalle',
  templateUrl: './catalogo-puesto-ninos-detalle.component.html',
  styleUrls: ['./catalogo-puesto-ninos-detalle.component.scss']
})
export class CatalogoPuestoNinosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idPuesto: string = this.obtenerId().idPuesto;

  //VARIABLES
  edit: boolean = false;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  PuestoForm = new FormGroup({
    nombre: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoPuestosService) { }

  ngOnInit(): void {
    if (this.idPuesto) {
      this.catalogoservice.getCatalogoPuesto(this.idPuesto).subscribe(response => {
        this.PuestoForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.PuestoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.catalogoservice.crearCatalogoPuesto(this.PuestoForm.value).subscribe(response =>{
      this.PuestoForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/catalogo-puesto')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoPuesto(this.idPuesto, this.PuestoForm.value).subscribe(response =>{
      this.PuestoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-puesto')
    });
  }
}
