import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { AudioNiniosTable } from 'app/model/radio';
import { AudiosNiniosService } from 'app/servicios/audios-ninios.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-audios-ninios',
  templateUrl: './audios-ninios.component.html',
  styleUrls: ['./audios-ninios.component.scss']
})
export class AudiosNiniosComponent implements OnInit {

  //VARIABLES
  edit: boolean = false;
  AUDIO_HOST = environment.API_AUDIO;
  juegos: any = [];
  array: any = [];
  nombreJuego: any = '';

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsAudio: string[] = Object.keys(AudioNiniosTable);
  dataSchemaAudio = AudioNiniosTable;
  dataSourceAudio = [...this.juegos];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  AudioForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    audio: new FormControl(''),
    estado: new FormControl(1),
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioDatos: AudiosNiniosService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {

    this.obtenerAudios()
  }


  obtenerAudios = () => {

    let array: any = []
    this.dataSourceAudio.length = 0;
    this.servicioDatos.getAudios().subscribe(response => {
      array = response;
      array.forEach(juego => {
        this.dataSourceAudio.push(juego)
        this.tableA.renderRows()
      });
    });

  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.AudioForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  guardarDatos = () => {

    let result: any = {};

    let mp3 = this.AudioForm.get('audio').value;
    if (this.AudioForm.get('audio').value) {
      this.AudioForm.controls['audio'].setValue(mp3._fileNames)
    }

    this.servicioDatos.crearAudio(this.AudioForm.value).subscribe(response => {
      result = response;
      if (this.AudioForm.get('audio').value) {
        this.guardarAudios(mp3)
      }
      this.AudioForm.reset()
      this.obtenerAudios()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
    });

  }

  // guardarPDF = (pdf: any) => {
  //   this.AudioForm.controls['audio'].setValue(pdf._files[0]);
  //   const pdfData = new FormData();
  //   pdfData.append('pdf', this.AudioForm.get('audio').value);
  //   this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
  //   });
  // }

  guardarAudios = (mp3: any) => {
    this.AudioForm.controls['audio'].setValue(mp3._files[0]);
    const audioData = new FormData();
    audioData.append('mp3', this.AudioForm.get('audio').value);
    this.serviciodocumento.guardarAudio(audioData).subscribe(response => {
    });
  }

  editarAudio(row) {
    if (row.id === 0) {
    } else {
      if (row.idAudio) {
        delete row.isEdit
        this.servicioDatos.actualizarAudio(row.idAudio, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerAudios()
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarAudio(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.eliminarAudio(id).subscribe(() => {
          this.obtenerAudios()
        })
      }
    })
  }
}
