import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { JuegosNiniosTable } from 'app/model/juegos';
import { CatalogoJuegosService } from 'app/servicios/catalogo-juegos.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { JuegoService } from 'app/servicios/juegos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-juegos-detalle',
  templateUrl: './juegos-detalle.component.html',
  styleUrls: ['./juegos-detalle.component.scss']
})
export class JuegosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idJuego: number = this.obtenerId().idJuego;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  juegos: any = [];
  array: any = [];
  nombreJuego: any = '';

  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsJuego: string[] = Object.keys(JuegosNiniosTable);
  dataSchemaJuego = JuegosNiniosTable;
  dataSourceJuego = [...this.juegos];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  JuegoForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    rutaDocumento: new FormControl(''),
    idJuego: new FormControl(0),
    estado: new FormControl(1),
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioDatos: JuegoService,
    private servicioDatosCatalogo: CatalogoJuegosService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idJuego) {
      this.servicioDatosCatalogo.getCatalogoJuego(this.idJuego).subscribe(response => {
        this.nombreJuego = response[0].nombre;
      })
      this.obtenerJuegos()
    }
  }


  obtenerJuegos = () =>{

      let array: any = []
      this.dataSourceJuego.length = 0;
      this.servicioDatos.getJuego(this.idJuego).subscribe(response => {
        array = response;
        array.forEach(juego => {
          this.dataSourceJuego.push(juego)
          this.tableA.renderRows()
        });
      });

  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.JuegoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  guardarDatos = () => {

    let result: any = {};

    let pdf = this.JuegoForm.get('rutaDocumento').value;
    if (this.JuegoForm.get('rutaDocumento').value) {
      this.JuegoForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.JuegoForm.controls['idJuego'].setValue(this.idJuego);
    this.servicioDatos.crearJuego(this.JuegoForm.value).subscribe(response => {
      result = response;
      if (this.JuegoForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.JuegoForm.reset()
      this.obtenerJuegos()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
    });



      // this.AudiosForm.controls['rutaAudio'].setValue(audio._fileNames)
      // this.dataSourceAudio.push(this.AudiosForm.value)
      // this.audios.push(this.AudiosForm.value)
      // this.tableA.renderRows()
      // this.AudiosForm.reset()



  }

  actualizarDatos = () => {

    // let pdf = this.JuegoForm.get('rutaDocumento').value;
    // if (this.JuegoForm.get('rutaDocumento').value) {
    //   this.JuegoForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    // }
    // this.servicioDatos.actualizarAvisoPrivacidad(this.idJuego, this.JuegoForm.value).subscribe(response => {
    //   if (this.JuegoForm.get('rutaDocumento').value) {
    //     this.guardarPDF(pdf)
    //   }
    //   this.JuegoForm.reset()
    //   this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
    //   this.router.navigateByUrl('/avisos-privacidad')
    // });
  }


  guardarPDF = (pdf: any) => {
    this.JuegoForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.JuegoForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.actualizarEstadoDocumento(this.idJuego, this.JuegoForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }

  editarJuego(row) {
    if (row.id === 0) {
    } else {
      if (row.idPdfJuego) {
        delete row.isEdit
        this.servicioDatos.actualizarJuego(row.idPdfJuego, row).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          row.isEdit = false
          this.obtenerJuegos()
        });
      } else {
        row.isEdit = false
      }
    }
  }

  eliminarJuego(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.eliminarJuego(id).subscribe(() => {
          this.obtenerJuegos()
        })
      }
    })
  }
}
