import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { BibliotecaService } from 'app/servicios/biblioteca.service';

@Component({
  selector: 'app-biblioteca',
  templateUrl: './biblioteca.component.html',
  styleUrls: ['./biblioteca.component.scss']
})
export class BibliotecaComponent implements OnInit {


  displayedColumns: string[] = ['idBiblioteca', 'titulo','clasificacion','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  public biblioteca: any = [];

  constructor(private serviciobiblioteca: BibliotecaService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerBiblioteca()
  }

  obtenerBiblioteca = () =>{
    this.serviciobiblioteca.getBibliotecas().subscribe(response =>{
      this.biblioteca = response;
      this.dataSource = new MatTableDataSource(this.biblioteca);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    
  }

  eliminarBiblioteca = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciobiblioteca.eliminarBiblioteca(id).subscribe(response =>{
          this.obtenerBiblioteca()
        })
      }
  
    })
  }
}
