import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoGruposVulnerablesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalgoGruposVulnerables(){
    return this.http.get(`${this.API_URI}/catalogo-grupos`)
  }

  getCatalogoGrupoVulnerable(id:string){
    return this.http.get(`${this.API_URI}/catalogo-grupos/${id}`);
  }

  actualizarCatalogoGrupoVulnerable(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-grupos/${id}`, datos);
  }
  
  crearCatalogoGrupoVulnerable(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-grupos`, datos);
  }

  eliminarCatalogoGrupoVulnerable(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-grupos/${id}`);
  }

}
