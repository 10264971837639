import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ArmonizacionTable } from 'app/model/armonizacion';
import { ArmonizacionService } from 'app/servicios/armonizacion.service';
import { CatalogoDetalleService } from 'app/servicios/catalogo-detalle-armonizacion.service';
import { CatalogoEncabezadoService } from 'app/servicios/catalogo-encabezado-armonizacion.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { FechasService } from 'app/servicios/fechas.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');
import { Observable } from 'rxjs';

@Component({
  selector: 'app-armonizacion-detalle',
  templateUrl: './armonizacion-detalle.component.html',
  styleUrls: ['./armonizacion-detalle.component.scss']
})
export class ArmonizacionDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idArmonizacion: string = this.obtenerId().idArmonizacion;

  //VARIABLES
  edit: boolean = false;
  array: any = [];
  detalle: any = [];
  armonizacion: any = [];
  anios: any = [];
  PDF_HOST = environment.API_PDF;
  documento: any = ''
  documentoPDF: any = ''
  tituloArmonizacion: any = '';
  anio: any = moment().format("YYYY");
  idAnio: any = 6;
  editandoDetalle: boolean = false;
  encabezadoID: any = 0;
  armonizacionID: any = 0;



  //DECLARAMOS TABLA DE ANGULAR MATERIAL
  displayedColumnsArmonizacion: string[] = Object.keys(ArmonizacionTable);
  dataSchemaArmonizacion = ArmonizacionTable;
  dataSourceArmonizacion = [...this.detalle];
  @ViewChild('tableA', { static: true }) tableA: MatTable<any>;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ArmonizacionForm = new FormGroup({
    idAnio: new FormControl(1, Validators.required),
    idEncabezadoArmonizacion: new FormControl(0, Validators.required),
    idDetalleArmonizacion: new FormControl(0, Validators.required),
    rutaDocumento: new FormControl('', Validators.required),
    estado: new FormControl(1)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoDetalleService,
    private catalogoserviceencabezado: CatalogoEncabezadoService,
    private servicioarmonizacion: ArmonizacionService,
    private serviciofechas: FechasService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    this.obtenerEncabezados(this.idArmonizacion)
    this.obtenerFechas()
    this.obtenerArmonizaciones(this.idArmonizacion, this.idAnio)
    if (this.idArmonizacion) {
      this.servicioarmonizacion.getArmonizacion(this.idArmonizacion, this.idAnio).subscribe(response => {
        this.encabezadoID = response[0].idEncabezadoArmonizacion;
        this.obtenerDetalle(this.encabezadoID)
      });
    }
  }

  obtenerEncabezados = (id) => {
    this.catalogoserviceencabezado.getCatalogoEncabezado(id).subscribe(response => {
      this.tituloArmonizacion = response[0].nombre;
    });
    // this.catalogoserviceencabezado.getCatalogoEncabezados().subscribe(response=>{
    //   this.array = response;
    // })
  }

  obtenerDetalle = (id) => {
    this.catalogoservice.getCatalogoDetallesArmonizacion(id).subscribe(response => {
      this.detalle = response;
    });
  }

  obtenerArmonizaciones = (id, idAnio) => {

    let array: any = []
    this.dataSourceArmonizacion.length = 0;
    this.servicioarmonizacion.getArmonizacion(id, idAnio).subscribe(response => {
      array = response;
      array.forEach(detalle => {
        this.dataSourceArmonizacion.push(detalle)
        this.tableA.renderRows()
      });
    });


    // this.servicioarmonizacion.getArmonizacion(id).subscribe(response =>{
    //   this.armonizacion = response;
    // });
  }

  obtenerFechas = () => {
    this.serviciofechas.getFechas().subscribe(response => {
      this.anios = response;
    })
  }

  select = (event: any) => {
    this.obtenerDetalle(event)
  }

  cambios = (event) => {
    this.anios.forEach(element => {
      if (element.fecha == this.anio) {
        this.idAnio = element.idAnio;
      }
    });
    this.obtenerArmonizaciones(this.idArmonizacion, this.idAnio)
  }

  obtenerDetalleArmonizacion = (element) => {
    this.editandoDetalle = true;
    this.armonizacionID = element.idArmonizacion;
    this.servicioarmonizacion.getArmonizacionDetalle(element.idArmonizacion).subscribe(response => {
      this.ArmonizacionForm.patchValue(response[0])
      this.documentoPDF = this.ArmonizacionForm.get('rutaDocumento').value;
    })
  }


  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ArmonizacionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let pdf = this.ArmonizacionForm.get('rutaDocumento').value;
    if (this.ArmonizacionForm.get('rutaDocumento').value) {
      this.ArmonizacionForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.servicioarmonizacion.crearArmonizacion(this.ArmonizacionForm.value).subscribe(response => {
      if (this.ArmonizacionForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.ArmonizacionForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/armonizacion')
    });

  }

  actualizarDatos = () => {

    // let pdf = this.ArmonizacionForm.get('rutaDocumento').value;
    // if (this.ArmonizacionForm.get('rutaDocumento').value) {
    //   this.ArmonizacionForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    // }

    if (this.armonizacion.length > 0) {
      this.armonizacion.forEach(detalle => {
        delete detalle.isEdit;
        detalle.idEncabezadoArmonizacion = this.idArmonizacion;
        this.servicioarmonizacion.crearArmonizacion(detalle).subscribe(response => {
        });
      });
    }

    // this.servicioarmonizacion.actualizarArmonizacion(this.idArmonizacion, this.ArmonizacionForm.value).subscribe(response => {

    // if (this.ArmonizacionForm.get('rutaDocumento').value) {
    //   this.guardarPDF(pdf)
    // }

    this.ArmonizacionForm.reset()
    this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
    this.router.navigateByUrl('/armonizacion')
    // });
  }

  guardarPDF = (pdf: any) => {

    this.ArmonizacionForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.ArmonizacionForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  editarArmonizacion() {

    if(this.documentoPDF){
      this.servicioarmonizacion.actualizarArmonizacion(this.armonizacionID, this.ArmonizacionForm.value).subscribe(() => {
        this.openSnackBar("¡REGISTRO ACTUALIZADO!")
        this.obtenerArmonizaciones(this.idArmonizacion, this.idAnio)
      });
    }else{
      let pdf = this.ArmonizacionForm.get('rutaDocumento').value;
      if (this.ArmonizacionForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
        this.ArmonizacionForm.controls['rutaDocumento'].setValue(pdf._fileNames)
        this.servicioarmonizacion.actualizarArmonizacion(this.armonizacionID, this.ArmonizacionForm.value).subscribe(() => {
          this.openSnackBar("¡REGISTRO ACTUALIZADO!")
          this.obtenerArmonizaciones(this.idArmonizacion, this.idAnio)
        });
      }
    }

    this.editandoDetalle = false;
    this.documentoPDF = '';
    this.ArmonizacionForm.reset()

  }

  eliminarArmonizacion(id) {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioarmonizacion.eliminarArmonizacion(id).subscribe(() => {
          this.obtenerArmonizaciones(this.idArmonizacion, this.idAnio)
        })
      }
    })
  }

  guardarArmonizacion = () => {

    let pdf = this.ArmonizacionForm.get('rutaDocumento').value;
    if (this.ArmonizacionForm.get('rutaDocumento').value) {
      this.guardarPDF(pdf);
      this.ArmonizacionForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.dataSourceArmonizacion.push(this.ArmonizacionForm.value)
    this.armonizacion.push(this.ArmonizacionForm.value)
    this.tableA.renderRows()
    this.ArmonizacionForm.reset()

  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.armonizacion, event.previousIndex, event.currentIndex);

    this.dataSourceArmonizacion.length = 0;
    this.armonizacion.forEach(detalle => {
      this.dataSourceArmonizacion.push(detalle)
    });
    this.tableA.renderRows()

    this.armonizacion.reverse()
    this.armonizacion.forEach(detalle => {
      detalle.orden = i;

      if (detalle.estado == 'ACTIVO') {
        detalle.estado = 1;
      } else {
        detalle.estado = 0;
      }

      this.servicioarmonizacion.actualizarArmonizacion(detalle.idArmonizacion, detalle).subscribe(response => {
      });

      if (detalle.estado == 1) {
        detalle.estado = 'ACTIVO';
      } else {
        detalle.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.armonizacion.reverse()

  }

  eliminarDocumento = () => {

    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioarmonizacion.actualizarEstadoDocumento(this.armonizacionID, this.ArmonizacionForm.value).subscribe(response => {
          this.serviciodocumento.eliminarPDF(this.documentoPDF).subscribe(response => {

          });
          this.documentoPDF = '';
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }
}
