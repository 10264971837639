import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common'
import { LoginService } from './login.service';
import { AuthorizationHeaderService } from './authorization-header.service';


declare interface Componente {
  label: string,
  link: string,
  icon: string,
  items?: []
}


@Injectable({
  providedIn: 'root'
})


export class DataService {
  private history: string[] = []

  API_URI = environment.API_URL;

  constructor(private http: HttpClient,
    private router: Router,
    private location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl("/")
    }
  }

  getMenu(idGrupo: string) {
    return this.http.get(`${this.API_URI}/menu/porUsuario/${idGrupo}`);
  }

  getMenuModulos(id: string) {
    return this.http.get(`${this.API_URI}/menu/modulos/${id}`);
  }



}
