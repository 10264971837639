import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoEncabezadoService } from 'app/servicios/catalogo-encabezado-armonizacion.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-armonizacion-encabezado-editar',
  templateUrl: './armonizacion-encabezado-editar.component.html',
  styleUrls: ['./armonizacion-encabezado-editar.component.scss']
})
export class ArmonizacionEncabezadoEditarComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idEncabezadoArmonizacion: string = this.obtenerId().idEncabezadoArmonizacion;

  //VARIABLES
  edit: boolean = false;
  array: any = [];
  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  EncabezadoForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    estado: new FormControl(1),
    orden: new FormControl(0)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoEncabezadoService) { }

  ngOnInit(): void {
    if (this.idEncabezadoArmonizacion) {
      this.catalogoservice.getCatalogoEncabezado(this.idEncabezadoArmonizacion).subscribe(response => {
        this.EncabezadoForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.EncabezadoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    this.catalogoservice.getCatalogoEncabezados().subscribe(response => {
      if (response) {
        this.array = response;
        this.EncabezadoForm.controls['orden'].setValue(this.array.length + 1)
        this.catalogoservice.crearCatalogoEncabezado(this.EncabezadoForm.value).subscribe(response => {
          if (response) {
          }
          this.EncabezadoForm.reset()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/catalogo-encabezado')
        });

      }
    });
  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoEncabezado(this.idEncabezadoArmonizacion, this.EncabezadoForm.value).subscribe(response => {
      this.EncabezadoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-encabezado')
    });
  }

}
