import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class MicroSitiosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getMicrositios(){
    return this.http.get(`${this.API_URI}/micrositios`)
  }

  getMicroSitio(id:string){
    return this.http.get(`${this.API_URI}/micrositios/${id}`);
  }

  actualizarMicroSitio(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/micrositios/${id}`, datos);
  }
  
  crearMicroSitio(datos: any) {
    return this.http.post(`${this.API_URI}/micrositios`, datos);
  }

  eliminarMicroSitio(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/micrositios/${id}`);
  }

}
