import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { AcuerdoService } from 'app/servicios/acuerdos.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';

@Component({
  selector: 'app-acuerdo-no-respons-detalle',
  templateUrl: './acuerdo-no-respons-detalle.component.html',
  styleUrls: ['./acuerdo-no-respons-detalle.component.scss']
})

export class AcuerdoNoResponsDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idAcuerdo: string = this.obtenerId().idAcuerdo;

  //VARIABLES
  edit: boolean = false;
  categorias: any = [];
  acuerdo: any = '';
  PDF_HOST = environment.API_PDF;


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  AcuerdoForm = new FormGroup({
    numero: new FormControl('', Validators.required),
    fecha: new FormControl(new Date, Validators.required),
    asunto: new FormControl('', Validators.required),
    autoridad: new FormControl(''),
    rutaDocumento: new FormControl(''),
    estado: new FormControl(1)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioacuerdo: AcuerdoService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if(this.idAcuerdo){
      this.servicioacuerdo.getAcuerdo(this.idAcuerdo).subscribe(response =>{
        this.AcuerdoForm.patchValue(response[0])
        this.acuerdo = response[0].rutaDocumento;
      })
    }
    
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.AcuerdoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let pdf = this.AcuerdoForm.get('rutaDocumento').value;
    if (this.AcuerdoForm.get('rutaDocumento').value) {
      this.AcuerdoForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.AcuerdoForm.controls['fecha'].setValue(moment(this.AcuerdoForm.get('fecha').value).format('YYYY-MM-DD'));   
    this.servicioacuerdo.crearAcuerdo(this.AcuerdoForm.value).subscribe(response => {
      if (this.AcuerdoForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.AcuerdoForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/acuerdos')
    });
  }

  actualizarDatos = () => {
    let pdf = this.AcuerdoForm.get('rutaDocumento').value;

    if (this.AcuerdoForm.get('rutaDocumento').value) {
      this.AcuerdoForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.AcuerdoForm.controls['fecha'].setValue(moment(this.AcuerdoForm.get('fecha').value).format('YYYY-MM-DD'));   
    this.servicioacuerdo.actualizarAcuerdo(this.idAcuerdo, this.AcuerdoForm.value).subscribe(response => {
      if (this.AcuerdoForm.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.AcuerdoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/acuerdos')
    });
  }

  guardarPDF = (pdf:any) =>{
    this.AcuerdoForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.AcuerdoForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  eliminarDocumento = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.servicioacuerdo.actualizarEstadoDocumento(this.idAcuerdo, this.AcuerdoForm.value).subscribe(response=>{
      this.serviciodocumento.eliminarPDF(this.acuerdo).subscribe(response=>{

      });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
    })
  }

}
