import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CentroInvestigacionService } from 'app/servicios/centro-investigacion.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-centro-investigacion-detalle',
  templateUrl: './centro-investigacion-detalle.component.html',
  styleUrls: ['./centro-investigacion-detalle.component.scss']
})
export class CentroInvestigacionDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idCentroInv: string = this.obtenerId().idCentroInv;

  //VARIABLES
  PDF_HOST = environment.API_PDF;
  edit: boolean = false;
  categorias: any = []
  centro_investigacion: any = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  InvestigacionForms = new FormGroup({
    descripcion: new FormControl('', Validators.required),
    estado: new FormControl(1),
    rutaDocumento: new FormControl('')
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicecentroinvestigacion: CentroInvestigacionService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idCentroInv) {
      this.servicecentroinvestigacion.getInvestigacion(this.idCentroInv).subscribe(response => {
        this.InvestigacionForms.patchValue(response[0]);
        this.centro_investigacion = response[0].rutaDocumento;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.InvestigacionForms.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let pdf = this.InvestigacionForms.get('rutaDocumento').value;
    if (this.InvestigacionForms.get('rutaDocumento').value) {
      this.InvestigacionForms.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.servicecentroinvestigacion.crearInvestigacion(this.InvestigacionForms.value).subscribe(response => {
      if (this.InvestigacionForms.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.InvestigacionForms.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/centro-investigacion')
    });
  }

  actualizarDatos = () => {

    let pdf = this.InvestigacionForms.get('rutaDocumento').value;

    if (this.InvestigacionForms.get('rutaDocumento').value) {
      this.InvestigacionForms.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.servicecentroinvestigacion.actualizarInvestigacion(this.idCentroInv, this.InvestigacionForms.value).subscribe(response => {
      if (this.InvestigacionForms.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.InvestigacionForms.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/centro-investigacion')
    });
  }

  guardarPDF = (pdf: any) => {
    this.InvestigacionForms.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.InvestigacionForms.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  eliminarDocumento = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.servicecentroinvestigacion.actualizarEstadoDocumento(this.idCentroInv, this.InvestigacionForms.value).subscribe(response=>{
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
    })
  }


}
