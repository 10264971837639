import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DescripcionDerechosService } from 'app/servicios/descripcion-derechos.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-descripcion-derechos-detalle',
  templateUrl: './descripcion-derechos-detalle.component.html',
  styleUrls: ['./descripcion-derechos-detalle.component.scss']
})
export class DescripcionDerechosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idDescripcion: string = this.obtenerId().idDescripcion;

  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  PDF_HOST = environment.API_PDF;
  imagenes: any = [];



  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  DescripcionForm = new FormGroup({

    descripcion: new FormControl('', Validators.required),
    estado: new FormControl(1, Validators.required)

  });

  DocumentoForm = new FormGroup({

    rutaImagen: new FormControl('', Validators.required),
    rutaDocumento: new FormControl('', Validators.required),
    estado: new FormControl(1, Validators.required),
    idDescripcion: new FormControl(0)

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioDatos: DescripcionDerechosService,
    private serviciodocumento: DocumentService) { }

  ngOnInit(): void {
    if (this.idDescripcion) {
      this.servicioDatos.getDescripcionDerecho(this.idDescripcion).subscribe(response => {
        this.DescripcionForm.patchValue(response[0])
        this.obtenerDocumentos()
      });
    }
  }

  obtenerDocumentos = () => {
    this.imagenes.length = 0;
    this.servicioDatos.getDocumentos(this.idDescripcion).subscribe(response => {
      this.imagenes = response;
    })
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.DescripcionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let result: any = {};
    let id;

    this.servicioDatos.crearDescripcionDerecho(this.DescripcionForm.value).subscribe(response => {
      result = response;
      if (this.DocumentoForm.get('rutaImagen').value && this.DocumentoForm.get('rutaDomento').value) {
        id = result.reps[0].id;
        this.guardarDocumento(id)
      }

      this.DescripcionForm.markAsPristine()
      this.DescripcionForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/descripcion-derechos')
    });

  }

  actualizarDatos = () => {
    this.servicioDatos.actualizarDescripcionDerecho(this.idDescripcion, this.DescripcionForm.value).subscribe(response => {

      if (this.DocumentoForm.get('rutaImagen').value || this.DocumentoForm.get('rutaDomento').value) {
        this.guardarDocumento(this.idDescripcion)
      }

      this.DescripcionForm.markAsPristine()
      this.DescripcionForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/descripcion-derechos')
    });
  }

  eliminarImagen = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.eliminarDocumento(id).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.obtenerDocumentos()
        })
      }
    })
  }


  guardarDocumento = (id) => {
    if (this.idDescripcion) {
      this.DocumentoForm.controls['idDescripcion'].setValue(this.idDescripcion)
    } else {
      this.DocumentoForm.controls['idDescripcion'].setValue(id)
    }


    let imagen = this.DocumentoForm.get('rutaImagen').value;
    let pdf = this.DocumentoForm.get('rutaDocumento').value;

    if (this.DocumentoForm.get('rutaDocumento').value) {
      this.DocumentoForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    if (this.DocumentoForm.get('rutaImagen').value) {
      this.DocumentoForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioDatos.crearDocumentos(this.DocumentoForm.value).subscribe(response => {
      this.guardarImagenPHP(imagen);
      this.guardarPDF(pdf);
    })

    if (this.idDescripcion) {
      this.obtenerDocumentos()
    }
  }



  guardarPDF = (pdf: any) => {
    this.DocumentoForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.DocumentoForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.DocumentoForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.DocumentoForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });
  }

}
