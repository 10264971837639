import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { ConvocatoriaService } from 'app/servicios/convocatoria.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-convocatoria-detalle',
  templateUrl: './convocatoria-detalle.component.html',
  styleUrls: ['./convocatoria-detalle.component.scss']
})
export class ConvocatoriaDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idConvocatoria: string = this.obtenerId().idConvocatoria;

  //VARIABLES
  edit: boolean = false;
  categorias: any = []
  PDF_HOST = environment.API_PDF;
  IMAGEN_HOST = environment.API_IMAGEN;
  documento: any = '';
  imagen: any = '';
  ficha: any = '';
  disableds: boolean = true;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ConvocatoriaForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    rutaImagen: new FormControl(''),
    rutaBases: new FormControl(''),
    url: new FormControl(''),
    rutaFichaRegistro: new FormControl(''),
    slider: new FormControl(0),
    estado: new FormControl(1)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviceconvocatoria: ConvocatoriaService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idConvocatoria) {
      this.serviceconvocatoria.getConvocatoria(this.idConvocatoria).subscribe(response => {
        this.ConvocatoriaForm.patchValue(response[0])
        this.imagen = response[0].rutaImagen;
        this.documento = response[0].rutaBases;
        this.ficha = response[0].rutaFichaRegistro;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ConvocatoriaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let imagen = this.ConvocatoriaForm.get('rutaImagen').value;
    let pdf = this.ConvocatoriaForm.get('rutaBases').value;
    let archivo = this.ConvocatoriaForm.get('rutaFichaRegistro').value

    if (this.ConvocatoriaForm.get('rutaImagen').value) {
      this.ConvocatoriaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    if (this.ConvocatoriaForm.get('rutaBases').value) {
      this.ConvocatoriaForm.controls['rutaBases'].setValue(pdf._fileNames)
    }

    if (this.ConvocatoriaForm.get('rutaFichaRegistro').value) {
      this.ConvocatoriaForm.controls['rutaFichaRegistro'].setValue(archivo._fileNames)
    }

    this.serviceconvocatoria.crearConvocatoria(this.ConvocatoriaForm.value).subscribe(response => {

      if (this.ConvocatoriaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }

      if (this.ConvocatoriaForm.get('rutaBases').value) {
        this.guardarPdfPHP(pdf)
      }

      if (this.ConvocatoriaForm.get('rutaFichaRegistro').value) {
        this.guardarArchivoPHP(archivo)
      }

      this.ConvocatoriaForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/convocatorias')
    });
  }

  actualizarDatos = () => {

    let imagen = this.ConvocatoriaForm.get('rutaImagen').value;
    let pdf = this.ConvocatoriaForm.get('rutaBases').value;
    let archivo = this.ConvocatoriaForm.get('rutaFichaRegistro').value

    if (this.ConvocatoriaForm.get('rutaImagen').value) {
      this.ConvocatoriaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    if (this.ConvocatoriaForm.get('rutaBases').value) {
      this.ConvocatoriaForm.controls['rutaBases'].setValue(pdf._fileNames)
    }

    if (this.ConvocatoriaForm.get('rutaFichaRegistro').value) {
      this.ConvocatoriaForm.controls['rutaFichaRegistro'].setValue(archivo._fileNames)
    }

    this.serviceconvocatoria.actualizarConvocatoria(this.idConvocatoria, this.ConvocatoriaForm.value).subscribe(response => {

      if (this.ConvocatoriaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }

      if (this.ConvocatoriaForm.get('rutaBases').value) {
        this.guardarPdfPHP(pdf)
      }

      if (this.ConvocatoriaForm.get('rutaFichaRegistro').value) {
        this.guardarArchivoPHP(archivo)
      }
      this.ConvocatoriaForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/convocatorias')
    });

  }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.ConvocatoriaForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ConvocatoriaForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  guardarPdfPHP = (pdf: any) => {
    this.ConvocatoriaForm.controls['rutaBases'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.ConvocatoriaForm.get('rutaBases').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  guardarArchivoPHP = (pdf: any) => {
    this.ConvocatoriaForm.controls['rutaFichaRegistro'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.ConvocatoriaForm.get('rutaFichaRegistro').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  //ELIMNAR IMAGEN Y DOCUMENTO

  eliminarImagen = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceconvocatoria.actualizarEstadoImagen(this.idConvocatoria, this.ConvocatoriaForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }

    })
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceconvocatoria.actualizarEstadoDocumento(this.idConvocatoria, this.ConvocatoriaForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }

  eliminarFicha = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceconvocatoria.actualizarEstadoDocumentoFicha(this.idConvocatoria, this.ConvocatoriaForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }

}
