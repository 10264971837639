import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { AvisoPrivacidadService } from 'app/servicios/avisos-privacidad.service';

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss']
})
export class AvisoPrivacidadComponent implements OnInit {

  displayedColumns: string[] = ['idAviso', 'nombre','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public centro_investigacion: any = [];

  constructor(private serviceavisoprivacidad: AvisoPrivacidadService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerInvestigaciones()
  }

  obtenerInvestigaciones = () =>{
    this.serviceavisoprivacidad.getAvisosPrivacidad().subscribe(response =>{
      this.centro_investigacion = response;
      this.dataSource = new MatTableDataSource(this.centro_investigacion);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarAviso = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceavisoprivacidad.eliminarAvisoPrivacidad(id).subscribe(response =>{
          this.obtenerInvestigaciones()
        })
      }
  
    })
  }
}
