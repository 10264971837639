import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class AudiosNiniosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getAudios(){
    return this.http.get(`${this.API_URI}/audios-ninios`)
  }

  getAudio(id:number){
    return this.http.get(`${this.API_URI}/audios-ninios/${id}`);
  }

  actualizarAudio(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/audios-ninios/${id}`, datos);
  }
  
  crearAudio(datos: any) {
    return this.http.post(`${this.API_URI}/audios-ninios`, datos);
  }

  eliminarAudio(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/audios-ninios/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/audios-ninios/estadoDocumento/${id}`, datos);
  }


}
