import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'app/servicios/data.service';
import { QuejasService } from 'app/servicios/quejas.service';

@Component({
  selector: 'app-quejas-detalle',
  templateUrl: './quejas-detalle.component.html',
  styleUrls: ['./quejas-detalle.component.scss']
})
export class QuejasDetalleComponent implements OnInit {
  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID QUE EL USUARIO SELECCIONO 
  idQueja: string = this.obtenerId().idQueja;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  QuejasForm = new FormGroup({
    nombre: new FormControl(''),
    genero: new FormControl(''),
    edad: new FormControl(''),
    escolaridad: new FormControl(''),
    ocupacion: new FormControl(''),
    domicilio: new FormControl(''),
    colonia: new FormControl(''),
    municipio: new FormControl(''),
    estado: new FormControl(''),
    cp: new FormControl(''),
    telefono: new FormControl(''),
    nacionalidad: new FormControl(''),
    email: new FormControl(''),
    descripcion: new FormControl(''),
    fecha: new FormControl('')
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicio_quejas: QuejasService,
  ) { }

  ngOnInit(): void {

    if (this.idQueja) {
      this.servicio_quejas.getQueja(this.idQueja).subscribe(response => {
        this.QuejasForm.patchValue(response[0])
      })
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  salir = () =>{
    this.QuejasForm.reset()
    this.router.navigateByUrl('/quejas')
  }


}
