import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class SubModulosGruposService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getSubModulos(){
    return this.http.get(`${this.API_URI}/submodulo-grupos`)
  }

  getSubModulo(id:string, idGrupo:string){
    return this.http.get(`${this.API_URI}/submodulo-grupos/${id}/${idGrupo}`);
  }

  actualizarSubModulo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/submodulo-grupos/${id}`, datos);
  }
  
  crearSubModulo(datos: any) {
    return this.http.post(`${this.API_URI}/submodulo-grupos`, datos);
  }

  eliminarSubModulo(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/submodulo-grupos/${id}`);
  }

  getCatalogoGrupos(idCatalogo: string){
    return this.http.get(`${this.API_URI}/submodulo-grupos/grupos/anios/${idCatalogo}`)
  }

  getCatalogoGruposRestantes(idCatalogo: string){
    return this.http.get(`${this.API_URI}/submodulo-grupos/grupos/restantes/${idCatalogo}`)
  }
}
