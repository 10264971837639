import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DocumentService } from 'app/servicios/documentos.service';
import { TransparenciaService } from 'app/servicios/transparencia.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-transparencia',
  templateUrl: './transparencia.component.html',
  styleUrls: ['./transparencia.component.scss']
})
export class TransparenciaComponent implements OnInit {

  IMAGEN_HOST = environment.API_IMAGEN;

  TransparenciaForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required]),
    url: new FormControl(''),
    rutaImagen: new FormControl('')
  });

  imagen: any = '';


  constructor(private servicioDatos: TransparenciaService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private serviciodocumento: DocumentService,
              ) { }

  ngOnInit(): void {
    this.obtenerAntecendentes()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.TransparenciaForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerAntecendentes = () => {
    this.servicioDatos.getTransparencia(1).subscribe(response => {
      this.TransparenciaForm.patchValue(response[0]);
      this.imagen = response[0].rutaImagen;
    })
  }


  guardarDatos = () => {

    let imagen = this.TransparenciaForm.get('rutaImagen').value;

    
    if (this.TransparenciaForm.get('rutaImagen').value) {
      this.TransparenciaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    this.servicioDatos.actualizarTransparencia(1, this.TransparenciaForm.value).subscribe(response => {

      if (this.TransparenciaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
        this.obtenerAntecendentes()
      }

      this.TransparenciaForm.markAsPristine()
      this.TransparenciaForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');

    })

  }

    //GUARDAR IMAGEN EN PROYECTO
    guardarImagenPHP = (imagen: any) => {
      imagen._files.forEach(element => {
        this.TransparenciaForm.controls['rutaImagen'].setValue(element);
        const imgData = new FormData();
        imgData.append('imagen', this.TransparenciaForm.get('rutaImagen').value);
        this.serviciodocumento.guardarImg(imgData).subscribe(response => {
        });
      });
  
    }

  eliminarImagen = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioDatos.actualizarEstadoImagen(1, this.TransparenciaForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          if(response){
            this.obtenerAntecendentes()
          }
        });
      }

    })
  }

}
