import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class SubModulosSCIService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getSubModulos(){
    return this.http.get(`${this.API_URI}/submodulo-sci`)
  }

  getSubModulo(id:string){
    return this.http.get(`${this.API_URI}/submodulo-sci/${id}`);
  }

  actualizarSubModulo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/submodulo-sci/${id}`, datos);
  }
  
  crearSubModulo(datos: any) {
    return this.http.post(`${this.API_URI}/submodulo-sci`, datos);
  }

  eliminarSubModulo(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/submodulo-sci/${id}`);
  }

}
