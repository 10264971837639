import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { SedesService } from 'app/servicios/sedes.service';

@Component({
  selector: 'app-sedes',
  templateUrl: './sedes.component.html',
  styleUrls: ['./sedes.component.css']
})
export class SedesComponent implements OnInit {

  displayedColumns: string[] = ['idSede', 'nombreSede', 'correoSede', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  public sedes: any = [];
  constructor(private sedesservicio: SedesService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.obtenerSedes()
  }

  obtenerSedes = () =>{
    this.sedesservicio.getSedes().subscribe(response =>{
      this.sedes = response;
      this.dataSource = new MatTableDataSource(this.sedes);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarSede = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.sedesservicio.eliminarSede(id).subscribe(response =>{
          this.obtenerSedes()
         this.sedesservicio.eliminarTodoMunicipio(id).subscribe(response=>{

         })

         this.sedesservicio.eliminarTodoPersonal(id).subscribe(response=>{

         })

         this.sedesservicio.eliminarTodoServicio(id).subscribe(response=>{

         })
         
         this.sedesservicio.eliminarTodoTelefono(id).subscribe(response=>{

         })
        })
      }
  
    })
  }

   //AVISO TIPO SNACKBAR ANGULAR MATERIAL
   openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.sedes, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.sedes);
    this.dataSource.sort = this.sort;
    this.sedes.reverse()
    this.sedes.forEach(sede => {
      sede.orden = i;

      // if (sede.estado == 'ACTIVO') {
      //   sede.estado = 1;
      // } else {
      //   sede.estado = 0;
      // }

      this.sedesservicio.actualizarSede(sede.idSede, sede).subscribe(response => {
      });


      // if (sede.estado == 1) {
      //   sede.estado = 'ACTIVO';
      // } else {
      //   sede.estado = 'INACTIVO';
      // }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.sedes.reverse()
    // this.obtenersedes()
  }


}
