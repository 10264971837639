import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { QuejasService } from 'app/servicios/quejas.service';

@Component({
  selector: 'app-quejas',
  templateUrl: './quejas.component.html',
  styleUrls: ['./quejas.component.scss']
})
export class QuejasComponent implements OnInit {


  displayedColumns: string[] = ['idQueja', 'fecha','nombre','email','telefono', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public centro_investigacion: any = [];

  constructor(private servicio_quejas: QuejasService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerQuejas()
  }

  obtenerQuejas = () =>{
    this.servicio_quejas.getQuejas().subscribe(response =>{
      this.centro_investigacion = response;
      this.dataSource = new MatTableDataSource(this.centro_investigacion);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
