import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoSCIService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalogosSCI(){
    return this.http.get(`${this.API_URI}/catalogo-sci`)
  }

  getCatalogoSCI(id:string){
    return this.http.get(`${this.API_URI}/catalogo-sci/${id}`);
  }

  actualizarCatalogoSCI(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-sci/${id}`, datos);
  }
  
  crearCatalogoSCI(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-sci`, datos);
  }

  eliminarCatalogoSCI(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-sci/${id}`);
  }

}
