import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CentroInvestigacionService } from 'app/servicios/centro-investigacion.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-centro-investigacion',
  templateUrl: './centro-investigacion.component.html',
  styleUrls: ['./centro-investigacion.component.scss']
})
export class CentroInvestigacionComponent implements OnInit {

  CentroForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required])
  });

  constructor(private servicioDatos: CentroInvestigacionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.CentroForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerDatos = () => {
    this.servicioDatos.getInvestigacion(1).subscribe(response => {
      // this.ckeditorContent = response[0].CentroForm;
      this.CentroForm.patchValue(response[0]);
    })
  }


  guardarDatos = () => {
    this.servicioDatos.actualizarInvestigacion(1, this.CentroForm.value).subscribe(response => {
      this.CentroForm.markAsPristine()
      this.CentroForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');
    })

  }

  // displayedColumns: string[] = ['idCentroInv', 'descripcion','estado', 'ver', 'delete'];
  // dataSource: MatTableDataSource<any>;
  // @ViewChild(MatSort) sort: MatSort;

  // public centro_investigacion: any = [];

  // constructor(private serviceinvestigacion: CentroInvestigacionService,
  //             private dialog: MatDialog) { }

  // ngOnInit(): void {
  //   this.obtenerInvestigaciones()
  // }

  // obtenerInvestigaciones = () =>{
  //   this.serviceinvestigacion.getInvestigaciones().subscribe(response =>{
  //     this.centro_investigacion = response;
  //     this.dataSource = new MatTableDataSource(this.centro_investigacion);
  //     this.dataSource.sort = this.sort;
  //   });
  // }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  // eliminarInvestigacion = (id) => {
  //   this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
  //     if (confirm) {
  //       this.serviceinvestigacion.eliminarInvestigacion(id).subscribe(response =>{
  //         this.obtenerInvestigaciones()
  //       })
  //     }
  
  //   })
  // }


}
