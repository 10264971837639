export const AudioTable = {
    fecha:"date",
    titulo:"text",
    descripcion:"text",
    rutaAudio:"text",
    estado:"select",
    isEdit: "isEdit"
}

export const AudioNiniosTable = {
    titulo:"text",
    audio:"text",
    estado:"select",
    isEdit: "isEdit"
}