import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoOrganismosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalgoOrganismos(){
    return this.http.get(`${this.API_URI}/catalogo-organismo`)
  }

  getCatalogoOrganismo(id:string){
    return this.http.get(`${this.API_URI}/catalogo-organismo/${id}`);
  }

  actualizarCatalogoOrganismo(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-organismo/${id}`, datos);
  }
  
  crearCatalogoOrganismo(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-organismo`, datos);
  }

  eliminarCatalogoOrganismo(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-organismo/${id}`);
  }

}
