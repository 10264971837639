import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';

@Injectable({
  providedIn: 'root'
})

export class QuejasService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getQuejas(){
    return this.http.get(`${this.API_URI}/quejas`)
  }

  getQueja(id:string){
    return this.http.get(`${this.API_URI}/quejas/${id}`);
  }

}
