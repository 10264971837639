import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoNormatividadService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getNormatividadAll(){
    return this.http.get(`${this.API_URI}/catalogo-normatividad`)
  }

  getNormatividad(id:string){
    return this.http.get(`${this.API_URI}/catalogo-normatividad/${id}`);
  }

  actualizarNormatividad(id: string | number, normatividad: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-normatividad/${id}`, normatividad);
  }
  
  crearNormatividad(normatividad: any) {
    return this.http.post(`${this.API_URI}/catalogo-normatividad`, normatividad);
  }

  eliminarNormatividad(idNormatividad: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-normatividad/${idNormatividad}`);
  }

}
