import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { OrganismosService } from 'app/servicios/organismos.service';

@Component({
  selector: 'app-organismos-nacionales-internacionales',
  templateUrl: './organismos-nacionales-internacionales.component.html',
  styleUrls: ['./organismos-nacionales-internacionales.component.scss']
})
export class OrganismosNacionalesInternacionalesComponent implements OnInit {

  displayedColumns: string[] = ['idOrganismo', 'descripcion', 'categoria','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public organismos: any = [];

  constructor(private serviceorganismo: OrganismosService , private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerOrganismos()
  }

  obtenerOrganismos = () =>{
    this.serviceorganismo.getOrganismos().subscribe(response =>{
      this.organismos = response;
      this.dataSource = new MatTableDataSource(this.organismos)
      this.dataSource.sort = this.sort;
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarOrganismo = (id) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
      this.serviceorganismo.eliminarOrganismo(id).subscribe(response=>{
        this.obtenerOrganismos()
      })
      }
  
    })
  }


}
