import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoGruposVulnerablesService } from 'app/servicios/catalogo-grupos-vulnerables.service';
import { CatalogoSubModuloGruposService } from 'app/servicios/catalogo-submodulo-grupos.service';
import { DataService } from 'app/servicios/data.service';
import { SubModulosGruposService } from 'app/servicios/submodulos-grupos-v.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-submodulos-grupos-detalle',
  templateUrl: './submodulos-grupos-detalle.component.html',
  styleUrls: ['./submodulos-grupos-detalle.component.scss']
})
export class SubmodulosGruposDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idContenido: string = this.obtenerId().idCatalogo;

  //VARIABLES
  edit: boolean = false;
  idGrupo: any = 0;
  grupos: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  SubModuloForm = new FormGroup({

    contenido: new FormControl('', Validators.required),
    idCatalogo: new FormControl(1, Validators.required),
    idGrupo: new FormControl(0, Validators.required)

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: SubModulosGruposService,
    private catalogo: CatalogoSubModuloGruposService,
    private catalogoGrupos: CatalogoGruposVulnerablesService) { }

  ngOnInit(): void {
    this.obtenerDatosGrupos()
    this.obtenerGrupos(1)

    if (this.idContenido) {
      this.obtenerDatos()
    }
  }


  obtenerDatos = () => {
    this.catalogoservice.getSubModulo(this.idContenido, this.idGrupo).subscribe(response => {
      this.SubModuloForm.patchValue(response[0])
      this.SubModuloForm.markAsPristine()
      this.SubModuloForm.markAllAsTouched()
    });
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SubModuloForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  obtenerDatosGrupos = () => {
    this.catalogo.getCatalogosGrupos().subscribe(response => {
      this.array = response;
    })
  }

  obtenerGrupos = (id) =>{
    if(this.idContenido){
      this.catalogoservice.getCatalogoGrupos(this.idContenido).subscribe(response =>{
        this.grupos = response;
        this.idGrupo = this.grupos[0].idGrupo;
        if(this.idGrupo){
          this.obtenerDatos()
        }
      })
    }else{
      this.catalogoservice.getCatalogoGruposRestantes(id).subscribe(response =>{
        this.grupos = response;
      })
    }
   
  }

  cambios = (event) => {
    if(this.idContenido){
      this.obtenerDatos()
    }
  }

  cambioSubmodulo = (event) =>{
    this.obtenerGrupos(event.value)
  }


  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.SubModuloForm.get('idGrupo').setValue(this.idGrupo)
    this.catalogoservice.crearSubModulo(this.SubModuloForm.value).subscribe(response => {
      this.SubModuloForm.markAsPristine()
      this.SubModuloForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/submodulos-grupos')
    });

  }

  actualizarDatos = () => {
    this.SubModuloForm.get('idGrupo').setValue(this.idGrupo)
    this.catalogoservice.actualizarSubModulo(this.idContenido, this.SubModuloForm.value).subscribe(response => {
      // this.SubModuloForm.reset()
      this.SubModuloForm.markAsPristine()
      this.SubModuloForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/submodulos-grupos')
    });
  }

}
