import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ProgramaRadioService } from 'app/servicios/programa-radio.service';
import moment = require('moment');


@Component({
  selector: 'app-programas-radio',
  templateUrl: './programas-radio.component.html',
  styleUrls: ['./programas-radio.component.scss']
})
export class ProgramasRadioComponent implements OnInit {

  displayedColumns: string[] = ['idProgramaRadio','titulo','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public programasRadio: any = [];

  constructor(private servicioprogramas: ProgramaRadioService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerProgramas()
  }

  obtenerProgramas = () =>{
    this.servicioprogramas.getProgramasRadio().subscribe(response =>{
      this.programasRadio = response;
      this.programasRadio.forEach(programa => {
        programa.fecha = moment(programa.fecha).format('YYYY-MM-DD')
      });
      this.dataSource = new MatTableDataSource(this.programasRadio)
      this.dataSource.sort = this.sort;
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarPrograma = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioprogramas.eliminarProgramaRadio(id).subscribe(response =>{
          this.servicioprogramas.eliminarDetallePrograma(id).subscribe(response =>{
            
          })
          this.obtenerProgramas()
        })
      }
  
    })
  }
}
