import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { BibliotecaService } from 'app/servicios/biblioteca.service';
import { CatalogoAutoresService } from 'app/servicios/catalogo-autores.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-biblioteca-detalle',
  templateUrl: './biblioteca-detalle.component.html',
  styleUrls: ['./biblioteca-detalle.component.scss']
})
export class BibliotecaDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idBiblioteca: string = this.obtenerId().idBiblioteca;

  //VARIABLES
  edit: boolean = false;
  categorias: any = []
  PDF_HOST = environment.API_PDF;
  IMAGEN_HOST = environment.API_IMAGEN;
  documento: any = '';
  imagen: any = '';
  disableds: boolean = true;
  autores: any = [];

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  BibliotecaForm = new FormGroup({
    titulo: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    clasificacion: new FormControl('', Validators.required),
    autor: new FormControl(1),
    rutaDocumento: new FormControl(''),
    rutaImagen: new FormControl(''),
    tipo: new FormControl(0),
    estado: new FormControl(1)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicioBiblioteca: BibliotecaService,
    private serviciodocumento: DocumentService,
    private servicioautor: CatalogoAutoresService
  ) { }

  ngOnInit(): void {
    // this.obtenerAutores()

    if (this.idBiblioteca) {
      this.servicioBiblioteca.getBiblioteca(this.idBiblioteca).subscribe(response => {
        this.BibliotecaForm.patchValue(response[0])
        this.imagen = response[0].rutaImagen;
        this.documento = response[0].rutaDocumento;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.BibliotecaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let imagen = this.BibliotecaForm.get('rutaImagen').value;
    let pdf = this.BibliotecaForm.get('rutaDocumento').value;

    if (this.BibliotecaForm.get('rutaImagen').value) {
      this.BibliotecaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    if (this.BibliotecaForm.get('rutaDocumento').value) {
      this.BibliotecaForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.servicioBiblioteca.crearBiblioteca(this.BibliotecaForm.value).subscribe(response => {

      if (this.BibliotecaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }

      if (this.BibliotecaForm.get('rutaDocumento').value) {
        this.guardarPdfPHP(pdf)
      }

      // this.BibliotecaForm.reset()
      this.BibliotecaForm.markAsPristine()
      this.BibliotecaForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/biblioteca')
    });
  }

  actualizarDatos = () => {

    let imagen = this.BibliotecaForm.get('rutaImagen').value;
    let pdf = this.BibliotecaForm.get('rutaDocumento').value;

    if (this.BibliotecaForm.get('rutaImagen').value) {
      this.BibliotecaForm.controls['rutaImagen'].setValue(imagen._fileNames)
    }

    if (this.BibliotecaForm.get('rutaDocumento').value) {
      this.BibliotecaForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.servicioBiblioteca.actualizarBiblioteca(this.idBiblioteca, this.BibliotecaForm.value).subscribe(response => {

      if (this.BibliotecaForm.get('rutaImagen').value) {
        this.guardarImagenPHP(imagen)
      }

      if (this.BibliotecaForm.get('rutaDocumento').value) {
        this.guardarPdfPHP(pdf)
      }

      // this.BibliotecaForm.reset()
      this.BibliotecaForm.markAsPristine()
      this.BibliotecaForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/biblioteca')
    });

  }

  // obtenerAutores = () =>{
  //   this.servicioautor.getCatalgoAutores().subscribe(response=>{
  //     this.autores = response;
  //   })
  // }

  //GUARDAR IMAGEN EN PROYECTO
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.BibliotecaForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.BibliotecaForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });

  }

  guardarPdfPHP = (pdf: any) => {
    this.BibliotecaForm.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.BibliotecaForm.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

  //ELIMNAR IMAGEN Y DOCUMENTO
  eliminarImagen = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioBiblioteca.actualizarEstadoImagen(this.idBiblioteca, this.BibliotecaForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        });
      }

    })
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioBiblioteca.actualizarEstadoDocumento(this.idBiblioteca, this.BibliotecaForm.value).subscribe(response => {
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }

    })
  }

}
