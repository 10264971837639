import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { AcuerdoService } from 'app/servicios/acuerdos.service';
import moment = require('moment');

@Component({
  selector: 'app-acuerdo-no-respons',
  templateUrl: './acuerdo-no-respons.component.html',
  styleUrls: ['./acuerdo-no-respons.component.scss']
})
export class AcuerdoNoResponsComponent implements OnInit {

  displayedColumns: string[] = ['idAcuerdo', 'numero', 'fecha','asunto','estado', 'ver','delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public acuerdos: any = [];

  constructor(private servicioacuerdo: AcuerdoService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerAcuerdos()
  }

  obtenerAcuerdos = () => {
    this.servicioacuerdo.getAcuerdos().subscribe(response =>{
      this.acuerdos = response;
      this.acuerdos.forEach(acuerdo => {
        acuerdo.fecha = moment(acuerdo.fecha).format('YYYY-MM-DD')
      });
      this.dataSource = new MatTableDataSource(this.acuerdos)
      this.dataSource.sort = this.sort
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarAcuerdo = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.servicioacuerdo.eliminarAcuerdo(id).subscribe(response =>{
      this.obtenerAcuerdos()
     })
      }
    })
  }

}
