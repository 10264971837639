import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { GaleriaService } from 'app/servicios/galeria.service';
import moment = require('moment');

@Component({
  selector: 'app-galeria-fotografica',
  templateUrl: './galeria-fotografica.component.html',
  styleUrls: ['./galeria-fotografica.component.scss']
})
export class GaleriaFotograficaComponent implements OnInit {

  displayedColumns: string[] = ['idGaleria','fecha','lugar','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public galeria: any = [];

  constructor(private serviciogaleria: GaleriaService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerGaleria()
  }

  obtenerGaleria = () =>{
    this.serviciogaleria.getGalerias().subscribe(response =>{
      this.galeria = response;
      // this.galeria.forEach(programa => {
      //   programa.fecha = moment(programa.fecha).format('YYYY-MM-DD')
      // });
      this.dataSource = new MatTableDataSource(this.galeria)
      this.dataSource.sort = this.sort;
    })
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarGaleria = (id) =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciogaleria.eliminarGaleria(id).subscribe(response =>{
          this.serviciogaleria.eliminarImagenes(id).subscribe(response =>{
            
          })
          this.obtenerGaleria()
        })
      }
  
    })
  }

}
