import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CentroInvestigacionService } from 'app/servicios/centro-investigacion.service';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { SupervisionPermanenteService } from 'app/servicios/supervision-permanente.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-supervision-permanente-detalle',
  templateUrl: './supervision-permanente-detalle.component.html',
  styleUrls: ['./supervision-permanente-detalle.component.scss']
})
export class SupervisionPermanenteDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idSupervision: string = this.obtenerId().idSupervision;

  //VARIABLES
  edit: boolean = false;
  categorias: any = [];
  PDF_HOST = environment.API_PDF;
  supervision_doc: any = '';


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  SupervisionForms = new FormGroup({
    descripcion: new FormControl('', Validators.required),
    estado: new FormControl(1),
    rutaDocumento: new FormControl('')
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private servicesupervision: SupervisionPermanenteService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idSupervision) {
      this.servicesupervision.getSupervision(this.idSupervision).subscribe(response => {
        this.SupervisionForms.patchValue(response[0])
        this.supervision_doc = response[0].rutaDocumento;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SupervisionForms.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    let pdf = this.SupervisionForms.get('rutaDocumento').value;
    this.SupervisionForms.controls['rutaDocumento'].setValue(pdf._fileNames)

    this.servicesupervision.crearSupervision(this.SupervisionForms.value).subscribe(response => {
      if (this.SupervisionForms.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.SupervisionForms.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/supervision-permanente')
    });
  }

  actualizarDatos = () => {

    let pdf = this.SupervisionForms.get('rutaDocumento').value;
    this.SupervisionForms.controls['rutaDocumento'].setValue(pdf._fileNames)

    this.servicesupervision.actualizarSupervision(this.idSupervision, this.SupervisionForms.value).subscribe(response => {
      if (this.SupervisionForms.get('rutaDocumento').value) {
        this.guardarPDF(pdf)
      }
      this.SupervisionForms.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/supervision-permanente')
    });
  }

  guardarPDF = (pdf: any) => {
    this.SupervisionForms.controls['rutaDocumento'].setValue(pdf._files[0]);
    const pdfData = new FormData();
    pdfData.append('pdf', this.SupervisionForms.get('rutaDocumento').value);
    this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
    });
  }

}
