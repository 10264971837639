import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DescripcionArmonizacionService } from 'app/servicios/descripcion-armonizacion.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-descripcion-armonizacion',
  templateUrl: './descripcion-armonizacion.component.html',
  styleUrls: ['./descripcion-armonizacion.component.scss']
})
export class DescripcionArmonizacionComponent implements OnInit {

  DescripcionForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required])
  });

  constructor(private serviciodescripcion: DescripcionArmonizacionService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerAntecendentes()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.DescripcionForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerAntecendentes = () => {
    this.serviciodescripcion.getDescripcion(1).subscribe(response => {
      this.DescripcionForm.patchValue(response[0]);
    })
  }


  guardarDatos = () => {
    this.serviciodescripcion.actualizarDescripcion(1, this.DescripcionForm.value).subscribe(response => {
      this.DescripcionForm.markAsPristine()
      this.DescripcionForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');

    })

  }
}
