import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ConcursoNinosDetalleService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getConcursosDetalle(){
    return this.http.get(`${this.API_URI}/detalle-concursos`)
  }

  getConcursoDetalle(id:string){
    return this.http.get(`${this.API_URI}/detalle-concursos/${id}`);
  }

  actualizarConcursoDetalle(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/detalle-concursos/${id}`, datos);
  }
  
  crearConcursoDetalle(datos: any) {
    return this.http.post(`${this.API_URI}/detalle-concursos`, datos);
  }

  eliminarConcursoDetalle(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/detalle-concursos/${id}`);
  }

}
