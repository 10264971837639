import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ImagenRecomendacionesService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getImagenesRecomendaciones(){
    return this.http.get(`${this.API_URI}/imagenes-recomendaciones`)
  }

  actualizarDatos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/imagenes-recomendaciones/${id}`, datos);
  }

  getImagenRecomendaciones(id:string){
    return this.http.get(`${this.API_URI}/imagenes-recomendaciones/${id}`);
  }
  
  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/imagenes-recomendaciones/estadoImagen/${id}`, datos);
  }

}
