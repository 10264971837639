import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoNormatividadService } from 'app/servicios/catalogo-normatividad.service';

@Component({
  selector: 'app-normatividad-catalogo',
  templateUrl: './normatividad-catalogo.component.html',
  styleUrls: ['./normatividad-catalogo.component.scss']
})
export class NormatividadCatalogoComponent implements OnInit {

  displayedColumns: string[] = ['idCategoria', 'nombre', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public normatividad: any = [];

  constructor(private catalogoservice: CatalogoNormatividadService) { }

  ngOnInit(): void {
    this.obtenerNormatividad()
  }

  obtenerNormatividad = () =>{
    this.catalogoservice.getNormatividadAll().subscribe(response =>{
      this.normatividad = response;
      this.dataSource = new MatTableDataSource(this.normatividad);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
