import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ConcursoService } from 'app/servicios/concursos.service';
import { environment } from 'environments/environment.prod';

@Component({
  selector: 'app-concursos',
  templateUrl: './concursos.component.html',
  styleUrls: ['./concursos.component.scss']
})
export class ConcursosComponent implements OnInit {

  displayedColumns: string[] = ['idConcurso', 'titulo', 'estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public concursos: any = [];
  public ganadores: any = [];

  constructor(private serviceconcurso: ConcursoService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerConcursos()
  }

  obtenerConcursos = () => {
    this.serviceconcurso.getconcursos().subscribe(response => {
      // this.dataSource.data.length = 0;
      this.concursos = response;
      this.dataSource = new MatTableDataSource(this.concursos)
      this.dataSource.sort = this.sort;
    })
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarConcurso = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviceconcurso.eliminarconcurso(id).subscribe(response => {
          // this.serviceconcurso.getGanadoresConcurso(id,).subscribe(response =>{
          //   this.ganadores = response;
          // })
          this.ganadores.forEach(ganador => {
            this.serviceconcurso.eliminarAllImagenes(ganador.idGanador).subscribe(response => {

            })
          });
          this.serviceconcurso.eliminarAllGanadores(id).subscribe(response => {
          })
          this.obtenerConcursos()
        })
      }
    })
  }

  
  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }


  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.concursos, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.concursos);
    this.dataSource.sort = this.sort;
    this.concursos.reverse()
    this.concursos.forEach(concurso => {
      concurso.orden = i;

      if (concurso.estado == 'ACTIVO') {
        concurso.estado = 1;
      } else {
        concurso.estado = 0;
      }

      this.serviceconcurso.actualizarconcurso(concurso.idConcurso, concurso).subscribe(response => {
      });


      if (concurso.estado == 1) {
        concurso.estado = 'ACTIVO';
      } else {
        concurso.estado = 'INACTIVO';
      }

      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
    this.concursos.reverse()
    // this.obtenerconcursos()
  }

}
