import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CatalogoVideosService } from 'app/servicios/catalogos/catalogo-videos.service';

@Component({
  selector: 'app-catalogo-videos',
  templateUrl: './catalogo-videos.component.html',
  styleUrls: ['./catalogo-videos.component.scss']
})
export class CatalogoVideosComponent implements OnInit {


  @ViewChild(MatSort) sort: MatSort;
  public categorias: any = [];

  displayedColumns: string[] = ['orden','nombre', 'estado' ,'ver'];
  dataSource: MatTableDataSource<any>;

  constructor(private catalogoservice: CatalogoVideosService,
              private snackBar: MatSnackBar,
              private router: Router) { }

  ngOnInit(): void {
    this.obtenerCategorias()
  }

  obtenerCategorias = () =>{
    this.catalogoservice.getCatalgoVideos().subscribe(response =>{
      this.categorias.length = 0;
      this.categorias = response;
      this.dataSource = new MatTableDataSource(this.categorias);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

    //AVISO TIPO SNACKBAR ANGULAR MATERIAL
    openSnackBar(message: string) {
      this.snackBar.open(message, "Aceptar", { duration: 2000 });
    }
  

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.categorias, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.categorias);
    this.dataSource.sort = this.sort;
    this.categorias.reverse()
    this.categorias.forEach(categoria => {
      categoria.orden = i;
      this.catalogoservice.actualizarCatalogoVideo(categoria.idCatalogoVideo, categoria).subscribe(response =>{
      });
      i++;
    });
    this.categorias.reverse()
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }
}
