import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { ImagenRecomendacionesService } from 'app/servicios/imagenes.recomendaciones.service';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.scss']
})
export class ImagenesComponent implements OnInit {


  //VARIABLES
  edit: boolean = false;
  IMAGEN_HOST = environment.API_IMAGEN;
  imagen = '';

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  ImagenesForm = new FormGroup({

    idImagen: new FormControl(1),
    rutaImagen: new FormControl('', Validators.required),

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciodatos: ImagenRecomendacionesService,
    private serviciodocumento: DocumentService
     ) { }

  ngOnInit(): void {
    this.obtenerDatos()
    this.obtenerImagen()    
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.ImagenesForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  obtenerImagen = () =>{
    let id = '';
    id = this.ImagenesForm.get('idImagen').value;
    this.serviciodatos.getImagenRecomendaciones(id).subscribe(response => {
      this.imagen = response[0].rutaImagen; 
    });
  }


  obtenerDatos = () =>{
    this.serviciodatos.getImagenesRecomendaciones().subscribe(response=>{
      this.array = response;
    })
  }

  cambios = (event) =>{
    this.obtenerImagen()
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  actualizarDatos = () => {

    let imagen = this.ImagenesForm.get('rutaImagen').value;
    this.ImagenesForm.controls['rutaImagen'].setValue(imagen._fileNames);
    let id = this.ImagenesForm.get('idImagen').value;

    this.serviciodatos.actualizarDatos( id, this.ImagenesForm.value).subscribe(response =>{
      this.guardarImagenPHP(imagen);
      this.ImagenesForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
    });
  }

  
  guardarImagenPHP = (imagen: any) => {
    imagen._files.forEach(element => {
      this.ImagenesForm.controls['rutaImagen'].setValue(element);
      const imgData = new FormData();
      imgData.append('imagen', this.ImagenesForm.get('rutaImagen').value);
      this.serviciodocumento.guardarImg(imgData).subscribe(response => {
      });
    });
  }

  eliminarImagen = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.serviciodatos.actualizarEstadoImagen(this.ImagenesForm.get('idImagen').value, this.ImagenesForm.value).subscribe(response=>{
      this.serviciodocumento.eliminarImagen(this.imagen).subscribe(response=>{

      });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
  
    })
  }

}
