import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ConvocatoriaService } from 'app/servicios/convocatoria.service';

@Component({
  selector: 'app-convocatorias',
  templateUrl: './convocatorias.component.html',
  styleUrls: ['./convocatorias.component.scss']
})
export class ConvocatoriasComponent implements OnInit {

  displayedColumns: string[] = ['idConvocatoria', 'titulo','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public centro_investigacion: any = [];

  constructor(private servicioconvocatoria: ConvocatoriaService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerConvocatorias()
  }

  obtenerConvocatorias = () =>{
    this.servicioconvocatoria.getConvocatorias().subscribe(response =>{
      this.centro_investigacion = response;
      this.dataSource = new MatTableDataSource(this.centro_investigacion);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarConvocatoria = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.servicioconvocatoria.eliminarConvocatoria(id).subscribe(response =>{
          this.obtenerConvocatorias()
        })
      }
  
    })
  }

}
