import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ConvocatoriaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getConvocatorias(){
    return this.http.get(`${this.API_URI}/convocatorias`)
  }

  getConvocatoria(id:string){
    return this.http.get(`${this.API_URI}/convocatorias/${id}`);
  }

  actualizarConvocatoria(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/convocatorias/${id}`, datos);
  }
  
  crearConvocatoria(datos: any) {
    return this.http.post(`${this.API_URI}/convocatorias`, datos);
  }

  eliminarConvocatoria(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/convocatorias/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/convocatorias/estadoDocumento/${id}`, datos);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/convocatorias/estadoImagen/${id}`, datos);
  }

  actualizarEstadoDocumentoFicha(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/convocatorias/estadoDocumentoFicha/${id}`, datos);
  }

  

}
