import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoCategoriasNinosService } from 'app/servicios/catalogo-categoria-ninos.controller';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-catalogo-ninos-cat-detalle',
  templateUrl: './catalogo-ninos-cat-detalle.component.html',
  styleUrls: ['./catalogo-ninos-cat-detalle.component.scss']
})
export class CatalogoNinosCatDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idCategoria: string = this.obtenerId().idCategoria;

  //VARIABLES
  edit: boolean = false;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  CategoriaForm = new FormGroup({
    nombre: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoCategoriasNinosService) { }

  ngOnInit(): void {
    if (this.idCategoria) {
      this.catalogoservice.getCatalogoCategoriaNino(this.idCategoria).subscribe(response => {
        this.CategoriaForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.CategoriaForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.catalogoservice.crearCatalogoCategoriaNino(this.CategoriaForm.value).subscribe(response =>{
      this.CategoriaForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/catalogo-ninos')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoCategoriaNino(this.idCategoria, this.CategoriaForm.value).subscribe(response =>{
      this.CategoriaForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-ninos')
    });
  }
}
