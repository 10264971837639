import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoGruposVulnerablesService } from 'app/servicios/catalogo-grupos-vulnerables.service';
import { DataService } from 'app/servicios/data.service';
import { GruposVulnerablesService } from 'app/servicios/grupos-vulnerables.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-grupos-vulnerables-detalle',
  templateUrl: './grupos-vulnerables-detalle.component.html',
  styleUrls: ['./grupos-vulnerables-detalle.component.scss']
})
export class GruposVulnerablesDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idGrupoVulnerable: string = this.obtenerId().idGrupoVulnerable;

  //VARIABLES
  edit: boolean = false;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  GrupoVulnerableForm = new FormGroup({

    descripcion: new FormControl('', Validators.required),
    idGrupo: new FormControl(0, Validators.required)
  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: GruposVulnerablesService,
    private catalogo: CatalogoGruposVulnerablesService) { }

  ngOnInit(): void {
    this.obtenerGrupos()
    if (this.idGrupoVulnerable) {
      this.catalogoservice.getGrupoVulnerable(this.idGrupoVulnerable).subscribe(response => {
        this.GrupoVulnerableForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.GrupoVulnerableForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  obtenerGrupos = () =>{
    this.catalogo.getCatalgoGruposVulnerables().subscribe(response=>{
      this.array = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.catalogoservice.crearGrupoVulnerable(this.GrupoVulnerableForm.value).subscribe(response =>{
      this.GrupoVulnerableForm.markAsPristine()
      this.GrupoVulnerableForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/grupos-vulnerables')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarGrupoVulnerable(this.idGrupoVulnerable, this.GrupoVulnerableForm.value).subscribe(response =>{
      // this.GrupoVulnerableForm.reset()
      this.GrupoVulnerableForm.markAsPristine()
      this.GrupoVulnerableForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/grupos-vulnerables')
    });
  }

}
