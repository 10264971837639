import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoMicroSitiosService } from 'app/servicios/catalogo-micrositios.service';
import { CatalogoUnidadInvestigacionService } from 'app/servicios/catalogo-unidad-inv.service';
import { SubModulosService } from 'app/servicios/centro-investigacion/submodulos.service';
import { DataService } from 'app/servicios/data.service';
import { MicroSitiosService } from 'app/servicios/micrositios.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-submodulos-detalle',
  templateUrl: './submodulos-detalle.component.html',
  styleUrls: ['./submodulos-detalle.component.scss']
})
export class SubmodulosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idContenido: string = this.obtenerId().idContenido;

  //VARIABLES
  edit: boolean = false;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  SubModuloForm = new FormGroup({

    contenido: new FormControl('', Validators.required),
    idCatalogo: new FormControl(1, Validators.required)

  });

  array: any = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: SubModulosService,
    private catalogo: CatalogoUnidadInvestigacionService) { }

  ngOnInit(): void {
    this.obtenerDatos()
    if (this.idContenido) {
      this.catalogoservice.getSubModulo(this.idContenido).subscribe(response => {
        this.SubModuloForm.patchValue(response[0])
        this.SubModuloForm.markAsPristine()
        this.SubModuloForm.markAllAsTouched()
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.SubModuloForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  obtenerDatos = () =>{
    this.catalogo.getCatalogoUnidadesInvestigacion().subscribe(response=>{
      this.array = response;
    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.catalogoservice.crearSubModulo(this.SubModuloForm.value).subscribe(response =>{
      this.SubModuloForm.markAsPristine()
      this.SubModuloForm.markAllAsTouched()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/submodulos')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarSubModulo(this.idContenido, this.SubModuloForm.value).subscribe(response =>{
      // this.SubModuloForm.reset()
      this.SubModuloForm.markAsPristine()
      this.SubModuloForm.markAllAsTouched()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/submodulos')
    });
  }
}
