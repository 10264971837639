import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CatalogoUnidadInvestigacionService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getCatalogoUnidadesInvestigacion(){
    return this.http.get(`${this.API_URI}/catalogo-unidad-investigacion`)
  }

  getCatalogoUnidadInvestigacion(id:string){
    return this.http.get(`${this.API_URI}/catalogo-unidad-investigacion/${id}`);
  }

  actualizarCatalogoUnidadInvestigacion(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/catalogo-unidad-investigacion/${id}`, datos);
  }
  
  crearCatalogoUnidadInvestigacion(datos: any) {
    return this.http.post(`${this.API_URI}/catalogo-unidad-investigacion`, datos);
  }

  eliminarCatalogoUnidadInvestigacion(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/catalogo-unidad-investigacion/${id}`);
  }

}
