import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class GaleriaService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getGalerias(){
    return this.http.get(`${this.API_URI}/galeria-fotografica`)
  }

  getGaleria(id:string){
    return this.http.get(`${this.API_URI}/galeria-fotografica/${id}`);
  }

  actualizarGaleria(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/galeria-fotografica/${id}`, datos);
  }
  
  crearGaleria(datos: any) {
    return this.http.post(`${this.API_URI}/galeria-fotografica`, datos);
  }

  eliminarGaleria(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/galeria-fotografica/${id}`);
  }

  getImagenes(id:string){
    return this.http.get(`${this.API_URI}/galeria-fotografica/imagen/${id}`);
  }

  crearImagen(datos: any) {
    return this.http.post(`${this.API_URI}/galeria-fotografica/imagen/galeria`, datos);
  }

  eliminarImagen(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/galeria-fotografica/imagen/${id}`);
  }

  eliminarImagenes(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/galeria-fotografica/eliminarI/${id}`);
  }

  actualizarImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/galeria-fotografica/imagenUpdate/${id}`, datos);
  }
  

}
