import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoNormatividadService } from 'app/servicios/catalogo-normatividad.service';
import { NormatividadService } from 'app/servicios/normatividad.service';

@Component({
  selector: 'app-normatividad',
  templateUrl: './normatividad.component.html',
  styleUrls: ['./normatividad.component.scss']
})
export class NormatividadComponent implements OnInit {

  displayedColumns: string[] = ['orden', 'descripcion', 'estado', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public normatividad: any = [];
  categoria: any = 2;
  categorias: any = [];

  constructor(private normatividadservices: NormatividadService,
    private catalogoNormatividad: CatalogoNormatividadService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerCategorias()
    this.obtenerNormatividad()
  }

  obtenerNormatividad = () => {
    this.normatividadservices.getNormatividadAll(this.categoria).subscribe(response => {
      this.normatividad = response;
      this.dataSource = new MatTableDataSource(this.normatividad)
      this.dataSource.sort = this.sort
    });
  }

  obtenerCategorias = () => {
    this.catalogoNormatividad.getNormatividadAll().subscribe(response => {
      this.categorias = response;
    })
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cambios = () => {
    this.obtenerNormatividad()
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.normatividad, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.normatividad);
    this.dataSource.sort = this.sort;
    this.normatividad.reverse()

    this.normatividad.forEach(norma => {
      norma.orden = i;

      if (norma.estado == 'ACTIVO') {
        norma.estado = 1;
      } else {
        norma.estado = 0;
      }

      this.normatividadservices.actualizarNormatividad(norma.idNormatividad, norma).subscribe(response => {
      });

      if (norma.estado == 1) {
        norma.estado = 'ACTIVO';
      } else {
        norma.estado = 'INACTIVO';
      }
      i++;
    });
    this.normatividad.reverse()

    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }


}
