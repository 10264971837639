import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ImagenDestacadosService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getImagenesDestacados(){
    return this.http.get(`${this.API_URI}/imagenes-destacados`)
  }

  actualizarDatos(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/imagenes-destacados/${id}`, datos);
  }

  getImagenDestacados(id:string){
    return this.http.get(`${this.API_URI}/imagenes-destacados/${id}`);
  }
  
  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/imagenes-destacados/estadoImagen/${id}`, datos);
  }

}
