import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { ConsejoConsultivoService } from 'app/servicios/consejo-consultivo.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-consejo-consultivo',
  templateUrl: './consejo-consultivo.component.html',
  styleUrls: ['./consejo-consultivo.component.scss']
})
export class ConsejoConsultivoComponent implements OnInit {

  displayedColumns: string[] = ['idPersonalConsultivo', 'nombre', 'puesto', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public personal: any = [];

  constructor(private consejoservice: ConsejoConsultivoService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerConsejoConsultivo()
  }

  obtenerConsejoConsultivo = () => {
    this.consejoservice.getConsejoConsultivo().subscribe(response => {
      this.personal = response;
      this.dataSource = new MatTableDataSource(this.personal);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarPersonalConsejo = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.consejoservice.eliminarPersonalConsejo(id).subscribe(cresponse => {
          this.obtenerConsejoConsultivo()
        });
      }

    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.personal, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.personal);
    this.dataSource.sort = this.sort;
    this.personal.forEach(personal => {
      personal.orden = i;
      this.consejoservice.actualizarPersonalConsejo(personal.idPersonalConsultivo, personal).subscribe(response =>{
      });
      i++;
    });
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
