import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'app/servicios/data.service';
import { LoginService } from 'app/servicios/login.service';
import { Observable } from 'rxjs';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

declare interface Componente {
  label: string,
  link: string,
  icon: string,
  items?: []
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'PANEL DE CONTROL', icon: 'pe-7s-graph', class: '' },
  { path: '/datosEmpresa', title: 'DATOS EMPRESA', icon: 'pe-7s-culture', class: '' },
  { path: '/productos', title: 'LA COMISIÓN', icon: 'pe-7s-note2', class: '' },
  { path: '/typography', title: 'DERECHOS HUMANOS', icon: 'pe-7s-hammer', class: '' },
  { path: '/icons', title: 'RECOMENDACIONES', icon: 'pe-7s-news-paper', class: '' },
  { path: '/maps', title: 'COMUNICACIÓN SOCIAL', icon: 'pe-7s-chat', class: '' },
  { path: '/notifications', title: 'SECCIÓN DE NIÑOS', icon: 'pe-7s-plugin', class: '' },
  { path: '', title: 'Cerrar sesión', icon: 'pe-7s-left-arrow', class: 'active-pro' },
];




@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  menuItemSidebar: any = [];
  config = {
    // paddingAtStart: true,
    // classname: 'my-custom-class',
    // listBackgroundColor: 'rgb(255, 160, 75)',
    // fontColor: 'rgb(0,0,0)',
    // backgroundColor: 'rgb(255, 153, 80)',
    // selectedListFontColor: 'rgb(86, 101, 115)',
    selectedListFontColor: 'rgb(44, 62, 80)'
  };
  dataUsuario;

  appitems: any = [];
  modulos: any = [];
  item: any = [
    {
      label: "Mensaje del Presidente",
      link: '/mensaje-presidente',
      icon: 'sms'
    },
    {
      label: "Antecedentes",
      link: '/antecedentes',
      icon: 'event_repeat'
    },
    {
      label: "Atribuciones",
      link: '/atribuciones',
      icon: 'supervised_user_circle'
    },
    {
      label: 'Consejo consultivo',
      link: '/consejo-consultivo',
      icon: 'groups'
    },
    {
      label: 'Organigrama',
      icon: 'schema',
      link: '/descripcion-organigrama',
      items: [
        {
          label: 'Organigrama',
          link: '/organigrama',
          icon: 'schema'
        }
      ]
    },
    {
      label: 'Sedes',
      link: '/sedes',
      icon: 'account_balance'
    }
  ]

  menuDerechosHumanos: any = [
    {
      label: 'Descripción D.H',
      link: '/contenido-derechos-humanos',
      icon: 'account_circle'
    },
    {
      label: 'Defensa y Protección',
      icon: 'add_moderator',
      items: [
        {
          label: 'Supervisión DH',
          link: '/supervision-dh',
          icon: 'supervisor_account'
        },
        {
          label: 'Sistema Estatal de Alerta',
          link: '/conciliaciones',
          icon: 'menu_book'
        },
        // {
        //   label: 'Medidas Cautelares',
        //   link: '/medidas-cautelares',
        //   icon: 'menu_book'
        // }
        // ,
        // {
        //   label: 'Comisiones estatales',
        //   link: '/prueba',
        //   icon: 'sticky_note_2'
        // }
      ]
    }, {
      label: 'Divulgación y Promoción',
      icon: 'assignment',
      items: [
        {
          label: 'Convenio',
          link: '/convenio-colaboracion',
          icon: 'supervisor_account'
        },
        {
          label: 'Capacitaciones',
          link: '/capacitacion',
          icon: 'supervisor_account'
        }
      ]
    },
    {
      label: 'Centro Investigación',
      link: '/centro-investigacion',
      icon: 'auto_stories',
      items: [
        {
          label: 'Modulos Análisis',
          link: '/submodulos',
          icon: 'receipt_long'
        },
        // {
        //   label: 'Mecanismo',
        //   link: '/mecanismo',
        //   icon: 'receipt_long'
        // },
        {
          label: 'Unidad Inv.',
          link: '/unidad-investigacion',
          icon: 'receipt_long'
        },
        {
          label: 'Unidad Doc.',
          link: '/unidad-documentacion',
          icon: 'receipt_long'
        },
        {
          label: 'Unidad Profesionalismo',
          link: '/unidad-profesionalismo',
          icon: 'receipt_long'
        },

      ]
    },
    // {
    //   label: 'Organismos DDHH',
    //   link: '/centro-investigacion',
    //   icon: 'local_library'
    // },
    // {
    //   label: 'Supervisión permanente',
    //   link: '/supervision-permanente',
    //   icon: 'supervisor_account'
    // },
    {
      label: 'Organismos',
      icon: 'recent_actors',
      items: [
        {
          label: 'Nacionales e Internacionales',
          link: '/organismos-nal-intl',
          icon: 'recent_actors'
        }
        // ,
        // {
        //   label: 'Comisiones estatales',
        //   link: '/prueba',
        //   icon: 'sticky_note_2'
        // }
      ]
    },

    // {
    //   label: 'Instrumentos',
    //   icon: 'build_circle',
    //   items: [
    //     {
    //       label: 'Declaraciones Internacionales',
    //       link: '/prueba',
    //       icon: 'menu_book'
    //     },
    //     {
    //       label: 'Tratados Internacionales',
    //       link: '/prueba',
    //       icon: 'menu_book'
    //     },
    //     {
    //       label: 'Tratados anexos',
    //       link: '/prueba',
    //       icon: 'menu_book'
    //     }
    //   ]
    // }
  ]

  menuRecomendaciones: any = [
    {
      label: "Recomendaciones",
      link: '/recomendacion',
      icon: 'record_voice_over'
    },
    {
      label: 'Recomendaciones Generales',
      link: '/recomendacion-general',
      icon: 'record_voice_over'
    },
    {
      label: 'Seguimiento',
      link: '/seguimiento',
      icon: 'import_contacts'
    },
    {
      label: 'Acuerdos No Responsabilidad',
      link: '/acuerdos',
      icon: 'assignment'
    },
    {
      label: 'Inconstitucionalidad',
      link: '/inconstitucionalidad',
      icon: 'import_contacts'
    },
    {
      label: 'Informes Especiales',
      link: '/informes-especiales',
      icon: 'menu_book'
    },
    {
      label: 'Imágenes',
      link: '/imagenes-recomendaciones',
      icon: 'image'
    },

  ]

  menuDifusion: any = [
    {
      label: 'Concursos',
      link: '/concursos',
      icon: 'app_registration'
    },
    {
      label: 'Boletines prensa',
      link: '/boletines-prensa',
      icon: 'question_answer'
    },
    {
      label: 'Programas de radio',
      link: '/programas-radio',
      icon: 'voicemail'
    },
    {
      label: 'Galería',
      link: '/galeria-fotografica',
      icon: 'image'
    },
    {
      label: 'Publicaciones',
      link: '/publicaciones',
      icon: 'feed'
    },
    {
      label: 'Videos',
      link: '/videos',
      icon: 'smart_display'
    },
  ]

  menuConfiguracion: any = [
    {
      label: 'Grupos Permisos',
      link: '/grupos-permisos',
      icon: 'feed'
    },
    {
      label: 'Usuarios',
      link: '/usuarios',
      icon: 'feed'
    }
  ]

  menuNinos: any = [
    {
      label: 'Grupos Vulnerables',
      link: '/descripcion-grupos-vulnerables',
      icon: 'groups',
      items: [
        {
          label: 'Grupos Vulnerables',
          link: '/grupos-vulnerables',
          icon: 'groups'
        },
        {
          label: 'Sub Modulos',
          link: '/submodulos-grupos',
          icon: 'groups'
        }
      ]
    },
    {
      label: 'Convocatorias',
      link: '/convocatorias',
      icon: 'travel_explore'
    },
    {
      label: 'Cursos',
      link: '/cursos',
      icon: 'app_registration',
      items: [
        {
          label: 'Descripción Cursos',
          link: '/descripcion-cursos',
          icon: 'contact_mail'
        }
      ]
    },
    {
      label: 'Biblioteca',
      link: '/biblioteca',
      icon: 'book'
    },

    {
      label: 'Transparencia',
      icon: 'article',
      items: [
        {
          label: 'Transparencia',
          link: '/transparencia',
          icon: 'local_library'
        },
        {
          label: 'Descripción Armonización',
          link: '/descripcion-armonizacion',
          icon: 'contact_mail'
        },
        {
          label: 'Armonización Contable',
          link: '/armonizacion',
          icon: 'article'
        }
      ]
    },
    {
      label: 'Organo Control Int.',
      icon: 'sticky_note_2',
      items: [
        {
          label: 'Quienes somos',
          link: '/quienes-somos',
          icon: 'account_circle'
        },
        {
          label: 'Normatividad',
          link: '/normatividad-org',
          icon: 'article'
        },
        {
          label: 'Declaraciones Patrimoniales ',
          link: '/declaracion-patrimonial',
          icon: 'article'
        },
        {
          label: 'Procedimiento entrega-recepción',
          link: '/entrega-recepcion',
          icon: 'article'
        },
        {
          label: 'Presenta tu Denuncia',
          link: '/presenta-tu-denuncia',
          icon: 'interpreter_mode'
        }

      ]
    },
    {
      label: 'Sistema Control Int.',
      icon: 'sticky_note_2',
      items: [
        {
          label: 'Descripción SCI',
          link: '/descripcion-sci',
          icon: 'contact_mail'
        },
        {
          label: 'Normatividad',
          link: '/normatividad',
          icon: 'article'
        },
        {
          label: 'Armonización Contable',
          link: '/armonizacion',
          icon: 'article'
        },
        {
          label: 'Control Interno',
          link: '/comite-control',
          icon: 'article'
        },
        {
          label: 'Sub-Módulos SCI',
          link: '/submodulos-sci',
          icon: 'article'
        },

      ]
    },
    {
      label: 'Imágenes',
      link: '/imagenes',
      icon: 'image'
    },

  ]

  menuCatalogo: any = [
    {
      label: 'Derechos Humanos',
      icon: 'gavel',
      items: [
        {
          label: 'Organismos',
          link: '/catalogo-organismos',
          icon: 'menu_book'
        },
        {
          label: 'Unidad Investigación',
          link: '/catalogo-unidad-inv',
          icon: 'menu_book'
        }
      ]
    },
    {
      label: 'Destacados',
      icon: 'view_list',
      items: [
        {
          label: 'Sistema Control Int.',
          icon: 'sticky_note_2',
          items: [
            {
              label: 'Normatividad',
              link: '/catalogo-normatividad',
              icon: 'menu_book'
            },
            {
              label: 'SCI',
              link: '/catalogo-sci',
              icon: 'menu_book'
            },
          ]
        },
        {
          label: 'Transparencia',
          icon: 'sticky_note_2',
          items: [
            {
              label: 'Armonización Principal',
              link: '/catalogo-encabezado',
              icon: 'menu_book'
            },
            {
              label: 'Armonización Detalle',
              link: '/catalogo-detalle',
              icon: 'menu_book'
            },
          ]
        }
      ]
    },
    {
      label: 'Difusión',
      icon: 'record_voice_over',
      items: [
        {
          label: 'Publicaciones',
          link: '/catalogo-publicacion',
          icon: 'menu_book'
        },
        {
          label: 'Autores Biblioteca',
          link: '/catalogo-autores',
          icon: 'menu_book'
        }, {
          label: 'Concursos',
          link: '/catalogo-concurso',
          icon: 'menu_book'
        }, {
          label: 'Videos',
          link: '/catalogo-videos',
          icon: 'menu_book'
        }
      ]
    },
    {
      label: 'Sección niños',
      icon: 'smart_toy',
      items: [
        ,
        {
          label: 'Juegos Niños',
          link: '/catalogo-juegos',
          icon: 'menu_book'
        }
        // ,
        // {
        //   label: 'Categorías ganadores niños',
        //   link: '/catalogo-ninos',
        //   icon: 'menu_book'
        // },
        // {
        //   label: 'Puestos concursos',
        //   link: '/catalogo-puesto',
        //   icon: 'menu_book'
        // }
      ]
    },
    {
      label: 'Grupos Vulnerables',
      icon: 'menu_book',
      items: [
        ,
        {
          label: 'Grupo Vulnerable',
          link: '/catalogo-grupos-vulnerables',
          icon: 'menu_book'
        },
        {
          label: 'Sub modulos grupos',
          link: '/catalogo-submodulo-grupo',
          icon: 'menu_book'
        }
      ]
    },
    {
      label: 'Micrositios',
      link: '/catalogo-micrositios',
      icon: 'menu_book'
    },
    {
      label: 'Años',
      link: '/catalogo-fecha',
      icon: 'timer'
    }
  ]

  menuSeccionNinios: any = [
    {
      label: 'Escríbenos',
      link: '/escribenos',
      icon: 'mode_edit'
    },
    {
      label: 'Juegos',
      link: '/juegos',
      icon: 'extension'
    },
    {
      label: 'Audios',
      icon: 'volume_up',
      items: [
        {
          label: 'Descripción',
          link: '/descripcion-audios',
          icon: 'mic'
        },
        {
          label: 'Audios',
          link: '/audios-ninios',
          icon: 'mic'
        },
        {
          label: 'Videos',
          link: '/videos-ninios',
          icon: 'smart_display'
        }
      ]
    },
    {
      label: 'Cuentos',
      icon: 'library_books',
      items: [
        {
          label: 'Descripción',
          link: '/descripcion-cuentos',
          icon: 'auto_stories'
        }
        // ,
        // {
        //   label: 'Concursos',
        //   link: '/concursos-ninios',
        //   icon: 'note'
        // }
      ]
    },
    {
      label: 'Conoce tus Derechos',
      icon: 'local_library',
      items: [
        {
          label: 'Derechos PDF',
          link: '/conoce-derechos',
          icon: 'book'
        },
        {
          label: 'Descripción derechos',
          link: '/descripcion-derechos',
          icon: 'book'
        }
      ]
    }
  ]


  constructor(private dataService: DataService,
    private loginCtrl: LoginService) { }

  ngOnInit() {
    this.loginCtrl.obtenerInfoUsuario().subscribe(response => {
      this.dataUsuario = response;
      // if (this.dataUsuario.permisos == 1) {
      //   this.menuItems = ROUTES.filter(menuItem => menuItem);
      // } else {
      //   this.menuItems = ROUTESUSER.filter(menuItem => menuItem);
      // }
    });
    this.obtenerMenus()
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  obtenerMenus = () => {
    // this.appitems =  this.dataService.getMenuOpciones();
    this.dataService.getMenu(this.dataUsuario.idGrupo).subscribe(response => {
      let array: any = []
      this.appitems = response;
      this.appitems.forEach(seccion => {
        if (seccion.link) {
        } else {
          seccion.items = []
          if (seccion.idSeccion == 2) {
            seccion.items = this.item;
          } else if (seccion.idSeccion == 3) {
            seccion.items = this.menuDerechosHumanos;
          } else if (seccion.idSeccion == 4) {
            seccion.items = this.menuRecomendaciones;
          } else if (seccion.idSeccion == 5) {
            seccion.items = this.menuDifusion;
          } else if (seccion.idSeccion == 6) {
            seccion.items = this.menuNinos
          } else if (seccion.idSeccion == 7) {

          } else if (seccion.idSeccion == 8) {
            seccion.items = this.menuCatalogo
          } else if (seccion.idSeccion == 10) {
            seccion.items = this.menuSeccionNinios
          } else if (seccion.idSeccion == 12) {
            seccion.items = this.menuConfiguracion
          }
        }

        // this.dataService.getMenuModulos(seccion.idSeccion).subscribe(response =>{
        //   this.modulos = response;
        //   seccion.items = [];
        //   seccion.items.push(this.item)
        //   this.modulos.forEach(modulo => {
        //     seccion.items.push(modulo);
        //   });
        // });
      });
    });

  }

  // obtenerMenuModulos = (id) =>{

  //   this.dataService.getMenuModulos(id).subscribe(response =>{

  //   });
  // }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
