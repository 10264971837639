import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CatalogoMicroSitiosService } from 'app/servicios/catalogo-micrositios.service';

@Component({
  selector: 'app-catalogo-micrositios',
  templateUrl: './catalogo-micrositios.component.html',
  styleUrls: ['./catalogo-micrositios.component.scss']
})
export class CatalogoMicrositiosComponent implements OnInit {

  displayedColumns: string[] = ['orden', 'nombreSeccion', 'estado', 'ver'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public array: any = [];

  constructor(private catalogoservice: CatalogoMicroSitiosService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerDatos()
  }

  obtenerDatos = () =>{
    this.catalogoservice.getCatalgoMicroSitios().subscribe(response =>{
      this.array = response;
      console.log(this.array)
      this.dataSource = new MatTableDataSource(this.array);
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

      //AVISO TIPO SNACKBAR ANGULAR MATERIAL
      openSnackBar(message: string) {
        this.snackBar.open(message, "Aceptar", { duration: 2000 });
      }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.array, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.array);
    this.dataSource.sort = this.sort;
    this.array.reverse()
    this.array.forEach(categoria => {
      categoria.orden = i;

      if (categoria.estado == 'ACTIVO') {
        categoria.estado = 1;
      } else {
        categoria.estado = 0;
      }

      this.catalogoservice.actualizarCatalogoMicroSitio(categoria.idSitio, categoria).subscribe(response =>{
      });

      if (categoria.estado == 1) {
        categoria.estado = 'ACTIVO';
      } else {
        categoria.estado = 'INACTIVO';
      }
      i++;
    });
    this.array.reverse()
    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
