import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { VideosNinosService } from 'app/servicios/videos-ninios.service';

@Component({
  selector: 'app-videos-ninios',
  templateUrl: './videos-ninios.component.html',
  styleUrls: ['./videos-ninios.component.scss']
})
export class VideosNiniosComponent implements OnInit {

  displayedColumns: string[] = ['orden', 'url','estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public videos: any = [];

  constructor(private serviciovideo: VideosNinosService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerVideos()
  }

  obtenerVideos = () =>{
    this.serviciovideo.getVideos().subscribe(response =>{
      this.videos = response;
      this.dataSource = new MatTableDataSource(this.videos);
      this.dataSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarAviso = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciovideo.eliminarVideo(id).subscribe(response =>{
          this.obtenerVideos()
        })
      }
  
    })
  }

    //AVISO TIPO SNACKBAR ANGULAR MATERIAL
    openSnackBar(message: string) {
      this.snackBar.open(message, "Aceptar", { duration: 2000 });
    }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.videos, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.videos);
    this.dataSource.sort = this.sort;
    this.videos.reverse()

    this.videos.forEach(video => {
      video.orden = i;

      if (video.estado == 'ACTIVO') {
        video.estado = 1;
      } else {
        video.estado = 0;
      }


      this.serviciovideo.actualizarVideo(video.idVideo, video).subscribe(response => {
      });


      if (video.estado == 1) {
        video.estado = 'ACTIVO';
      } else {
        video.estado = 'INACTIVO';
      }
      i++;
    });
    this.videos.reverse()

    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
