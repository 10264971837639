import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ConcursoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getconcursos(){
    return this.http.get(`${this.API_URI}/concursos`)
  }

  getconcurso(id:string){
    return this.http.get(`${this.API_URI}/concursos/${id}`);
  }

  actualizarconcurso(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/concursos/${id}`, datos);
  }
  
  crearconcurso(datos: any) {
    return this.http.post(`${this.API_URI}/concursos`, datos);
  }

  eliminarconcurso(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/concursos/${id}`);
  }

  getGanadoresConcurso(id:string, idAnio: string){
    return this.http.get(`${this.API_URI}/concursos/ganador/${id}/${idAnio}`);
  }

  actualizarGanadorConcurso(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/concursos/ganador/${id}`, datos);
  }
  
  crearGanadorConcurso(datos: any) {
    return this.http.post(`${this.API_URI}/concursos/ganador/concurso`, datos);
  }

  eliminarGanadorConcurso(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/concursos/ganador/${id}`);
  }

  crearGanadorConcursoImagenes(datos: any) {
    return this.http.post(`${this.API_URI}/concursos/ganador/imagen`, datos);
  }

  getGanadoresConcursoImagenes(id:string){
    return this.http.get(`${this.API_URI}/concursos/imagen/${id}`);
  }

  eliminarAllImagenes(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/concursos/eliminarI/${id}`);
  }
  
  eliminarAllGanadores(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/concursos/eliminarG/${id}`);
  }

  actualizarEstadoImagen(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/concursos/estadoImagen/${id}`, datos);
  }

  getGanadorImagenes(id:string){
    return this.http.get(`${this.API_URI}/concursos/ganadorSeleccionado/${id}`);
  }

  eliminarImagenGanador(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/concursos/ganadorSeleccionado/${id}`);
  }


}
