import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoJuegosService } from 'app/servicios/catalogo-juegos.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-catalogo-juegos-detalle',
  templateUrl: './catalogo-juegos-detalle.component.html',
  styleUrls: ['./catalogo-juegos-detalle.component.scss']
})
export class CatalogoJuegosDetalleComponent implements OnInit {


  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idJuego: string = this.obtenerId().idJuego;

  //VARIABLES
  edit: boolean = false;

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  JuegoForm = new FormGroup({
    nombre: new FormControl('', Validators.required)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoJuegosService) { }

  ngOnInit(): void {
    if (this.idJuego) {
      this.catalogoservice.getCatalogoJuego(this.idJuego).subscribe(response => {
        this.JuegoForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.JuegoForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    this.catalogoservice.crearCatalogoJuego(this.JuegoForm.value).subscribe(response =>{
      this.JuegoForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/catalogo-juegos')
    });

  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoJuego(this.idJuego, this.JuegoForm.value).subscribe(response =>{
      this.JuegoForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-juegos')
    });
  }
}
