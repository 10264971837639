import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { RecomendacionService } from 'app/servicios/recomendacion.service';
import { Observable } from 'rxjs';
// import * as moment from 'moment';
import { environment } from 'environments/environment.prod';
import { DocumentService } from 'app/servicios/documentos.service';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import * as moment from 'moment-timezone';



@Component({
  selector: 'app-recomendacion-detalle',
  templateUrl: './recomendacion-detalle.component.html',
  styleUrls: ['./recomendacion-detalle.component.scss']
})
export class RecomendacionDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idRecomendacion: string = this.obtenerId().idRecomendacion;

  //VARIABLES
  edit: boolean = false;
  recomendaciones: any = [];
  recomendacion: any = '';
  PDF_HOST = environment.API_PDF;


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  RecomendacionForm = new FormGroup({
    numero: new FormControl('', Validators.required),
    fecha: new FormControl(new Date, Validators.required),
    anio: new FormControl(''),
    asunto: new FormControl('', Validators.required),
    autoridad: new FormControl(''),
    rutaDocumento: new FormControl(''),
    estado: new FormControl(1)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciorecomendacion: RecomendacionService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {

    if (this.idRecomendacion) {
      this.serviciorecomendacion.getRecomendacion(this.idRecomendacion).subscribe(response => {
        this.RecomendacionForm.patchValue(response[0])
        this.RecomendacionForm.controls['fecha'].setValue(this.adjustTimeZone(this.RecomendacionForm.get('fecha').value, 'GMT')) 
        this.recomendacion = response[0].rutaDocumento;
        let fechaDate = new Date(this.RecomendacionForm.get('fecha').value + 'T00:00');
        this.RecomendacionForm.controls['fecha'].setValue(fechaDate)
        console.log(this.RecomendacionForm.value)

      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.RecomendacionForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let pdf = this.RecomendacionForm.get('rutaDocumento').value;
    if (this.RecomendacionForm.get('rutaDocumento').value) {
      this.RecomendacionForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.RecomendacionForm.controls['fecha'].setValue(moment(this.RecomendacionForm.get('fecha').value).format('YYYY-MM-DD'));
    this.RecomendacionForm.controls['anio'].setValue(moment(this.RecomendacionForm.get('fecha').value).format('YYYY'));
    this.RecomendacionForm.addControl('orden', new FormControl(0));
    this.serviciorecomendacion.getRecomendaciones(this.RecomendacionForm.get('anio').value).subscribe(response => {
      if (response) {
        this.recomendaciones = response;
        this.RecomendacionForm.controls['orden'].setValue(this.recomendaciones.length + 1)
        this.serviciorecomendacion.crearRecomendacion(this.RecomendacionForm.value).subscribe(response => {
          if (this.RecomendacionForm.get('rutaDocumento').value) {
            this.RecomendacionForm.controls['rutaDocumento'].setValue(pdf._files[0]);
            const pdfData = new FormData();
            pdfData.append('pdf', this.RecomendacionForm.get('rutaDocumento').value);
            this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
            });
          }
          this.RecomendacionForm.reset()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/recomendacion')
        });
      }
    });
  }

  actualizarDatos = () => {
    let pdf = this.RecomendacionForm.get('rutaDocumento').value;

    if (this.RecomendacionForm.get('rutaDocumento').value) {
      this.RecomendacionForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.RecomendacionForm.controls['fecha'].setValue(moment(this.RecomendacionForm.get('fecha').value).format('YYYY-MM-DD'));
    this.RecomendacionForm.controls['anio'].setValue(moment(this.RecomendacionForm.get('fecha').value).format('YYYY'));
    this.serviciorecomendacion.actualizarRecomendacion(this.idRecomendacion, this.RecomendacionForm.value).subscribe(response => {
      if (this.RecomendacionForm.get('rutaDocumento').value) {
        this.RecomendacionForm.controls['rutaDocumento'].setValue(pdf._files[0]);
        const pdfData = new FormData();
        pdfData.append('pdf', this.RecomendacionForm.get('rutaDocumento').value);
        this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
        });
      }
      this.RecomendacionForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/recomendacion')
    });
  }

  eliminarDocumento = () => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciorecomendacion.actualizarEstadoDocumento(this.idRecomendacion, this.RecomendacionForm.value).subscribe(response => {
          this.serviciodocumento.eliminarPDF(this.recomendacion).subscribe(response => {

          });
          this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
          this.ngOnInit()
        })
      }
    })
  }

    //CONFIGURACION DE FECHA Y HORA
    adjustTimeZone(date: string, timeZone: string): string {
      return moment(date).tz(timeZone).format('YYYY-MM-DD');
    }


}
