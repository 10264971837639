import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { DataService } from 'app/servicios/data.service';
import { DocumentService } from 'app/servicios/documentos.service';
import { RecomendacionGeneralService } from 'app/servicios/recomendacion-general.service';
import { environment } from 'environments/environment.prod';
import moment = require('moment');
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recomendacion-general-detalle',
  templateUrl: './recomendacion-general-detalle.component.html',
  styleUrls: ['./recomendacion-general-detalle.component.scss']
})
export class RecomendacionGeneralDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idRecomendacionGeneral: string = this.obtenerId().idRecomendacionGeneral;

  //VARIABLES
  edit: boolean = false;
  categorias: any = []
  recomendacion: any = '';
  PDF_HOST = environment.API_PDF;


  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  RecomendacionGeneralForm = new FormGroup({
    numero: new FormControl('', Validators.required),
    // fecha: new FormControl(moment([]).format('YYYY-MM-DD'), Validators.required),
    fecha: new FormControl(new Date, Validators.required),
    asunto: new FormControl('', Validators.required),
    autoridad: new FormControl(''),
    rutaDocumento: new FormControl(''),
    estado: new FormControl(1)
  });


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private serviciorecomendacion: RecomendacionGeneralService,
    private serviciodocumento: DocumentService
  ) { }

  ngOnInit(): void {
    if (this.idRecomendacionGeneral) {
      this.serviciorecomendacion.getRecomendacionGeneral(this.idRecomendacionGeneral).subscribe(response => {
        this.RecomendacionGeneralForm.patchValue(response[0])
        this.recomendacion = response[0].rutaDocumento;
      })
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.RecomendacionGeneralForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }

  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {
    let pdf = this.RecomendacionGeneralForm.get('rutaDocumento').value;
    if (this.RecomendacionGeneralForm.get('rutaDocumento').value) {
      this.RecomendacionGeneralForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }
    this.RecomendacionGeneralForm.controls['fecha'].setValue(moment(this.RecomendacionGeneralForm.get('fecha').value).format('YYYY-MM-DD'));
    this.serviciorecomendacion.crearRecomendacionGeneral(this.RecomendacionGeneralForm.value).subscribe(response => {

      if (this.RecomendacionGeneralForm.get('rutaDocumento').value) {
        this.RecomendacionGeneralForm.controls['rutaDocumento'].setValue(pdf._files[0]);
        const pdfData = new FormData();
        pdfData.append('pdf', this.RecomendacionGeneralForm.get('rutaDocumento').value);
        this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
        });
      }

      this.RecomendacionGeneralForm.reset()
      this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
      this.router.navigateByUrl('/recomendacion-general')
    });
  }

  actualizarDatos = () => {
    let pdf = this.RecomendacionGeneralForm.get('rutaDocumento').value;
    if (this.RecomendacionGeneralForm.get('rutaDocumento').value) {
      this.RecomendacionGeneralForm.controls['rutaDocumento'].setValue(pdf._fileNames)
    }

    this.RecomendacionGeneralForm.controls['fecha'].setValue(moment(this.RecomendacionGeneralForm.get('fecha').value).format('YYYY-MM-DD'));
    this.serviciorecomendacion.actualizarRecomendacionGeneral(this.idRecomendacionGeneral, this.RecomendacionGeneralForm.value).subscribe(response => {
      if (this.RecomendacionGeneralForm.get('rutaDocumento').value) {
        this.RecomendacionGeneralForm.controls['rutaDocumento'].setValue(pdf._files[0]);
        const pdfData = new FormData();
        pdfData.append('pdf', this.RecomendacionGeneralForm.get('rutaDocumento').value);
        this.serviciodocumento.guardarPdf(pdfData).subscribe(response => {
        });
      }
      this.RecomendacionGeneralForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/recomendacion-general')
    });
  }

  eliminarDocumento = () =>{
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
     this.serviciorecomendacion.actualizarEstadoDocumento(this.idRecomendacionGeneral, this.RecomendacionGeneralForm.value).subscribe(response=>{
      this.serviciodocumento.eliminarPDF(this.recomendacion).subscribe(response=>{

      });
      this.openSnackBar("SE HA ELIMINADO CORRECTAMENTE")
      this.ngOnInit()
     })
      }
    })
  }


}
