import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class InformesEspecialesService {

  API_URI = environment.API_URL;

  constructor(private http: HttpClient) { }

  getDescripcion(id: number) {
    return this.http.get(`${this.API_URI}/informes-especiales/${id}`);
  }

  actualizarDescripcion(id: number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/informes-especiales/${id}`, datos);
  }

}
