import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class JuegoService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getJuegos(){
    return this.http.get(`${this.API_URI}/juegos`)
  }

  getJuego(id:number){
    return this.http.get(`${this.API_URI}/juegos/${id}`);
  }

  actualizarJuego(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/juegos/${id}`, datos);
  }
  
  crearJuego(datos: any) {
    return this.http.post(`${this.API_URI}/juegos`, datos);
  }

  eliminarJuego(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/juegos/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/juegos/estadoDocumento/${id}`, datos);
  }


}
