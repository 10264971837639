import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { AtribucionesService } from 'app/servicios/atribuciones.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-atribuciones',
  templateUrl: './atribuciones.component.html',
  styleUrls: ['./atribuciones.component.scss']
})
export class AtribucionesComponent implements OnInit {

  AtribucionesForm = new FormGroup({
    descripcion: new FormControl('', [Validators.required])
  });

  constructor(private servicioatribuciones: AtribucionesService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.obtenerAntecendentes()
  }

    //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
      if (this.AtribucionesForm.dirty) {
        const dialogRef = this.dialog.open(MatDialogComponent, {
          data: {
            message: ' ¿ Desea salir sin guardar los cambios ? ',
            buttonText: {
              ok: 'Aceptar',
              cancel: 'Cancelar'
            }
          }
        });
        return dialogRef.afterClosed();
      } else {
        return true;
      }
  
    }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  onChangeEvent($event) {

  }

  obtenerAntecendentes = () => {
    this.servicioatribuciones.getAtribuciones(1).subscribe(response => {
      this.AtribucionesForm.patchValue(response[0]);
    })
  }


  guardarDatos = () => {
    this.servicioatribuciones.actualizarAtribuciones(1, this.AtribucionesForm.value).subscribe(response => {
      this.AtribucionesForm.markAsPristine()
      this.AtribucionesForm.markAllAsTouched()
      this.openSnackBar('REGISTRO ACTUALIZADO!');

    })

  }
}
