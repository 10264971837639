import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CuadroDialogoComponent } from 'app/componentes/cuadro-dialogo/cuadro-dialogo.component';
import { CatalogoCategoriasService } from 'app/servicios/catalogo-publicacion.service';
import { PublicacionService } from 'app/servicios/publicaciones.service';
import moment = require('moment');

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.scss']
})
export class PublicacionesComponent implements OnInit {

  displayedColumns: string[] = ['orden', 'titulo', 'estado', 'ver', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  public publicaciones: any = [];
  categorias: any = [];
  categoria: any = 1;


  constructor(private serviciopublicacion: PublicacionService,
    private dialog: MatDialog,
    private catalogoCategorias: CatalogoCategoriasService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.obtenerCategorias()
    this.obtenerPublicaciones()
  }

  cambios = () => {
    this.obtenerPublicaciones()
  }

  obtenerPublicaciones = () => {
    this.serviciopublicacion.getPublicaciones(this.categoria).subscribe(response => {
      this.publicaciones = response;
      this.dataSource = new MatTableDataSource(this.publicaciones)
      this.dataSource.sort = this.sort;
    })
  }

  obtenerCategorias = () => {
    this.catalogoCategorias.getCatalogoCategorias().subscribe(response => {
      this.categorias = response;
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  eliminarPublicacion = (id) => {
    this.dialog.open(CuadroDialogoComponent).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.serviciopublicacion.eliminarPublicacion(id).subscribe(response => {
          this.obtenerPublicaciones()
        })
      }

    })
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  drop(event: CdkDragDrop<string[]>) {
    let i = 1;
    moveItemInArray(this.publicaciones, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.publicaciones);
    this.dataSource.sort = this.sort;
    this.publicaciones.reverse()

    this.publicaciones.forEach(publicacion => {
      publicacion.orden = i;

      if (publicacion.estado == 'ACTIVO') {
        publicacion.estado = 1;
      } else {
        publicacion.estado = 0;
      }

      this.serviciopublicacion.actualizarPublicacion(publicacion.idPublicacion, publicacion).subscribe(response => {
      });

      if (publicacion.estado == 1) {
        publicacion.estado = 'ACTIVO';
      } else {
        publicacion.estado = 'INACTIVO';
      }
      i++;
    });
    this.publicaciones.reverse()

    this.openSnackBar("EL REGISTRO SE MOVIO CORRECTAMENTE!")
  }

}
