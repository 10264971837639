import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogComponent } from 'app/componentes/mat-dialog/mat-dialog.component';
import { CatalogoMicroSitiosService } from 'app/servicios/catalogo-micrositios.service';
import { DataService } from 'app/servicios/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-catalogo-micrositios-detalle',
  templateUrl: './catalogo-micrositios-detalle.component.html',
  styleUrls: ['./catalogo-micrositios-detalle.component.scss']
})
export class CatalogoMicrositiosDetalleComponent implements OnInit {

  obtenerId = () => this.activatedRoute.snapshot.params;
  //OBTENEMOS EL ID DE LA SEDE QUE EL USUARIO SELECCIONO 
  idSitio: string = this.obtenerId().idSitio;

  //VARIABLES
  edit: boolean = false;
  micrositios: any

  //DECLARACIÓN DE FORMULARIOS REACTIVOS 
  MicroSitioForm = new FormGroup({
    nombreSeccion: new FormControl('', Validators.required),
    estado: new FormControl(1)
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigation: DataService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private catalogoservice: CatalogoMicroSitiosService) { }

  ngOnInit(): void {
    if (this.idSitio) {
      this.catalogoservice.getCatalogoMicroSitio(this.idSitio).subscribe(response => {
        this.MicroSitioForm.patchValue(response[0])
      });
    }
  }

  //VERIFICAMOS SI EL USUARIO VA A SALIR DE LA RUTA  CON DATOS EN EL FORMULARIO
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.MicroSitioForm.dirty) {
      const dialogRef = this.dialog.open(MatDialogComponent, {
        data: {
          message: ' ¿ Desea salir sin guardar los cambios ? ',
          buttonText: {
            ok: 'Aceptar',
            cancel: 'Cancelar'
          }
        }
      });
      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }

  //AVISO TIPO SNACKBAR ANGULAR MATERIAL
  openSnackBar(message: string) {
    this.snackBar.open(message, "Aceptar", { duration: 2000 });
  }

  guardarDatos = () => {

    this.MicroSitioForm.addControl('orden', new FormControl(0));
    this.catalogoservice.getCatalgoMicroSitios().subscribe(response => {
      if(response){
        this.micrositios = response;
        this.MicroSitioForm.controls['orden'].setValue(this.micrositios.length + 1)
        this.catalogoservice.crearCatalogoMicroSitio(this.MicroSitioForm.value).subscribe(response =>{
          this.MicroSitioForm.reset()
          this.openSnackBar("REGISTRO CREADO CORRECTAMENTE!")
          this.router.navigateByUrl('/catalogo-micrositios')
        });
      }
    });
  }

  actualizarDatos = () => {
    this.catalogoservice.actualizarCatalogoMicroSitio(this.idSitio, this.MicroSitioForm.value).subscribe(response =>{
      this.MicroSitioForm.reset()
      this.openSnackBar("INFORMACIÓN ACTUALIZADA!")
      this.router.navigateByUrl('/catalogo-micrositios')
    });
  }
}
