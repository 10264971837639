import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.prod';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class ConoceDerechosPDFService {

  API_URI = environment.API_URL;
  constructor(private http: HttpClient) { }

  getDerechos(){
    return this.http.get(`${this.API_URI}/conocer-pdf`)
  }

  getDerecho(id:string){
    return this.http.get(`${this.API_URI}/conocer-pdf/${id}`);
  }

  actualizarDerecho(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/conocer-pdf/${id}`, datos);
  }
  
  crearDerecho(datos: any) {
    return this.http.post(`${this.API_URI}/conocer-pdf`, datos);
  }

  eliminarDerecho(id: string): Observable<any> {
    return this.http.delete(`${this.API_URI}/conocer-pdf/${id}`);
  }

  actualizarEstadoDocumento(id: string | number, datos: any): Observable<any> {
    return this.http.put(`${this.API_URI}/conocer-pdf/estadoDocumento/${id}`, datos);
  }


}
